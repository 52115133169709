import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router';
import is from 'is_js';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Container, Box, CircularProgress } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ReactWhatsapp from 'react-whatsapp';

import { BookingContext } from 'src/contexts/BookingContext';
import { AuthContext } from 'src/contexts/AuthContext';
import Button from 'src/modules/components/Button';
import Typography from 'src/modules/components/Typography';
import BookingDetails from 'src/modules/components/BookingDetails';
import UserServices from 'src/services/UserServices';
import ShoppingCartServices from 'src/services/ShoppingCartServices';
import FinalCountCard from 'src/modules/components/FinalCount';
import PaymentServices from 'src/services/PaymentServices';
import CardList from 'src/modules/components/CardList';
import { logPurchase, logViewCart } from 'src/utils/eventsLogger';
import { COUNTRIES } from 'src/utils/constants';
import CartSteps from 'src/modules/components/CartSteps';

const AMZ_PHONE_NUMBER = '56930397449';

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(10),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bookingContainer: {
    maxWitdh: 350,
    margin: '24px'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    minWidth: 350,
    maxWidth: 680,
    margin: '24px auto 0px auto'
  },
  loading: {
    textAlign: 'center',
    marginTop: '24px'
  },
  emptyMessageContainer: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
    minHeight: '200px'
  },
  whatsappBox: {
    marginTop: theme.spacing(8),
    textAlign: 'center',
    width: '100%'
  }
});

const CustomContainedButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#B93D48',
		color: '#fff',
		// border: '3px solid #f94646!important',
    '&:hover': {
      backgroundColor: '#B93D4870',
			color: '#fff',
    },
  },
}))(Button);

const CustomOutlinedButton = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
		color: '#B93D48',
		border: '3px solid #B93D48!important',
    '&:hover': {
      backgroundColor: '#B93D4850',
			color: '#B93D48',
    },
  },
}))(Button);

function Cart({ classes }) {
  // eslint-disable-next-line 
  const { i18n, t } = useTranslation();
  let { userData } = useContext(AuthContext);
  let { handleDeleteBooking } = useContext(BookingContext);
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingSlider, setBookingSlider] = useState(true);
  const [countSlider, setCountSlider] = useState(false);
  const [cardsSlider, setCardsSlider] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [promocode, setPromocode] = useState(null);
  const [promocodeLoading, setPromocodeLoading] = useState(false);
  const [promocodeError, setPromocodeError] = useState('');
  const [cards, setCards] = useState([]);
  const [cardSelected, setCardSelected] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  useEffect(() => {
    if (new URLSearchParams(window.location.search).get("step")) {
      if (new URLSearchParams(window.location.search).get("step") === '2') {
        setBookingSlider((prev) => !prev); 
        setCardsSlider((prev) => !prev); 
        setActiveStep(1);
      }
    }
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (userData) {
      Promise.all([getCartDataByUserId(), getRedirectUrl()]);
    }
  }, [userData]);

  useEffect(() => {
    if (cart.bookings && is.not.empty(cart.bookings)) {
      getPaymentMethods();
      getCards();
    }
    // eslint-disable-next-line 
  }, [cart]);

  // get saved user cards
  const getCards = async () => {
    let filter = {
      address_id: cart.bookings[0].address.address_id
    }
    try {
      const response = await UserServices.getCards(userData.user_id, filter);
      let updatedList = response.data.map((c) => ({ ...c, checked: false }));
      setCards(updatedList);
    } catch (error) {
      console.log(error);
    }
  }

  const getPaymentMethods = async () => {
    let filter = {
      address_id: cart.bookings[0].address.id,
    }
    try {
      const response = await PaymentServices.searchPaymentMethods(filter);
      let updatedList = response.data.map((pm) => ({ ...pm, checked: false }));
      setPaymentMethods(updatedList);
    } catch (error) {
      console.log(error);
    }
  }

  const getCartDataByUserId = async (bookingToDelete) => {
    setIsLoading(true);
    try {
      if (bookingToDelete) await handleDeleteBooking(bookingToDelete);
      const response = await UserServices.getCart(userData.user_id);
      setCart(response.data);

      const currentCountry = COUNTRIES.find((c) => c.lang === i18n.language);

      if (response.data.bookings.find((b) => b.currency.name !== currentCountry.currency)) {
        let filteredList = response.data.bookings.filter((b) => b.currency.name !== currentCountry.currency);

        for (let i in filteredList) {
          await ShoppingCartServices.deleteBooking(filteredList[i].id);
        }

        const responseUpdated = await UserServices.getCart(userData.user_id);
        setCart(responseUpdated.data);
      }

      if (response.data.bookings.length > 0) {
        logViewCart(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const keepBuying = (e) => {
    e.preventDefault();
    navigate('/categoria-de-servicio');
  }

  const handleLocalDeleteBooking = async (bookingId) => {
    getCartDataByUserId(bookingId);
  }

  // promocode calculation & setting new final count
  const handleAddPromocode = async () => {
    setPromocodeError(null)
    if (promocode) {
      setPromocodeLoading(true);
      try {
        const response = await ShoppingCartServices.addPromocode(promocode);
        setCart(response.data);
      } catch (error) {
        console.log(error.response.data.message || error);
        setPromocodeError('Ingrese un código valido.')
      } finally {
        setPromocodeLoading(false);
      }
    }
  }

  const handleSelectPaymentmethod = async (card, value) => {
    if (card === "MercadoPago") {
      if (value) {
        setCardSelected("MercadoPago");
      } else {
        setCardSelected(null);
      }
      let filteredList = paymentMethods.filter((pm) => pm.name !== 'Mercado Pago');
      let methodToEdit = paymentMethods.find((pm) => pm.name === 'Mercado Pago');
      methodToEdit.checked = value;
      setPaymentMethods([...filteredList, methodToEdit]);
    } else {
      if (value) {
        setCardSelected(card);
      } else {
        setCardSelected(null);
      }
      let filteredList = cards.filter((c) => c.id !== card.id);
      let cardToEdit = cards.find((c) => c.id === card.id);
      cardToEdit.checked = value;
      setCards([...filteredList, cardToEdit]);
    }
  }

  const handleAddNewCard = () => {
    const oneClickEnabled = paymentMethods.find((method) => { return (method.name === 'One Click' && method.app_enabled) });
    const dlocalEnabled = paymentMethods.find((method) => { return (method.name === 'D Local' && method.app_enabled) });
    if (oneClickEnabled) {
      window.location.href = redirectUrl;
    } else if (dlocalEnabled) {
      const token = localStorage.getItem('access_token');
      navigate(`/cart/dlocal-secure-card/${token}`);
    }
  }

  // get onClick new card form url
  const getRedirectUrl = async () => {
    let filter = {
      user_id: userData.user_id,
      channel: 'web'
    }
    try {
      const resp = await PaymentServices.getOneClickUrl(filter);
      setRedirectUrl(resp.data.inscription_url);
    } catch (e) {
      console.log(e);
    }
  }

  // get payment link or pay
  const payCart = async () => {
    if (cardSelected) {
      setIsLoading(true);
      const resp = await PaymentServices.getPublicIP();
      const ip = resp?.data?.ip || '';
      try {
        const body = {
          shopping_cart_id: cart.id,
          payment_method_id: cardSelected === 'MercadoPago' ? null : cardSelected.paymentMethod.id,
          user_card_id: cardSelected === 'MercadoPago' ? null : cardSelected.id,
          ip,
          device_id: "string",
          channel: 'web'
        }
        const respPayment = await PaymentServices.pay(body);
        localStorage.setItem('cart', JSON.stringify(cart));
        if (respPayment.data.payment_url) {
          window.location.href = respPayment.data.payment_url;
        } else {
          navigate('/process-payment/payment-success');          
        }
      } catch (e) {
        console.log(e);
        navigate('/process-payment/payment-failed');
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleNext = () => {
    let newSkipped = skipped;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <section>
      <Container className={classes.container}>

        {is.not.empty(cart.bookings) && <CartSteps activeStep={activeStep} setActiveStep={setActiveStep} skipped={skipped} setSkipped={setSkipped} />}

        {isLoading && <Box className={classes.loading}>
          <CircularProgress color="secondary" />
        </Box>}
        {!isLoading && <>

          {/* empty cart message */}
          {is.empty(cart.bookings) && <Box className={classes.emptyMessageContainer}>
            <Typography
              variant="h6"
              align="center"
              component="h2">
              {t('phrases.no_services_selected')}
            </Typography>
          </Box>}

          {/* Booking summary  */}
          {is.not.empty(cart.bookings) && <Slide
            direction={bookingSlider ? "left" : "right"}
            in={bookingSlider}
            mountOnEnter
            unmountOnExit
          >
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Box style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'center' }}>
                {cart.bookings && cart.bookings.map((b, i) => <Box key={'booking_' + i} className={classes.bookingContainer}>
                  <BookingDetails booking={b} currency={cart.currency} cartId={cart.id} multiple handleDeleteBooking={handleLocalDeleteBooking} />
                </Box>)}
              </Box>
              <CustomContainedButton
                size="large"
                onClick={() => { setBookingSlider((prev) => !prev); setCardsSlider((prev) => !prev); handleNext(); }}
                className={classes.button}
              >
                {t('phrases.select_payment_method')}
              </CustomContainedButton>
              <CustomOutlinedButton
                size="large"
                onClick={(e) => keepBuying(e)}
                className={classes.button}
              >
                {t('phrases.keep_buying')}
              </CustomOutlinedButton>
            </Box>
          </Slide>}

          {/* Card Select */}
          <Slide
            direction={cardsSlider ? "left" : "right"}
            in={cardsSlider}
            mountOnEnter
            timeout={100}
            unmountOnExit
          >
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <CardList
                cards={cards}
                handleSelectPaymentmethod={handleSelectPaymentmethod}
                handleAddNewCard={handleAddNewCard}
                paymentMethods={paymentMethods} />
              <CustomContainedButton
                size="large"
                disabled={!cardSelected}
                onClick={() => { setCardsSlider((prev) => !prev); setCountSlider((prev) => !prev); handleNext(); }}
                className={classes.button}
              >
                {t('text.confirm')}
              </CustomContainedButton>
              <CustomOutlinedButton
                size="large"
                onClick={() => { setBookingSlider((prev) => !prev); setCardsSlider((prev) => !prev);  handleBack(); }}
                className={classes.button}
              >
                {t('text.go_back')}
              </CustomOutlinedButton>
            </Box>
          </Slide>

          {/* Final Count */}
          <Slide
            direction={countSlider ? "left" : "right"}
            onExited={() => setCardsSlider((prev) => !prev)}
            timeout={700}
            in={countSlider} mountOnEnter unmountOnExit>
            <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <FinalCountCard
                cart={cart}
                cardSelected={cardSelected}
                paymentMethods={paymentMethods}
                promocode={promocode}
                handleAddPromocode={handleAddPromocode}
                setPromocode={setPromocode}
                promocodeLoading={promocodeLoading}
                promocodeError={promocodeError} />
              <CustomContainedButton
                size="large"
                onClick={payCart}
                className={classes.button}
              >
                {t('text.pay')}
              </CustomContainedButton>
              <CustomOutlinedButton
                size="large"
                onClick={() => { setCountSlider((prev) => !prev); handleBack(); } }
                className={classes.button}
              >
                {t('text.go_back')}
              </CustomOutlinedButton>
            </Box>
          </Slide>

        </>}

        <Box className={classes.whatsappBox}>
          <ReactWhatsapp number={AMZ_PHONE_NUMBER} message="" style={{ backgroundColor: 'transparent', border: 'none' }}>
            <Button
              endIcon={<WhatsAppIcon />}
              variant='outlined'
              size='small'
              style={{ marginTop: '24px' }}>
              {t('text.contact_us')}
            </Button>
          </ReactWhatsapp>
        </Box>

      </Container>
    </section>
  );
}

Cart.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Cart);
