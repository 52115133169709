import { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import is from 'is_js';
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  TextField,
  IconButton,
  Button,
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@material-ui/core';
import moment from 'moment';
import SendIcon from '@material-ui/icons/Send';
import { useMediaQuery } from "react-responsive";
import InsertCommentOutlinedIcon from '@material-ui/icons/InsertCommentOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { db } from 'src/utils/firebase';
import { AuthContext } from 'src/contexts/AuthContext';
import UserServices from 'src/services/UserServices';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  arrow: {
    width: '0',
    height: '0',
    borderTop: '8px solid transparent',
    borderBottom: '8px solid transparent',
    borderLeft: '8px solid #202020',
    alignSelf: 'flex-end',
    marginLeft: '-7%'
  },
  messageInput: {
    borderRadius: '6px',
    backgroundColor: '#EAEAEA'
  },
  conversationContainer: {
    '-moz-box-shadow': 'inset 0 0 2px #000000',
    '-webkit-box-shadow': 'inset 0 0 2px #000000',
    'box-shadow': 'inset 0 0 2px #000000',
    backgroundColor: '#F9F9F9'
  },
  chatTitle: {
  },
  subtitle: {
    color: '#CBCBCB'
  },
  input: {
    display: 'none',
  }
}));

const Conversation = ({ conversationSelected, professionalSelected, newChat }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const { userData } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
      console.log('messages', messages);
      setTimeout(scrollChatToBottom, 100);
  }, [messages]);

  useEffect(() => {
    let onValueChange;
    if (conversationSelected) {
      setIsLoading(true);
      onValueChange = db.ref(`new_chat/user_${userData.user_id}/${conversationSelected}`)
        .on('value', snapshot => {
          let oldMessages = snapshot.val();
          let listOfMessages = [];
          if (oldMessages) {
            for (let i in oldMessages) {
              // let attachment = oldMessages[i].image ? { url: oldMessages[i].image_url } : null;
              let newMessage = {
                ...oldMessages[i],
              }
              listOfMessages.push(newMessage);
            }
          }
          if (is.not.empty(listOfMessages)) {
            listOfMessages.sort((a, b) => a.date - b.date);
          }
          setMessages(listOfMessages);
          setIsLoading(false);
        });

        if (!newChat) {
          let isUnread = false;
          db
            .ref("unread/" + `user_${userData.user_id}/` + `${conversationSelected}`)
            .once('value')
            .then(snapshot => {
              console.log("snapshot.val()",snapshot.val());
              if (snapshot.val()) {
                isUnread = true;
              }
            })
            .then(() => {
              if (isUnread) {
                db
                  .ref("unread/" + `user_${userData.user_id}/` + `${conversationSelected}`)
                  .update({
                    isRead: true,
                  });
              }
            });
        }
    }

    return () => {
      db.ref(`new_chat/user_${userData.user_id}/${conversationSelected}`)
        .off('value', onValueChange);
    }

  }, [conversationSelected]);

  const sendMessage = async () => {
    let messageCopy = newMessage.slice();
    setNewMessage('');
    try {
      let m = db.ref(`new_chat/user_${userData.user_id}/${conversationSelected}`).push({
        created_at: moment().unix(),
        sender: 'user',
        user_id: userData.user_id,
        message: messageCopy,
        image: false
      });

      let count = 0;

      await db
        .ref("unread/" + `${conversationSelected}/` + `user_${userData.user_id}`)
        .once('value')
        .then(snapshot => {
          if (snapshot.val()) {
            count = snapshot.val().count;
          }
        });
      
      // setting unread message
      await db.ref("unread/" + `${conversationSelected}/` + `user_${userData.user_id}`).set({
        count: count + 1,
        firstName: `${userData.name}`,
        lastName: `${userData.lastname}`,
        user_id: userData.user_id,
        message: messageCopy,
        date: moment().format(),
        isRead: false,
        image: false,
        avatar: userData?.avatar ? userData?.avatar?.url : '',
        sender: 'user'
      });

       // setting own unread message
       await db.ref("unread/" + `user_${userData.user_id}/` + `${conversationSelected}`).set({
        count: 0,
        firstName: professionalSelected.firstName,
        lastName: professionalSelected.lastName,
        user_id: professionalSelected.user_id,
        message: messageCopy,
        date: moment().format(),
        isRead: true,
        image: false,
        avatar: professionalSelected?.avatar,
        sender: 'user'
      });

    } catch (error) {
      console.log(error);
    }
  }

  const scrollChatToBottom = () => {
    let chatsElements = document.getElementById('chatMessagesContainer');
    if (chatsElements) chatsElements.scrollTop = chatsElements.scrollHeight;
  }

  const handleSendImage = async (file) => {
    if (!file) return;
    setImageLoading(true);
    const base64 = await getBase64(file);

    const imageObj = {
      base64,
      user_id: userData.user_id,
    }

    try {
      const response = await UserServices.postMessageAttachment(imageObj.user_id, imageObj);
      console.log('postMessageAttachment', response.data);

      await db.ref(`new_chat/user_${userData.user_id}/${conversationSelected}`).push({
        created_at: moment().unix(),
        sender: 'user',
        user_id: userData.user_id,
        message: '',
        image_url: response.data.url,
        image: true
      });

      let count = 0;

      await db
        .ref("unread/" + `${conversationSelected}/` + `user_${userData.user_id}`)
        .once('value')
        .then(snapshot => {
          if (snapshot.val()) {
            count = snapshot.val().count;
          }
        });

      // setting unread message
      await db.ref("unread/" + `${conversationSelected}/` + `user_${userData.user_id}`).set({
        count: count + 1,
        firstName: `${userData.name}`,
        lastName: `${userData.lastname}`,
        user_id: userData.user_id,
        message: 'image',
        date: moment().format(),
        isRead: false,
        image: true,
        image_url: response.data.url,
        avatar: userData?.avatar ? userData?.avatar?.url : '',
        sender: 'user'
      });

       // setting own unread message
       await db.ref("unread/" + `user_${userData.user_id}/` + `${conversationSelected}`).set({
        count: 0,
        firstName: professionalSelected.firstName,
        lastName: professionalSelected.lastName,
        user_id: professionalSelected.user_id,
        message: 'image',
        date: moment().format(),
        isRead: true,
        image: true,
        avatar: professionalSelected?.avatar,
        sender: 'user'
      });

    } catch (error) {
      console.log(error);
    } finally {
      setImageLoading(false);
    }
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <Box className={classes.root} >
       {professionalSelected && <Box style={{ width: '100%', backgroundColor: '#28282a' }}>
          <ListItem 
            alignItems="flex-start" 
            style={{ fontSize: '24px', color: '#fff' }}
          >
              <ListItemAvatar>
                <Avatar alt={professionalSelected.firstName} src={professionalSelected.avatar} />
              </ListItemAvatar>
              <ListItemText
                className={classes.chatTitle}
                primary={`${professionalSelected.firstName} ${professionalSelected.lastName}`}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.subtitle}
                      color="textPrimary"
                    >
                      Parte del equipo de Ameizers
                    </Typography>
                  </>
                }
              />
            </ListItem>
        </Box>}

      {isLoading ? <CircularProgress /> :
        <Box
          id="chatMessagesContainer"
          className={classes.conversationContainer}
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: conversationSelected ? '80%' : '100%',
            'overflow-y': 'auto',
            height:'240px',
          }} 
          boxShadow={0}>

          {!conversationSelected && <Box style={{ width: '100%', padding: '35% 0', textAlign: 'center' }}>
            <InsertCommentOutlinedIcon fontSize="large" />
            <Typography variant="h5" style={{ fontSize: '24px' }}>
              Selecciona una conversación
            </Typography>
          </Box>}

          <Box style={{ marginTop: '8px' }}>
            {
              conversationSelected && messages.map((m, i) => <Box key={'m_' + m.created_at}
                  style={{
                      marginBottom: 10,
                      flexDirection: 'column',
                      whiteSpace: 'pre-line',
                      display: 'flex',
                      alignSelf: m.sender === 'user' ? 'flex-end' : 'flex-start',
                      alignItems: m.sender === 'user' ? 'flex-end' : 'flex-start',
                      paddingRight: m.sender === 'user' ? '24px' : '24px',
                      paddingLeft: m.sender === 'user' ? '24px' : '24px',
                  }}>
                {!m.image && <Box 
                  style={{ 
                    padding: 8, 
                    borderRadius: 5, 
                    backgroundColor: m.sender === 'user' ? '#202020' : '#909090', 
                    color: '#ffffff', display: 'inline-flex' }}>
                  {m.message}
                </Box>}
                {(!imageLoading && (messages.length - 1) === i) && m.image ? 
                  <Box 
                    style={{ 
                      padding: 8, 
                      borderRadius: 5, 
                      backgroundColor: m.sender === 'user' ? '#202020' : '#909090', 
                      color: '#ffffff', display: 'inline-flex' }}>
                    <img alt='image' src={m.image_url} width={200} />
                  </Box> : 
                  (m.image && (messages.length - 1) !== i) && <Box 
                  style={{ 
                    padding: 8, 
                    borderRadius: 5, 
                    backgroundColor: m.sender === 'user' ? '#202020' : '#909090', 
                    color: '#ffffff', display: 'inline-flex' }}>
                  <img alt='image' src={m.image_url} width={200} />
                </Box>}
                {imageLoading && (messages.length - 1) === i && <Box 
                  style={{ 
                    padding: 8, 
                    borderRadius: 5, 
                    backgroundColor: m.sender === 'user' ? '#202020' : '#909090', 
                    color: '#ffffff', display: 'inline-flex' }}>
                  <CircularProgress color='#ffffff' />
                </Box>}
                <Box style={{ color: 'grey', fontSize: 12 }}>{moment.unix(m.created_at).format('LLL')}</Box>
              </Box>)
            }
          </Box>

        </Box>}

      {conversationSelected && <Box style={{ display: 'flex', backgroundColor: '#28282a', padding: '8px', alignItems: 'center' }} >
        <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={(e) => handleSendImage(e.target.files[0])}/>
        <label htmlFor="icon-button-file">
          <IconButton color="primary" aria-label="upload picture" component="span">
            <AttachFileIcon style={{ color: '#EAEAEA' }} fontSize='small' />
          </IconButton>
        </label>
        <FormControl style={{ width: '100%' }}>
          <TextField
              autoFocus={!isMobile}
              id="newMessage"
              className={classes.messageInput}
              variant="outlined"
              value={newMessage}
              inputProps
              size='small'
              onChange={e => setNewMessage(e.target.value)}
              type="text"
              onKeyPress={ev => {
                  if (ev.key === 'Enter' && !ev.shiftKey) sendMessage();
              }}
              InputLabelProps={{ shrink: true, }} />
        </FormControl>
        <IconButton
          onClick={sendMessage}
          style={{  border: '1px solid #28282a' }}
        >
          <SendIcon style={{ color: '#EAEAEA' }}  fontSize='small' />
        </IconButton>
      </Box>}
    </Box>
  )
}

export default Conversation;
