import { createContext, useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'src/contexts/AuthContext';
import UserServices from 'src/services/UserServices';

let UserContext = createContext();
let { Provider } = UserContext;

function UserProvider({ children }) {
  const { i18n } = useTranslation();
  const { userData } = useContext(AuthContext);
  const [profile, setProfile] = useState(null);
  const [defaultAddress, setDefaultAddress] = useState(null);

  useEffect(() => {
    console.log("store profile", profile);
  }, [profile]);

  useEffect(() => {
    console.log("store defaultAddress", defaultAddress);
  }, [defaultAddress]);

  const getSavedAddress = async () => {
    const savedAddress = JSON.parse(localStorage.getItem('default_address'));
    if (savedAddress) {
      if (COUNTRIES[savedAddress?.country] === i18n.language) {
        setDefaultAddress(savedAddress);
      } else {
        localStorage.removeItem('default_address');
        setDefaultAddress(null);
      }
    } else {
      if (userData) {
        try {
          const response = await UserServices.getUserAdresses(userData.user_id);
          let d = response.data.find((a) => a.default);
          if (d && COUNTRIES[d?.country] === i18n.language) {
            saveAddressInLocalStorage(d);
          } else {
            setDefaultAddress(null);
          }
        } catch (error) {
          console.log(error);
          setDefaultAddress(null);
        }
      } else {
        setDefaultAddress(null);
      }
    }
  }

  const saveAddressInLocalStorage = (address) => {
    localStorage.setItem('default_address', JSON.stringify(address));
    setDefaultAddress(address);
  }

  return (
    <Provider
      value={{
        profile,
        setProfile,
        defaultAddress,
        setDefaultAddress,
        getSavedAddress,
        saveAddressInLocalStorage
      }}
    >
      {children}
    </Provider>
  );
}

const COUNTRIES = {
  'Chile': 'es_cl',
  'Perú': 'es_pe',
  'Colombia': 'es_co',
  'Argentina': 'es_ar',
  'Mexico': 'es_mx'
}

export { UserProvider, UserContext };