import { analytics } from 'src/utils/firebase';
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('972057153586578', advancedMatching, options);
ReactPixel.pageView();

export const logAddToCart = async (cart) => {
  //Log for Firebase
  const b = cart.bookings.filter((booking) => booking.id === cart.last_created_booking)[0]
  console.log("logAddToCart", b);
  analytics.logEvent('logAddToCart', {
    currency: b.currency?.name,
    value: Number(b?.service_variant_total_price)
  })

  // Log for Facebook
  ReactPixel.trackCustom('AddToCart', {
    content_name: b.serviceVariantArea.name,
    currency: b.currency.name,
    value: Number(b.service_variant_total_price),
    content_ids: [String(b.serviceVariantArea.id)],
    content_type: 'Service',
  });

}

export const logPurchase = async (cart) => {
  console.log('logPurchase')
  console.log(cart)
  //Log for Firebase
  analytics.logEvent('logPurchase', {
    currency: cart.currency.name,
    coupon: (cart.promocode ? cart.promocode.code : ''),
    shipping: Number(cart.final_transport_price),
    value: Number(cart.final_price)
  });

  // Log for Facebook
  ReactPixel.trackCustom('logPurchase', {
    currency: cart.currency.name,
    coupon: (cart.promocode ? cart.promocode.code : ''),
    shipping: Number(cart.final_transport_price),
    value: Number(cart.final_price)
  });

}

export const logViewCart = async (cart) => {
  console.log('logViewCart')
  const currency = cart.currency.name;
  const value = cart.final_price;
  //Log for Firebase
  analytics.logEvent('logViewCart', { value: Number(value), currency: currency });

  // Log for Facebook
  ReactPixel.trackCustom('logViewCart', { value: Number(value), currency: currency });
}

// no search bar available yet 
// export const logSearch = async (query) => {
//   console.log('logSearch')
//   //Log for Firebase
//   analytics().logSearch({ search_term: query })
// }

export const logNoCoverage = async (address) => {
  console.log('logNoCoverage')
  console.log(address)
  //Log for Firebase
  analytics.logEvent('no_coverage', {
    ...address
  });

  // Log for Facebook
  ReactPixel.trackCustom('no_coverage', {
    ...address
  });

}

export const logWhatsappContact = async (from) => {
  console.log('logWhatsappContact')
  //Log for Firebase
  analytics.logEvent('whatsapp_contact', { from: from });

  // Log for Facebook
  ReactPixel.trackCustom('whatsapp_contact', { from: from });

}

// no banner available yet 
// export const logBannerTap = async (title: String): void => {
//   console.log('logBannerTap')
//   //Log for Firebase
//   analytics().logEvent('banner_tap', { title: title })
// }