import React, { useEffect, useState, useContext } from 'react';
import { BookingContext } from 'src/contexts/BookingContext';
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemSecondaryAction,
  Radio,
  List,
  ListItem,
  ListItemText,
  Grid,
  Box,
  Container
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useMediaQuery } from "react-responsive";
import { useNavigate } from 'react-router';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ReactWhatsapp from 'react-whatsapp';

import ServicesAPI from 'src/services/Services';
import VariantCard from 'src/modules/components/VariantCard';
import VariantModal from 'src/modules/components/VariantModal';
import Typography from 'src/modules/components/Typography';
import Button from 'src/modules/components/Button';

const AMZ_PHONE_NUMBER = '56930397449';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  title: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(5),
  },
  serviceName: {
    fontSize: 18,
    fontFamily: theme.typography.fontFamily,
    color: '#BFBFBF',
    fontWeight: theme.typography.fontWeightMedium,
  },
  serviceNameSelected: {
    fontSize: 18,
    fontFamily: theme.typography.fontFamily,
    color: '#04332f',
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(8),
    minWidth: '250px'
  },
  gobackButton: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    minWidth: '250px'
  },
  variantCardContainer: {
    marginTop: 10,
  },
  variantAccordion: {
    width: '100%',
    border: '1px solid #04332f',
    margin: '4px 0px'
  },
  serviceItem: {
    border: '1px solid #BFBFBF',
    margin: '4px 0px',
    borderRadius: '4px',
    backgroundColor: 'white',
  },
  serviceItemSelected: {
    border: '1px solid #04332f',
    margin: '4px 0px',
    borderRadius: '4px',
    backgroundColor: 'white',
  },
  whatsappBox: {
    textAlign: 'center',
    marginTop: '24px',
    width: '100%'
  },
  radioSelected: {
    color: '#04332f',
  }
});

function Services({ classes }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { categoryId } = useParams();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  let { addressSelected, confirmedCoordinates, setBooking } = useContext(BookingContext);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [serviceSelected, setServiceSelected] = useState(null);
  const [variantSelected, setVariantSelected] = useState(null);

  useEffect(() => {
    if (categoryId && (addressSelected || confirmedCoordinates)) {
      getCategory();
    }
    // eslint-disable-next-line 
  }, [categoryId, addressSelected, confirmedCoordinates]);

  const getCategory = async () => {
    setIsLoading(true);

    let filter = {
      category_id: categoryId,
      coordinate: {
        lat: "",
        lng: ""
      }
    }

    if (confirmedCoordinates) {
      filter.coordinate = {
        lat: confirmedCoordinates?.lat(),
        lng: confirmedCoordinates?.lng()
      }
    } else if (addressSelected.coordinate) {
      filter.coordinate = {
        lat: addressSelected.coordinate?.lat,
        lng: addressSelected.coordinate?.lng
      }
    } else {
      filter.coordinate = {
        lat: addressSelected?.geometry.location?.lat(),
        lng: addressSelected?.geometry.location?.lng()
      }
    }

    try {
      const response = await ServicesAPI.getServicesByCategoryId(filter, categoryId);
      console.log('getServicesByCategoryId', response.data );
      setServices(response.data);
      setServiceSelected(response.data.services[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleVariantCardClick = (variant) => {
    console.log('variant', variant);
    setVariantSelected(variant);
    setShow(true);
  }

  const handleCloseModal = () => {
    setBooking({});
    setVariantSelected(null);
    setShow(false);
  }

  const handleChangeService = (serviceId) => {
    setServiceSelected(serviceId);
  }

  const getToCategories = () => {
    navigate('/');
  }

  return (
    <section className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        {isLoading && <CircularProgress size={80} />}
        {!isLoading && <>
          <Typography variant="h4" marked="center" className={classes.title} component="h2">
            {services.name}
          </Typography>

          <Grid container direction="row" spacing={3} style={{ width: '100%' }}>
            <Grid item xs={12} md={4}>
              <List component="nav" aria-label="main mailbox folders">
                {serviceSelected && services.services && services.services.map((service, i) =>
                  <ListItem key={'sv' + i} button onClick={() => handleChangeService(service)} className={serviceSelected.id === service.id ? classes.serviceItemSelected : classes.serviceItem}>
                    <ListItemText
                      primary={
                        <Typography className={serviceSelected.id === service.id ? classes.serviceNameSelected : classes.serviceName}>{service.name}</Typography>}
                      secondary={<Typography variant="body2" marked="center" component="p">
                        {service.description}
                      </Typography>}>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <Radio
                        checked={serviceSelected.id === service.id}
                        value={service.id}
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'A' }}
                        onClick={() => handleChangeService(service)}
                        color='primary'
                        className={serviceSelected.id === service.id ? classes.radioSelected : classes.radio}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>)}

                  {/* <ListItem button onClick={() => handleChangeService('sessions')} className={serviceSelected === 'sessions' ? classes.serviceItemSelected : classes.serviceItem}>
                    <ListItemText
                      primary={
                        <Typography className={serviceSelected === 'sessions' ? classes.serviceNameSelected : classes.serviceName}>Packs</Typography>}
                     >
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <Radio
                        checked={serviceSelected === 'sessions'}
                        value={'pack'}
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'A' }}
                        onClick={() => handleChangeService('sessions')}
                        color='primary'
                        className={false ? classes.radioSelected : classes.radio}
                      />
                    </ListItemSecondaryAction>
                  </ListItem> */}
              </List>
            </Grid>

            <Grid item xs={12} md={8}>
              {serviceSelected && serviceSelected !== 'sessions' && <>
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '8px 0px' }}>
                  {serviceSelected.variants && serviceSelected.variants.map((variant, i) => <Accordion key={'variant' + i} className={classes.variantAccordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>{variant.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: '100%' }}>
                      <VariantCard variant={variant} onCardClick={() => { handleVariantCardClick(variant) }} />
                    </AccordionDetails>
                  </Accordion>)}
                </Box>
              </>}
              {serviceSelected === 'sessions' && <>
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '8px 0px' }}>
                  {[1,2,3].map((variant, i) => <Accordion key={'sessionVariant' + i} className={classes.variantAccordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>Pack de masajes n°{i + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: '100%' }}>
                      {/* TODO */}
                      <VariantCard variant={PACK} onCardClick={() => { handleVariantCardClick(PACK) }} />
                    </AccordionDetails>
                  </Accordion>)}
                </Box>
              </>}
            </Grid>

          </Grid>
        </>}
        {variantSelected && <VariantModal variant={variantSelected} show={show} handleCloseModal={handleCloseModal} />}

        <Button
          color="primary"
          variant="contained"
          size="large"
          fullWidth={isMobile}
          onClick={getToCategories}
          startIcon={<ArrowBackIosIcon />}
          className={classes.gobackButton}
        >
          {t('text.go_back')}
        </Button>

        <Box className={classes.whatsappBox}>
          <Typography variant="h5" align="center" component="h2">
            {t('phrases.can_not_find_what_you_are_looking_for')}
          </Typography>
          <ReactWhatsapp number={AMZ_PHONE_NUMBER} message="" style={{ backgroundColor: 'transparent', border: 'none' }}>
            <Button
              endIcon={<WhatsAppIcon />}
              variant='outlined'
              size='small'
              style={{ marginTop: '24px' }}>
              {t('text.contact_us')}
            </Button>
          </ReactWhatsapp>
        </Box>

      </Container>
    </section>
  );
}

Services.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Services);


const PACK = {
  image: {
    full_url: "https://ameiz-prod.s3-sa-east-1.amazonaws.com/product/service-variant-area/611/c981a2b5-e1f3-48bb-9ffb-335a3f491afc.png",
    url: "https://ameiz-prod.s3-sa-east-1.amazonaws.com/product/service-variant-area/611/c981a2b5-e1f3-48bb-9ffb-335a3f491afc.png",
  },
  name: '10 Sesiones de Masajes',
  description: '10 Sesiones de Masajes relajantes.',
  price: 60000,
  duration: 70,
  pack: true,
  addOns: []
}