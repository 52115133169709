import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import ReferFriendCode from 'src/modules/views/ReferFriendCode';

function ReferFriend() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ReferFriendCode />
      <AppFooter />
    </React.Fragment>
  );
}

export default ReferFriend;
