import axios from "axios";

const AuthServices = {
  getLogged: async (loginData) => {
    console.log(`AuthServices.getLogged()`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/auth/login`;
    return axios.post(url, loginData);
  },
  isLogged: () => {
    console.log(`AuthServices.isLogged()`);
    const token = localStorage.getItem('access_token');
    const userStorageData = localStorage.getItem('user_data');
    if (token && userStorageData) {
      return {token, userStorageData};
    } else {
      return false;
    }
  },
};

export default AuthServices;