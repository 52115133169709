import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Box } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';

import MoneyDisplay from 'src/modules/components/MoneyDisplay';

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: 'auto'
  },
  media: {
    height: 140,
  },
});

export default function MediaCard({ onCardClick, id, variant }) {
  const { i18n, t } = useTranslation();
  const classes = useStyles();

  const handleOnClick = () => {
    onCardClick(id)
  }

  return (
    <Card className={classes.root} onClick={handleOnClick}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={variant.image?.full_url}
          title={variant.name}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {variant?.description.slice(0, 199)}...
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Box style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <Box>
            <Button color="primary">
              {t('text.from')}&nbsp;
              <MoneyDisplay
                prefix={i18n.language === "es_pe" ? 'S/.' : '$'}
                value={variant?.price}
                displayType={'text'} />
            </Button>
            <Button color="primary">
              <ScheduleRoundedIcon style={{ marginRight: '5px' }} fontSize={'small'} /> {variant?.duration} min
            </Button>
          </Box>
          <Button
            variant='outlined'
            color="primary"
          >
            Ver
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}
