import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { Container, Box, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import Button from 'src/modules/components/Button';
import Typography from 'src/modules/components/Typography';
import { logPurchase } from 'src/utils/eventsLogger';
import imageProfile from 'src/assets/image-profile.jpg';

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(14),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bookingContainer: {
    maxWitdh: 350,
    margin: '24px'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    minWidth: 350,
    maxWidth: 680,
    margin: '24px auto 0px auto'
  },
  loading: {
    textAlign: 'center',
    marginTop: '24px'
  },
  emptyMessageContainer: {
    marginTop: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(4),
    fontSize: '1.6rem'
  },
  subtitle: {
    marginBottom: theme.spacing(4),
  },
  tips: {
    marginBottom: theme.spacing(8),
    maxWidth: 650
  }
});

const TIPS = [
  {
    author: 'Daniela de Ameiz',
    title: '¿Qué pasa luego de reservar?',
    content: 'Después de recibir el correo de tu reserva recibirás uno distinto confirmando al Ameizer asignado, a quien podrás contactar por el chat de la aplicación y resolver cualquier duda que tengas.',
  }
]

const PaymentResultMessage = ({classes}) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { result } = useParams();

  const keepBuying = (e) => {
    e.preventDefault();
    navigate('/categoria-de-servicio');
  }

  const getToCart = (e, step) => {
    e.preventDefault();
    if (step) {
      navigate(`/cart?step=${step}`);
    } else {
      navigate('/cart');
    }
  }

  useEffect(() => {
    if (result === 'payment-success') {
      const cart = JSON.parse(localStorage.getItem('cart'));
      logPurchase(cart);
      localStorage.removeItem('cart');
    }
  }, [result]);

  const Message = () => {
    console.log("result", result);
    switch (result) {
      case 'payment-success':
        return  (
          <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '24px' }}>
            <Typography variant="h4" align="center" component="h2" className={classes.title}>
              {/* ¡{t('phrases.the_purchase_has_been_successfully_completed')}! */}
              ¡Felicitaciones por tu reserva!
            </Typography>
            <Typography variant="h5" align="center" component="h2" className={classes.subtitle}>
              {/* {t('phrases.we_are_preparing_the_best_wellness_experience')} */}
              Pronto vivirás una experiencia Ameiz. Para disfrutarla al máximo, lee estos tips:
            </Typography>

            <List className={classes.tips}>
              <Divider variant="inset" component="li" />
              {TIPS.map((tip,i) => <ListItem key={`tip_${i}`} alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt={tip.author} src={imageProfile} />
                </ListItemAvatar>
                <ListItemText
                  primary={tip.title}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {tip.author}
                      </Typography>
                      {` — ${tip.content}`}
                    </>
                  }
                />
              </ListItem>)}
              <Divider variant="inset" component="li" />
            </List>

            <Button
              color="secondary"
              variant="outlined"
              size="large"
              onClick={(e) => keepBuying(e)}
              className={classes.button}
            >
              {t('phrases.keep_buying')}
            </Button>
          </Box>
        )
        break
      case 'payment-failed': 
          return (
            <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '24px' }}>
              <Typography variant="h6" align="center" component="h2" className={classes.title}>
                {t('phrases.pay_error')}
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                onClick={(e) => getToCart(e, 2)}
                className={classes.button}
              >
                Ir al carrito
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                size="large"
                onClick={(e) => keepBuying(e)}
                className={classes.button}
              >
                {t('phrases.keep_buying')}
              </Button>
            </Box>
          )
          break
        case 'save-card-success': 
            return (
              <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '24px' }}>
                <Typography variant="h6" align="center" component="h2" className={classes.title}>
                  La tarjeta se ha guardado exitosamente.
                </Typography>
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={(e) => getToCart(e, 2)}
                  className={classes.button}
                >
                  Ir al carrito
                </Button>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  onClick={(e) => keepBuying(e)}
                  className={classes.button}
                >
                  {t('phrases.keep_buying')}
                </Button>
              </Box>
            )
          case 'save-card-failed':
            return (
              <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '24px' }}>
                <Typography variant="h6" align="center" component="h2" className={classes.title}>
                  Ha habido un error inténtalo mas nuevamente. 
                </Typography>
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={(e) => getToCart(e, 2)}
                  className={classes.button}
                >
                  Ir al carrito
                </Button>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  onClick={(e) => keepBuying(e)}
                  className={classes.button}
                >
                  {t('phrases.keep_buying')}
                </Button>
              </Box>
            )
            break
      default:  
        return (
          <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '24px' }}>
            <Typography variant="h6" align="center" component="h2" className={classes.title}>
              No hubo ninguna operación.
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              onClick={(e) => getToCart(e)}
              className={classes.button}
            >
              Ir al carrito
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              onClick={(e) => keepBuying(e)}
              className={classes.button}
            >
              {t('phrases.keep_buying')}
            </Button>
          </Box>
        )
        break    
    }
  }


  return (
    <Container className={classes.container}>
      { result && <Message /> }
    </Container>
  )
}

export default withStyles(styles)(PaymentResultMessage);

