import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import CardList from 'src/modules/views/CardList';

function PaymentMethods() {
  return (
    <React.Fragment>
      <AppAppBar />
      <CardList />
      <AppFooter />
    </React.Fragment>
  );
}

export default PaymentMethods;
