import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import Cart from 'src/modules/views/Cart'

function CartCheckout() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Cart />
      <AppFooter />
    </React.Fragment>
  );
}

export default CartCheckout;
