import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PaymentServices from 'src/services/PaymentServices';

export const useCurrentLang = () => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(null);  

  const langs = ['en_us', 'es_ar', 'es_cl', 'es_co', 'es_mx', 'es_pe'];

  useEffect(() => {
    getCurrentLang();
    // eslint-disable-next-line
  }, []);

  async function getCurrentLang() {
    if (localStorage.getItem('lang')) {
      await i18n.changeLanguage(localStorage.getItem('lang'));
      setCurrentLang(localStorage.getItem('lang'));
    } else {
      const response = await PaymentServices.getPublicIP();
      let currentLang = langs.find((l) => l.split('_')?.[1]?.toLowerCase() === response.data.country.toLowerCase());
      await i18n.changeLanguage(currentLang); // change app display lang
      localStorage.setItem('lang', currentLang);
      setCurrentLang(currentLang);
    }
  }

  return {
    currentLang
  }
}
