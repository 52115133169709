import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import CreateClientReview from 'src/modules/views/CreateClientReview';

function ClientReview() {
  return (
    <React.Fragment>
      <CreateClientReview />
      <AppFooter />
    </React.Fragment>
  );
}

export default ClientReview;
