import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import AmeizerDetails from 'src/modules/views/AmeizerDetails';

function AmeizerProfile() {
  return (
    <React.Fragment>
      <AppAppBar />
      <AmeizerDetails />
      <AppFooter />
    </React.Fragment>
  );
}

export default AmeizerProfile;
