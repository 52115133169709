import React, { useEffect, useContext } from 'react';
import { Box, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';

import { UserContext } from 'src/contexts/UserContext';
import { BookingContext } from 'src/contexts/BookingContext';

const styles = (theme) => ({
  root: {
    width: '250px'
  },
  rootMobile: {
    width: '151px'
  }
});

const AddressSelected = ({ classes, onClick, mobile }) => {
  const { defaultAddress, getSavedAddress } = useContext(UserContext);
  const { setAddressSelected } = useContext(BookingContext);

  useEffect(() => {
    getSavedAddress();
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (defaultAddress) {
      setAddressSelected(defaultAddress);
    }
    // eslint-disable-next-line 
  }, [defaultAddress]);

  return (
    <>
      {defaultAddress && <Box className={mobile ? classes.rootMobile : classes.root}>
        <Button
          variant={!mobile ? 'outlined' : 'text'}
          style={{ width: mobile && '100%', padding: mobile && '0px' }}
          color='inherit'
          onClick={onClick}
          startIcon={!mobile && <LocationOnOutlinedIcon />}
        >
          {!mobile && defaultAddress?.address != null && defaultAddress?.address.slice(0, 20) + '...'}
          {mobile && defaultAddress?.address != null && defaultAddress?.address.slice(0, 16) + '...'}
        </Button>
      </Box>}
    </>
  )
}

export default withStyles(styles)(AddressSelected);

