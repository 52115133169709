import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  Typography,
  Switch,
  CardHeader,
  Box,
  CardActions,
} from '@material-ui/core';

import MoneyDisplay from 'src/modules/components/MoneyDisplay';
import QuantitySelector from '../form/QuantitySelector';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginTop: 10,
    marginBottom: 20,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard({ handleSetAddons, addon, handleAddonQuantity }) {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const [addonState, setAddonState] = useState(false);

  const handleAddAddon = (value, addonId) => {
    setAddonState(value);
    handleSetAddons(addonId, value);
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <Switch
            checked={addonState}
            onChange={(e) => { handleAddAddon(e.target.checked, addon.addon_area_id) }}
            name="addon"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        }
        title={addon.name}
      />
      <CardContent style={{ marginTop: '0px', paddingTop: '0px' }}>
        <Typography className={classes.title} gutterBottom>
          <MoneyDisplay
            prefix={i18n.language === "es_pe" ? 'S/.' : '$'}
            value={addon.price}
            displayType={'text'} />
        </Typography>
        <Typography variant="body3" color="textSecondary" component="p">
          {addon.description}
        </Typography>
      </CardContent>
      <CardActions>
       {addonState && <Box display="flex" justifyContent="center" flexDirection="row" alignItems="center" m={1}>
          <Typography variant="body3" color="textSecondary" style={{ marginRight: 10, color: '#343434' }}>
            ¿Cuántos adicionales?
          </Typography>
          <QuantitySelector handleCounter={(quantity) => handleAddonQuantity(quantity, addon.addon_area_id)} />
        </Box>  }    
      </CardActions>
    </Card>
  );
}
