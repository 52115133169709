import { makeStyles } from '@material-ui/core/styles';
import {
	Card,
	CardContent,
	CardMedia,
	Typography,
} from '@material-ui/core';
import is from 'is_js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	root: {
		maxWidth: 800
	},
	multipleRoot: {
		maxWidth: 350
	},
	media: {
		height: 140,
	},
	quantityText: {
		marginRight: 10,
	},
	details: {
		width: '100%'
	},
	table: {
		minWidth: 650,
	},
	multipleTable: {
		minWidth: 250,
	},
	totalsTable: {
		minWidth: 650,
		marginTop: 24
	},
	commentTitle: {
		marginTop: 24
	},
	paymentMethodsAccordion: {
		marginTop: 24
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		textAlign: 'center'
	},
	button: {
		marginTop: '24px',
		width: '100%'
	},
	heading: {
		paddingLeft: '16px'
	}
});

export default function CardList({ cards, handleSelectPaymentmethod, handleAddNewCard, paymentMethods }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const handleToggle = (card, value) => {
		handleSelectPaymentmethod(card, value);
	}

	return (
		<Card className={classes.root}>
			<CardMedia
				className={classes.media}
				image={'https://s3.amazonaws.com/ameizproduction/site/client-form-bg.jpg'}
			// title={localBookingData?.serviceVariantArea?.name}
			/>
			<CardContent>
				<Typography className={classes.heading}>
					{t('phrases.select_a_card')}:
				</Typography>
				<List dense className={classes.root}>
					{is.not.empty(cards) && cards.map((c, i) => {
						const labelId = `card-list-secondary-label-${i}`;
						return (
							<ListItem key={"paymentMethod_" + i} style={{ marginTop: '16px' }}>
								<ListItemText id={labelId} primary={`${c.brand}`} />
								<ListItemText secondary={`**** **** **** ${c.last_four_digit}`} />
								<ListItemSecondaryAction>
									<Checkbox
										disabled={cards.find((card) => (card.last_four_digit !== c.last_four_digit) && card.checked)}
										edge="end"
										onChange={(e) => handleToggle(c, e.target.checked)}
										checked={c.checked}
										inputProps={{ 'aria-labelledby': labelId }}
									/>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
					{paymentMethods && paymentMethods.find((pm) => pm.name === 'Mercado Pago') && <ListItem key={"paymentMethod_mp"} style={{ marginTop: '16px' }}>
						<ListItemText id={'mp-label'} primary={"Mercado Pago"} />
						<ListItemSecondaryAction>
							<Checkbox
								disabled={cards.find((card) => card.checked)}
								edge="end"
								onChange={(e) => handleToggle("MercadoPago", e.target.checked)}
								checked={paymentMethods.find((pm) => pm.name === 'Mercado Pago').checked}
								inputProps={{ 'aria-labelledby': 'mp-label' }}
							/>
						</ListItemSecondaryAction>
					</ListItem>}
					<ListItem key={"paymentMethod_newCard"} button onClick={handleAddNewCard} style={{ marginTop: '16px' }}>
						<ListItemText primary={`Agregar tarjeta de crédito o débito`} />
						<ListItemSecondaryAction>
							<NavigateNextIcon />
						</ListItemSecondaryAction>
					</ListItem>
				</List>
			</CardContent>
		</Card>
	);
}
