import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';

export default withStyles(() => ({
  root: {
    color: '#ffb400',
    cursor: 'pointer',
    display: 'inline-flex',
    position: 'relative',
    textAlign: 'left',
    '-webkit-tap-highlight-color': 'transparent',
  },
  sizeLarge: {
    fontSize: '3.500rem',
  },
}))(Rating);
