import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import es_cl from 'src/i18n/es_cl';
import es_ar from 'src/i18n/es_ar';
import en_us from 'src/i18n/en_us';
import es_co from 'src/i18n/es_co';
import es_mx from 'src/i18n/es_mx';
import es_pe from 'src/i18n/es_pe';

i18n
	.use(initReactI18next)
	.init({
		resources: {
			en_us: {
				translation: en_us
			},
			es_ar: {
				translation: es_ar
			},
			es_cl: {
				translation: es_cl
			},
			es_co: {
				translation: es_co
			},
			es_mx: {
				translation: es_mx
			},
			es_pe: {
				translation: es_pe
			},
		},
		fallbackLng: 'es_cl',
		debug: process.env.NODE_ENV === 'development',
	});



export default i18n;