import { useEffect, useContext } from 'react';
import { AuthContext } from 'src/contexts/AuthContext';
import AuthServices from 'src/services/AuthServices';
import { getFirebaseToken }  from '../utils/firebase';

export const useLogged = () => {
  let { setLogged, setUserData } = useContext(AuthContext);

  useEffect(() => {
    isLoggedCheck();
    // eslint-disable-next-line
  }, []);

  const isLoggedCheck = async () => {
    const response = AuthServices.isLogged();
    if (response) {
      const { userStorageData } = response;
      setLogged(true);
      setUserData(JSON.parse(userStorageData));
      const userId = JSON.parse(userStorageData).user_id;
      console.log('userStorageData', JSON.parse(userStorageData));
      const customToken = {
        user_type: 'ameiz',
        user_id: userId,
        professional_id: null,
        admin: false
      }
      await getFirebaseToken(customToken);
    }
  }
}
