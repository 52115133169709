import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token');

  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, function (err) {
  return Promise.reject(err);
});

const AmeizerServices = {
  getProfessionals: async (filter) => {
    console.log(`Services.getProfessionals(${JSON.stringify(filter)})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/ameizer/search`;
    return axios.post(url, filter);
  },
  getProfessional: async (professional_id, filter) => {
    console.log(`Services.getProfessional(${professional_id})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/ameizer/${professional_id}/search`;
    return axios.post(url, filter);
  },
  getReviews: async (professional_id) => {
    console.log(`Services.getReviews()`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/ameizer/${professional_id}/reviews/search`;
    return axios.post(url, { professional_id: professional_id });
  },
  getPortfolio: async (professional_id) => {
    console.log(`Services.getReviews()`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/ameizer/${professional_id}/portfolio`;
    return axios.get(url);
  },
};

export default AmeizerServices;
