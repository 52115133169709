import { createContext, useState, useEffect } from "react";

let AuthContext = createContext();
let { Provider } = AuthContext;

function AuthProvider({ children }) {

  const [logged, setLogged] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    console.log("store userData", userData);
  }, [userData])

  useEffect(() => {
    console.log("store logged", logged);
  }, [logged]);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_data');
    localStorage.removeItem('default_address');
    localStorage.removeItem('firebaseToken');
    setLogged(false);
  }

  return (
    <Provider
      value={{
        logged,
        setLogged,
        userData,
        setUserData,
        handleLogout
      }}
    >
      {children}
    </Provider>
  );
}

export { AuthProvider, AuthContext };