import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Pack from 'src/modules/components/Pack';

const PackList = ({ classes }) => {
  const [packs, setPacks] = useState([1,2,3,4,5,6]);

  return (
    <Container className={classes.root}>
      {/* {packs.map((p) => <Pack />)} */}
    </Container>
  )
}

const styles = (theme) => ({
  root: {
    marginTop: '10px',
    marginBottom: '15px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

export default withStyles(styles)(PackList);
