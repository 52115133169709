export const COUNTRIES = [
  {
    country: "Argentina",
    lang: 'es_ar',
    center: {
      lat: -34.607159200483956,
      lng: -58.44738773108899
    },
    currency: "ARS"
  },
  {
    country: "Chile",
    lang: 'es_cl',
    center: {
      lat: -33.43774451396826,
      lng: -70.65101623535156
    },
    currency: 'CLP'
  },
  {
    country: "Perú",
    lang: 'es_pe',
    center: {
      lat: -12.036005250592334,
      lng: -77.03654141267982
    },
    currency: 'PEN'
  },
  {
    country: "Colombia",
    lang: 'es_co',
    center: {
      lat: 4.700061159024171,
      lng: -74.07552354517156
    },
    currency: 'COP'
  },
  {
    country: "Mexico",
    lang: 'es_mx',
    center: {
      lat: 19.41951223788174,
      lng: -99.13232437576265
    },
    currency: 'MXN'
  }
]