import React from 'react';
import NumberFormat from "react-number-format";

const PhoneDisplay = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator={false}
      decimalScale={0}
      fixedDecimalScale={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: `${values.value}`,
          },
        });
      }}

    />
  )
}

export default PhoneDisplay
