import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import UserWallet from 'src/modules/views/UserWallet';

function Wallet() {
  return (
    <React.Fragment>
      <AppAppBar />
      <UserWallet />
      <AppFooter />
    </React.Fragment>
  );
}

export default Wallet;
