import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token');

  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, function (err) {
  return Promise.reject(err);
});

const UserServices = {
  createUser: async (body) => {
    console.log(`UserServices.createUser(${JSON.stringify(body)})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user`;
    return axios.post(url, body);
  },
  getUser: async (userId) => {
    console.log(`UserServices.getUser(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${userId}`;
    return axios.get(url);
  },
  updateUser: async (userId, user) => {
    console.log(`UserServices.updateUser(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${userId}`;
    return axios.patch(url, user);
  },
  postAvatar: async (userId, avatar) => {
    console.log(`UserServices.postAvatar(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${userId}/avatar`;
    return axios.post(url, avatar);
  },
  resetPassword: (email) => {
    console.log(`UserServices.resetPassword(${email})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/password/reset`;
    return axios.post(url, { email });
  },
  getUserAdresses: async (userId) => {
    console.log(`UserServices.getUserAdresses(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${userId}/address`;
    return axios.get(url);
  },
  deleteUserAddress: async (userId, addressId) => {
    console.log(`UserServices.deleteUserAddress(${userId}-${addressId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${userId}/address/${addressId}`;
    return axios.delete(url);
  },
  createUserAddress: async (userId, newAddress) => {
    console.log(`UserServices.createUserAddress(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${userId}/address`;
    return axios.post(url, newAddress);
  },
  updateUserAddress: async (userId, addressId, updatedAddress) => {
    console.log(`UserServices.updateUserAddress(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${userId}/address/${addressId}`;
    return axios.patch(url, updatedAddress);
  },
  getCart: async (userId) => {
    console.log(`UserServices.getCart(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${userId}/shopping_cart`;
    return axios.get(url);
  },
  getCards: async (user_id, filter) => {
    console.log(`UserServices.getCards(${user_id})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${user_id}/card/search`;
    return axios.post(url, filter);
  },
  updateCard: async (user_id, card_id, isDefault) => {
    console.log(`UserServices.updateCard(${user_id})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${user_id}/card/${card_id}`;
    return axios.patch(url, { user_id: user_id, card_id: card_id, default: isDefault });
  },
  deleteCard: async (user_id, card_id) => {
    console.log(`UserServices.deleteCard(${user_id})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${user_id}/card/${card_id}`;
    return axios.delete(url);
  },
  addCard: async (user_id, card) => {
    console.log(`UserServices.addCard(${user_id})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${user_id}/card`;
    return axios.post(url, card);
  },
  deleteCard: async (user_id, card_id) => {
    console.log(`UserServices.deleteCard(${user_id})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${user_id}/card/${card_id}`;
    return axios.delete(url);
  },
  bookingSearch: async (user_id, filter) => {
    console.log(`UserServices.bookingSearch(${user_id})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${user_id}/booking/search`;
    return axios.post(url, filter);
  },
  postMessageAttachment: async (user_id, image) => {
    console.log(`UserServices.postMessageAttachment(${user_id})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${user_id}/message-attachment`;
    return axios.post(url, image);
  },
  getBalance: async (userId) => {
    console.log(`UserServices.getBalance(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${userId}/balance`;
    return axios.get(url);
  },
  getTransactions: async (userId, body) => {
    console.log(`UserServices.getTransactions(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${userId}/transaction/search`;
    return axios.post(url, body);
  },
  getFirebaseToken: (userId, customToken) => {
    console.log(`UserService.getFirebaseToken(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${userId}/firebase/token`;
    console.log('url', url);
    return axios.post(url, customToken);
  },
};

export default UserServices;