import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import is from 'is_js';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';

import Button from 'src/modules/components/Button';
import Typography from 'src/modules/components/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 800,
    minWidth: 270,
    marginTop: '24px'
  },
  media: {
    height: 140,
  },
  addAddressButton: {
    margin: 'auto'
  },
});

export default function UserAddresses({ addresses, handleDeleteAddress }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [localAddresses, setLocalAddresses] = useState({});

  useEffect(() => {
    if (addresses) {
      setLocalAddresses(addresses);
    }
  }, [addresses]);

  const handleLocalDeleteAddress = (addressId) => {
    let filteredList = localAddresses.filter((a) => a.id !== addressId);
    setLocalAddresses(filteredList);
    handleDeleteAddress(addressId);
  }

  const handleGetToAddNewAddress = (e) => {
    e.preventDefault();
    navigate('/perfil/direccion');
  }

  return (
    <>
      {
        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h6" marked="center" align="center" component="h2">
              {t('text.my_address')}
            </Typography>
            <List>
              {is.not.empty(localAddresses) &&
                localAddresses.sort((a, b) => a.id - b.id).map((a) => <ListItem key={"address_" + a.id}>
                  <IconButton color='primary'>
                    <ArrowForwardIosIcon />
                  </IconButton>
                  <ListItemText
                    primary={a.address}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={(e) => handleLocalDeleteAddress(a.id)} color='secondary'>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>)
              }
            </List>
          </CardContent>
          <CardActions disableSpacing>
            <Button
              className={classes.addAddressButton}
              onClick={handleGetToAddNewAddress}
              aria-label="Edit"
              variant='outlined'
              color='primary'
            >
              {t('text.add_address')}
            </Button>
          </CardActions>
        </Card>
      }
    </>
  );
}
