import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { useMediaQuery } from "react-responsive";
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';

import Typography from '../components/Typography';
import Instagram from 'src/assets/icons8-instagram-24.png';
import AmeizLogo from 'src/assets/ameiz-footer-logo.png';

function Copyright() {
  return (
    <React.Fragment>
      <span style={{ color: '#FFF' }}>{'© '}</span>
      <Link color="inherit" href="/" style={{ color: '#FFF' }}>
        Ameiz Technologies
      </Link>{' '}
      <span style={{ color: '#FFF' }}>
        {new Date().getFullYear()}
      </span>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#28282a',
    // backgroundColor: '#f9464630',
    bottom: '0',
    width: '100%',
  },
  mobileRoot: {
    display: 'flex',
    backgroundColor: '#28282a',
    // backgroundColor: '#f9464630',
    bottom: '0',
    width: '100%',
  },
  container: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    padding: '0px',
    width: '100%',
    bottom: '0',
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e3b9b3',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#e3b9b350',
    },
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
}));

export default function AppFooter() {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <Typography component="footer" className={isMobile ? classes.mobileRoot : classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5} style={{ width: '100%', margin: '0 auto' }} >
          <Grid item xs={12} sm={4} md={4} style={{ margin: '0 auto', padding: isMobile && '0px', textAlign: isMobile ? 'center' : 'left' }}>
            <Grid
              container
              direction="column"
              justify="center"
              className={classes.iconsWrapper}
              spacing={2}
            >
              <Grid item className={classes.icons} style={{ margin: isMobile && '0 auto' }}>
                <a href="https://www.facebook.com/ameizCL"  target="_blank" className={classes.icon}>
                  <img src="/static/images/appFooterFacebook.png" alt="Facebook" />
                </a>
                <a href="https://www.instagram.com/ameiz_cl/?hl=es-la" target="_blank" className={classes.icon}>
                  <img src={Instagram} alt="Instagram" />
                </a>
              </Grid>
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} md={4} style={{ textAlign: 'center', padding: isMobile && '0px' }} >
            <img src={AmeizLogo} alt="AmeizLogo" width='60px' height='auto' />
          </Grid>
          <Grid item xs={12} sm={4} md={4} style={{ textAlign: isMobile ? 'center' : '-webkit-right', padding: isMobile && '0px' }}>
            <Typography variant="h6" marked={isMobile ? 'center' : "left"} style={{ color: '#FFF' }} gutterBottom>
              Legal
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link href="https://home.ameiz.app/terminos-y-condiciones/" target="_blank" rel="noopener">
                  <span style={{ color: '#FFF' }}> {t('text.terms_and_conditions')}</span>
                </Link>
              </li>
              {/* <li className={classes.listItem}>
                <Link href="/politica-de-privacidad">Política de privacidad</Link>
              </li> */}
            </ul>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
