import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, TextField, MenuItem, Typography, Link } from '@material-ui/core';
import { Container, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Marker, GoogleMap, Autocomplete } from '@react-google-maps/api';
import is from 'is_js';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";
import MuiTypography from '@material-ui/core/Typography';
import { useMediaQuery } from "react-responsive";

import { BookingContext } from 'src/contexts/BookingContext';
import { AuthContext } from 'src/contexts/AuthContext';
import Button from '../components/Button';
import Services from 'src/services/Services';
import UserServices from 'src/services/UserServices';
import { COUNTRIES } from 'src/utils/constants';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(2, 8),
    border: '4px solid #f9464695',
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  address: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  buoy: {
    width: 60,
  },
  mainTitle: {
    borderRadius: 0,
    padding: theme.spacing(2, 2),
    fontSize: '1.6rem',
    fontWeight: 'normal'
  },
  mobileMainTitle: {
    borderRadius: 0,
    padding: theme.spacing(2, 0),
    fontSize: '1rem',
    fontWeight: 'normal'
  },
  button: {
    marginTop: '24px',
    minWidth: 200,
  },
  autocomplete: {
    width: '100%'
  },
  addressContainer: {
    width: '100%',
    display: 'flex'
  },
  addressMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  pinMessage: {
    marginTop: '12px',
    fontWeight: 200,
    fontSize: '0.9rem',
  },
  googleMessage: {
    fontWeight: 100,
    fontSize: '0.8rem',
    marginBottom: '12px',
    marginTop: '0px',
  },
});

const containerStyle = {
  width: '100%',
  height: '300px'
};

function AddressMap(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const { classes } = props;
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  let { userData, logged } = useContext(AuthContext);
  let {
    addressSelected,
    setAddressSelected,
    setConfirmedCoordinates,
    confirmedCoordinates,
    setAvailableCategories,
  } = useContext(BookingContext);
  const [autocomplete, setAutocomplete] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [oldAddressSelected, setOldAddressSelected] = useState(null);
  const [extraInfo, setExtraInfo] = useState('');

  const containerSpacing = {
    padding: isMobile ? '16px 20px' : "16px 64px",
  };

  useEffect(() => {
    if (userData && logged) {
      getUserAddresses();
    }
    // eslint-disable-next-line
  }, [logged]);

  const getUserAddresses = async () => {
    try {
      const response = await UserServices.getUserAdresses(userData.user_id);
      let country = COUNTRIES.find((c) => c.lang === i18n.language);
      let filteredList = response.data.filter((a) => a.country === country.country);
      setAddresses(filteredList);
    } catch (error) {
      console.log(error);
    }
  }

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete)
  }

  const onPlaceChanged = (event) => {
    setErrors({});
    if (autocomplete !== null) {
      let newAddress = autocomplete.getPlace();
      if (newAddress.address_components) {
        if (newAddress.address_components.find((a) => a.types.find((t) => t === "street_number"))) {
          let newAddressCompleted = {
            ...newAddress,
            extra_info: extraInfo
          }
          setAddressSelected(newAddressCompleted);
          setConfirmedCoordinates(null);
          setOldAddressSelected(null);
        } else {
          setErrors({ ...errors, address: 'Ingresa una numeración.' })
        }
      }
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  const onLoadMarker = marker => {
    console.log('marker: ', marker)
  }

  const handlePointMove = (e) => {
    setConfirmedCoordinates(e.latLng);
  }

  const handleNextStep = async () => {

    if (addressSelected && !oldAddressSelected && logged) {
      setIsLoading(true);
      let newAddressCompleted = {
        ...addressSelected,
        extra_info: extraInfo
      }

      const { address_components, formatted_address, geometry, place_id, plus_code, types } = newAddressCompleted;

      let googleData = {
        results: [
          {
            address_components,
            formatted_address,
            geometry,
            place_id,
            plus_code,
            types
          }
        ],
        status: "OK"
      }

      let body = {
        formatted_address: newAddressCompleted.formatted_address,
        address: newAddressCompleted.formatted_address,
        extra_info: newAddressCompleted.extra_info,
        has_parking: false,
        user_id: userData.user_id,
        default: true,
        google_data: googleData,
        coordinate: {
          lat: newAddressCompleted.geometry.location.lat(),
          lng: newAddressCompleted.geometry.location.lng()
        }
      }

      try {
        const resp = await UserServices.createUserAddress(userData.user_id, body);
        localStorage.setItem('default_address', JSON.stringify(resp.data));
        navigate('/categoria-de-servicio');
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate('/categoria-de-servicio');
    }

  }

  useEffect(() => {
    if (!addressSelected) {
      return;
    }
    fetchCategories();
    // eslint-disable-next-line
  }, [addressSelected])

  function fetchCategories() {
    setIsLoading(true);
    return Services.getCategories(getCoordinates()).then((response) => {
      setAvailableCategories(response.data);
      setIsLoading(false);
    });
  }

  function getCoordinates() {
    let coordinates;
    if (confirmedCoordinates) {
      console.info(confirmedCoordinates);
      coordinates = { coordinate: { lat: confirmedCoordinates.lat(), lng: confirmedCoordinates.lng() } };
    } else if (addressSelected?.coordinate) {
      coordinates = { coordinate: { lat: addressSelected.coordinate.lat, lng: addressSelected.coordinate.lng } };
    } else if (addressSelected) {
      coordinates = { coordinate: { lat: addressSelected?.geometry.location.lat(), lng: addressSelected?.geometry.location.lng() } };
    } else {
      let country = COUNTRIES.find((c) => c.lang === i18n.language);
      coordinates = { coordinate: { lat: country.center.lat, lng: country.center.lng } };
    }
    return coordinates;
  }

  const handleSelectOldAddress = (address) => {
    setOldAddressSelected(address);
    setAddressSelected(address);
    localStorage.setItem('default_address', JSON.stringify(address));
    setExtraInfo(null);
    setErrors({});
  }

  return (
    <Container className={classes.root} component="section" maxWidth="md" style={{ ...containerSpacing }}>

      <MuiTypography variant="h4" component="span" className={isMobile ? classes.mobileMainTitle : classes.mainTitle} >
        {t('phrases.address_search_title')}
      </MuiTypography>

      {logged && is.not.empty(addresses) && <TextField
        id="outlined-select-currency"
        select
        size="small"
        color='secondary'
        fullWidth
        key={oldAddressSelected}
        value={oldAddressSelected}
        label={t('phrases.select_address')}
        onChange={(e) => handleSelectOldAddress(e.target.value)}
        style={{ marginBottom: '12px' }}
        variant="outlined"
      >
        {addresses.map((item) => (
          <MenuItem key={item.id + "address"} value={item}>
            {item.address} {item?.extra_info}
          </MenuItem>))
        }
      </TextField>}

      {(is.empty(addresses) || !logged) && <Box style={{width: '100%'}}>
          <Box className={isMobile ? classes.addressMobileContainer : classes.addressContainer}>
            <Autocomplete
              restrictions={{ country: i18n.language.slice(3) }}
              onLoad={onLoad}
              onPlaceChanged={(e) => onPlaceChanged(e)}
              className={classes.autocomplete}
            >
              <TextField
                size="small"
                id="main-address"
                variant="outlined"
                color='secondary'
                placeholder={addressSelected?.formatted_address || t('phrases.enter_a_new_address')}
                error={!!errors.address}
                helperText={errors.address}
                style={{ width: '100%', marginBottom: isMobile ? '12px' : '0px', paddingRight: isMobile ? '0px' : '5px' }}
                autoComplete="off"
              />
            </Autocomplete>
            <TextField
              id="extra-info"
              label={t('text.floor_and_house')}
              color='secondary'
              required
              value={extraInfo}
              onChange={(e) => setExtraInfo(e.target.value)}
              fullWidth
              size="small"
              style={{ marginBottom: isMobile ? '12px' : '0px', paddingLeft: isMobile ? '0px' : '5px' }}
              variant="outlined" />
          </Box>
          <Typography className={classes.googleMessage} align="rigth">
            Selecciona una dirección sugerida por Google para poder guardar.
          </Typography>
        </Box>
      }

      <GoogleMap
        id="marker-example"
        mapContainerStyle={containerStyle}
        center={{ ...getCoordinates() }.coordinate}
        zoom={addressSelected ? 16 : 12} >
        {addressSelected && <Marker
          onLoad={onLoadMarker}
          draggable={true}
          onDragEnd={(e) => { handlePointMove(e) }}
          position={{ ...getCoordinates() }.coordinate}
        />}
      </GoogleMap>
      <Typography className={classes.pinMessage} align="center">
        {t('phrases.move_the_pin')}
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={handleNextStep}
        disabled={!addressSelected}
        className={classes.button}
      >
        {isLoading ? <CircularProgress color='inherit' /> : t('phrases.select_service')}
      </Button>

      {logged && is.not.empty(addresses) && <Link
        color="inherit"
        href={'/perfil/direccion?init=true'}
      >
        Agregar nueva dirección
      </Link>}

    </Container>
  );
}

AddressMap.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddressMap);

