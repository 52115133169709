import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  Container,
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ReactWhatsapp from 'react-whatsapp';
import is from 'is_js';
import SwipeableViews from 'react-swipeable-views';
import Autocomplete from '@material-ui/lab/Autocomplete';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Avatar from '@material-ui/core/Avatar';
import { useLocation } from "react-router-dom";

import Button from 'src/modules/components/Button';
import Typography from 'src/modules/components/Typography';
import { BookingContext } from 'src/contexts/BookingContext';
import { logWhatsappContact } from 'src/utils/eventsLogger';
import AppTab from 'src/modules/components/AppTab';
import AmeizerList from 'src/modules/views/AmeizerList';
import AmeizServices from 'src/services/AmeizServices';
import VariantModal from 'src/modules/components/VariantModal';
import { RouterContext } from 'src/contexts/RouterContext';

const AMZ_PHONE_NUMBER = '56930397449';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    minHeight: '450px'
  },
  images: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
    // justifyContent: 'space-between'
  },
  imageWrapper: {
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '40vh',
    margin: '0px 0px 5px 5px',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
      height: 100,
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $imageBackdrop': {
      opacity: 0.15,
    },
    '&:hover $imageMarked': {
      opacity: 0,
    },
    '&:hover $imageTitle': {
      border: '4px solid currentColor',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    flexDirection: 'column',
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
    marginBottom: theme.spacing(2),
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  loading: {
    textAlign: 'center',
    marginTop: '24px',
    width: '100%'
  },
});

function ProductCategories({ classes }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  let {
    availableCategories,
    categoryLoading,
    getCategoryList,
    addressSelected,
  } = useContext(BookingContext);
  let { previousPath, setPreviousPath } = useContext(RouterContext);
  const [tabValue, setTabValue] = useState(0);
  const [options, setOptions] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [variantSelected, setVariantSelected] = useState(null);

  useEffect(() => {
    if(previousPath && previousPath.includes('ameizer')) {
      setTabValue(1);
      setPreviousPath('');
    }
  }, [previousPath]);

  const handleChangeIndex = (index) => {
    setTabValue(index);
  };

  useEffect(() => {
    if (localStorage.getItem('default_address')) {
      getCategoryList();
    }
    // eslint-disable-next-line
  }, [addressSelected]);

  const handleSelectCategory = (categoryId) => {
    navigate(`/categoria/${categoryId}/servicios`);
  }

  const handleClickWhatsapp = (e) => {
    logWhatsappContact('CategoryListNoCoverage')
  }

  const handleSearchServices = async (query) => {
    if (!query) {
      setOptions([]);
    }
    if (query.length > 2) {
      setSearchLoading(true);
      let filter = {
        coordinate: {
          lat: addressSelected.coordinate.lat,
          lng: addressSelected.coordinate.lng
        },
        text: query
      }
      try {
        const response = await AmeizServices.search(filter);
        let serviceVariantList = response.data.services.map((s) => s.variants).flat();
        console.log('serviceVariantList', serviceVariantList);
        setOptions(serviceVariantList);
      } catch (error) {
        console.log(error);
      } finally {
        setSearchLoading(false);
      }
    }
  }

  const handleOpenServiceVariant = (variant) => {
    setVariantSelected(variant);
  }

  const handleCloseModal = () => {
    setVariantSelected(null);
  }

  return (
    <Container className={classes.root} component="section">
      <Typography variant="h4" marked="center" align="center" component="h2" style={{ fontSize: '1.2rem' }}>
        {t('phrases.what_will_be_your_experience_today')}
      </Typography>

      <AppTab value={tabValue} setValue={setTabValue} tabs={['Servicios', 'Ameizers']}/>

      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabValue}
        onChangeIndex={handleChangeIndex}
      >
       
        <TabPanel value={tabValue} index={0} dir={theme.direction}>
          {categoryLoading && <Box className={classes.loading}>
            <CircularProgress color="secondary" />
          </Box>}

          {!categoryLoading && <Autocomplete
            id="sva-autocomplete"
            options={options}
            loading={searchLoading}
            onInputChange={(event, value) => handleSearchServices(value)}
            onChange={(event, value) =>  handleOpenServiceVariant(value)}
            getOptionLabel={(option) => option.name}
            style={{ width: '90%', margin: '0 auto' }}
            freeSolo
            renderOption={(option) => <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Box style={{ display: 'flex' }}>
                <Avatar alt={option.name} src={option.image.url} style={{ marginRight: '8px' }} />
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography noWrap>{option.name}</Typography>
                  <Typography 
                    noWrap 
                    variant="subtitle2" 
                    component="h2" 
                    style={{ color: '#909090', fontWeight: 400 }}
                  > 
                    Desde ${Number(option?.price).toFixed(0)}
                  </Typography>
                </Box>
              </Box>
              <IconButton>
                <ArrowForwardIosRoundedIcon />
              </IconButton>
              </Box>}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Encuentra tu próxima experiencia"
                variant="outlined"
                style={{ backgroundColor: '#e3b9b333' }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {searchLoading ? <CircularProgress color="inherit" size={20} /> : <SearchRoundedIcon/>}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />}

          {!categoryLoading && <div className={classes.images}>
            {(is.empty(availableCategories) || !availableCategories) && <Box className={classes.loading}>
              <Typography variant="h5" align="center" component="h2">
                {t('phrases.no_coverage_for_the_selected_address')}
              </Typography>
              <ReactWhatsapp number={AMZ_PHONE_NUMBER} onClick={handleClickWhatsapp} message="" style={{ backgroundColor: 'transparent', border: 'none' }}>
                <Button
                  endIcon={<WhatsAppIcon />}
                  variant='outlined'
                  style={{ marginTop: '24px' }}>
                  {t('phrases.contact_us_message')}
                </Button>
              </ReactWhatsapp>
            </Box>}

            {availableCategories && availableCategories.map((category, i) => (<ButtonBase
              key={"category_" + i}
              onClick={() => { handleSelectCategory(category.id) }}
              className={classes.imageWrapper}
              style={{
                width: '45%',
              }}
            >
              <div
                className={classes.imageSrc}
                style={{
                  backgroundImage: `url(${category.web_image.url})`,
                }}
              />
              <div className={classes.imageBackdrop} />
              <div className={classes.imageButton}>
                <Typography
                  component="h3"
                  variant="h6"
                  color="inherit"
                  className={classes.imageTitle}>
                  {category.name}
                  <div className={classes.imageMarked} />
                </Typography>
                <Typography variant="button">
                  {category.description}
                </Typography>
              </div>
            </ButtonBase>
            ))}
          </div>}
          
        </TabPanel>
        <TabPanel value={tabValue} index={1} dir={theme.direction}>
          <AmeizerList />
        </TabPanel>
      </SwipeableViews>

      {!categoryLoading && is.not.empty(availableCategories) && <Box className={classes.loading}>
        <Typography variant="h5" align="center" component="h2">
          {t('phrases.can_not_find_what_you_are_looking_for')}
        </Typography>
        <ReactWhatsapp number={AMZ_PHONE_NUMBER} onClick={handleClickWhatsapp} message="" style={{ backgroundColor: 'transparent', border: 'none' }}>
          <Button
            endIcon={<WhatsAppIcon />}
            variant='outlined'
            size='small'
            style={{ marginTop: '24px' }}>
            {t('text.contact_us')}
          </Button>
        </ReactWhatsapp>
      </Box>}

      {variantSelected && <VariantModal variant={variantSelected} show={variantSelected} handleCloseModal={handleCloseModal} />}

    </Container>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default withStyles(styles)(ProductCategories);
