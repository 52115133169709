import { createContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router';
import moment from 'moment';

import ShoppingCartServices from "src/services/ShoppingCartServices";
import UserServices from "src/services/UserServices";
import Services from 'src/services/Services';
import { logAddToCart, logNoCoverage } from 'src/utils/eventsLogger';

let BookingContext = createContext();
let { Provider } = BookingContext;

function BookingProvider({ children }) {
  const navigate = useNavigate();
  const [addressSelected, setAddressSelected] = useState(null);
  const [confirmedCoordinates, setConfirmedCoordinates] = useState(null);
  const [availableCategories, setAvailableCategories] = useState(null);
  const [booking, setBooking] = useState({});
  const [lastBookingId, setLastBookingId] = useState(null);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [favouriteProfessional, setFavouriteProfessional] = useState(null);

  useEffect(() => {
    console.log("store booking", booking);
  }, [booking]);

  useEffect(() => {
    console.log("store booking address selected", addressSelected);
  }, [addressSelected]);

  const createBooking = async (userId, step) => {

    let addressId;
    // save address
    try {
      const response = await UserServices.getUserAdresses(userId);

      if (response.data.find((a) => a.address === addressSelected.formatted_address || a.id === addressSelected.id)) {
        addressId = response.data.find((a) => a.address === addressSelected.formatted_address || a.id === addressSelected.id).id;
        localStorage.setItem('default_address', JSON.stringify(response.data.find((a) => a.address === addressSelected.formatted_address || a.id === addressSelected.id)));
        let updatedAddress = {
          id: addressId,
          default: true,
        }
        await UserServices.updateUserAddress(userId, addressId, updatedAddress);
      } else {
        const { address_components, formatted_address, geometry, place_id, plus_code, types } = addressSelected;

        let googleData = {
          results: [
            {
              address_components,
              formatted_address,
              geometry,
              place_id,
              plus_code,
              types
            }
          ],
          status: "OK"
        }

        let newAddress = {
          formatted_address: addressSelected.formatted_address,
          address: addressSelected.formatted_address,
          extra_info: addressSelected.extra_info,
          google_data: googleData,
          default: true,
          user_id: userId,
          coordinate: {
            lat: addressSelected.coordinate ? addressSelected.coordinate.lat : addressSelected.geometry.location.lat(),
            lng: addressSelected.coordinate ? addressSelected.coordinate.lng : addressSelected.geometry.location.lng()
          }
        }
        const newAddressResponse = await UserServices.createUserAddress(userId, newAddress);
        localStorage.setItem('default_address', JSON.stringify(newAddressResponse.data));
        addressId = newAddressResponse.data.id;
      }

      // create 
      let addonsAreas = [];
      for (let i in booking.addonsSelected) {
        if (booking.addonsSelected[i].active) {
          let newAddonArea = {
            addon_area_id: parseInt(i),
            quantity: booking.addonsSelected[i].quantity,
          }
          addonsAreas.push(newAddonArea)
        }
      }

      let newBooking = {
        service_variant_area_id: booking.service_variant_area_id,
        quantity: booking.peopleCount || 1,
        add_ons: addonsAreas,
        address_id: addressId,
        channel: 'web',
        favourite_ameizers_ids: favouriteProfessional ? [Number(favouriteProfessional)] : null,
      }

      const bookingResponse = await ShoppingCartServices.createBooking(newBooking);
      setBooking(bookingResponse.data);
      setLastBookingId(bookingResponse.data.last_created_booking);
      await logAddToCart(bookingResponse.data);
      if (step) {
        navigate('/fecha');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const addBookingDate = async (date, time, cartId, oldBookingId) => {
    let hours = time.slice(0, 2);
    let minutes = time.slice(3);
    let newDate =  moment(date).utc().format();
    let dateCompleted = moment(newDate).utc().set({hour: hours ,minute: minutes,second:0,millisecond:0});

    let bookingUpdated = {
      shopping_cart_id: cartId ? cartId : booking.id,
      booking_id: oldBookingId ? oldBookingId : lastBookingId,
      date: moment(dateCompleted).format(),
    }

    try {
      const response = await ShoppingCartServices.updateBooking(bookingUpdated.booking_id, bookingUpdated);
      setBooking(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const addBookingComment = async (comment, cartId, oldBookingId) => {
    let bookingUpdated = {
      shopping_cart_id: cartId ? cartId : booking.id,
      booking_id: oldBookingId ? oldBookingId : lastBookingId,
      comment
    }
    try {
      const response = await ShoppingCartServices.updateBooking(bookingUpdated.booking_id, bookingUpdated);
      setBooking(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleDeleteBooking = async (bookingId) => {
    try {
      const response = await ShoppingCartServices.deleteBooking(bookingId);
    } catch (error) {
      console.log(error);
    }
  }

  const getCategoryList = async () => {
    setCategoryLoading(true);
    const savedAddress = JSON.parse(localStorage.getItem('default_address'));
    try {
      const response = await Services.getCategories({ coordinate: { lat: savedAddress.coordinate.lat, lng: savedAddress.coordinate.lng } });
      setAvailableCategories(response.data);
      if (response.data.length === 0) {
        logNoCoverage(savedAddress);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCategoryLoading(false);
    }
  }

  return (
    <Provider
      value={{
        setAddressSelected,
        addressSelected,
        confirmedCoordinates,
        setConfirmedCoordinates,
        availableCategories,
        setAvailableCategories,
        setBooking,
        booking,
        createBooking,
        addBookingDate,
        lastBookingId,
        addBookingComment,
        handleDeleteBooking,
        categoryLoading,
        getCategoryList,
        setFavouriteProfessional,
        setLastBookingId
      }}
    >
      {children}
    </Provider>
  );
}

export { BookingProvider, BookingContext };