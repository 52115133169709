import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token');

  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, function (err) {
  return Promise.reject(err);
});

const BannersServices = {
  getBanners: async (params) => {
    console.log(`Bannersservice.getBanners()`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/banner/search`;
    return axios.post(url, params);
  },
};

export default BannersServices;