import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import UserProfileEdit from 'src/modules/views/UserProfileEdit';

function Profile() {
  return (
    <React.Fragment>
      <AppAppBar />
      <UserProfileEdit />
      <AppFooter />
    </React.Fragment>
  );
}

export default Profile;
