import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import Checkout from 'src/modules/views/Checkout';

function BookingCheckout() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Checkout/>
      <AppFooter />
    </React.Fragment>
  );
}

export default BookingCheckout;
