import React, { useState, useEffect, useContext } from 'react';
import { 
  Container, 
  Box, 
  Card, 
  CardContent, 
  CardMedia, 
  IconButton, 
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  ListItemText,
  CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import is from 'is_js';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';

import UserServices from 'src/services/UserServices';
import { AuthContext } from 'src/contexts/AuthContext';
import Typography from '../components/Typography';

const UserWallet = ({ classes }) => {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);
  const [balance, setBalance] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [range, setRange] = useState({ endDate: moment().toDate(), startDate: moment().subtract(1, 'month').toDate(), });

  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = async () => {
    setIsLoading(true);
    try {
      const resp = await UserServices.getBalance(userData.user_id);
      // TODO: JUST SET THE CURRENT COUNTRY BALANCE
      setBalance(resp.data);
      console.log('getBalance', resp.data);
      getTransactions(moment(range.startDate).format('YYYY-MM-DD'), moment(range.endDate).format('YYYY-MM-DD'));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getTransactions = async (from, to) => {
    setIsLoadingTransactions(true);
    const body = {
      // from: from,
      // to: to,
    }
    try {
      const resp = await UserServices.getTransactions(userData.user_id, body);
      console.log('getTransactions', resp.data);
      // TODO: JUST SET THE CURRENT COUNTRY TRANSACTION
      setTransactions(resp.data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingTransactions(false);
    }
  }

  const getIcon = (concept) => {
    if (concept === 'purchase') {
      return <ShoppingCartOutlinedIcon />;
    }
    if (concept === 'referred') {
      return <CardGiftcardOutlinedIcon />;
    }
    if (concept === 'refund') {
      return <SettingsBackupRestoreOutlinedIcon />;
    }
  }

  return (
    <Container className={classes.root}>
      <Typography variant="h4" marked="center" align="center" component="h2" className={classes.mainTitle}>
        Mi billetera
      </Typography>

      <Card className={classes.container}>
        <CardContent>
          <Typography variant="h6" align="left" component="h2">
            Balance total: 
          </Typography>
          <Divider/>
          
          {isLoading && <Box className={classes.loading}><CircularProgress/></Box>}
          {/* {!isLoading && is.not.empty(balance) && <Typography variant="h4" align="center" component="h2" className={classes.balance}>
            {`${balance[0].currency.symbol} ${balance[0].amount ? balance[0].amount : 0}`}
          </Typography>} */}
          <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            {!isLoading && is.not.empty(balance) && balance.map((b) => <Typography variant="h4" align="center" component="h2" className={classes.balance}>
              {`${b.currency.symbol} ${b.amount ? b.amount : 0}`}
            </Typography>)}
          </Box>

          <Typography variant="h6" align="left" component="h2">
            Transacciones
          </Typography>
          <Divider/>
          <List>
            {isLoadingTransactions && <Box className={classes.loading}><CircularProgress/></Box>}
            {!isLoadingTransactions && is.empty(transactions) && <Box style={{ textAlign: 'center', marginTop: 24 }}><Typography>No hay transacciones.</Typography></Box>}
            {!isLoadingTransactions && is.not.empty(transactions) && transactions.map((ts,i) => <Box key={'transaction_' + i}><ListItem>
              <ListItemAvatar>
                <Avatar>
                  {getIcon(ts.concept)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t('transaction_concepts.' + ts.concept)}
                secondary={moment(ts.created_date).format('DD-MM-YYYY')}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={`${ts.currency.symbol} ${ts.amount ? ts.amount : 0 }`}
                  style={{ color: ts.amount < 0 ? 'red' : 'black' }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
            </Box>
            )}
          </List>
        </CardContent>
      </Card>
    </Container>
  )
}

const styles = (theme) => ({
  root: {
    marginTop: '10px',
    marginBottom: '24px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // height: '550px'
  },
  mainTitle: {
    marginTop: '24px'
  },
  container: {
    width: '50%',
		marginTop: '24px',
	},
	media: {
		height: 140,
		width: 140,
		borderRadius: '100%',
		margin: 'auto'
	},
	expand: {
		// marginLeft: 'auto',
	},
	editButtonContainer: {
		textAlign: 'right',
		width: '100%'
	},
	balance: {
    marginTop: '24px',
		marginBottom: '24px'
	},
  loading: {
    marginTop: '24px',
		marginBottom: '24px',
    textAlign: 'center'
  }
});

export default withStyles(styles)(UserWallet);
