import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import is from 'is_js';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { useMediaQuery } from "react-responsive";

import Typography from 'src/modules/components/Typography';
import { AuthContext } from 'src/contexts/AuthContext';
import { UserContext } from 'src/contexts/UserContext';
import UserServices from 'src/services/UserServices';

const styles = (theme) => ({
  root: {
    textAlign: 'center',
  },
  list: {
    margin: '24px',
    borderRadius: '4px',
    boxShadow: '0 1px 4px 0 rgb(0 0 0 / 23%)',
    border: '.0625em solid #e6e6e6'
  },
  listItem: {
    borderTop: '.0625em solid #e6e6e6',
    // padding: '10px 0px',

  },
  title: {
    paddingTop: '24px'
  },
  buttonContainer: {
    marginBottom: '24px',
    marginRight: '24px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonContainerMobile: {
    marginBottom: '24px',
    padding: '0px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  }
});

const GreenCheckbox = withStyles({
  root: {
    color: 'grey',
    '&$checked': {
      color: '#04332f',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const DefaultAddressModal = ({ classes, onClose, open }) => {
  const { i18n, t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const { defaultAddress, saveAddressInLocalStorage } = useContext(UserContext);
  const [addresses, setAddresses] = useState([]);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    if (defaultAddress && addresses) {
      setChecked([addresses.find((a) => a.id === defaultAddress.id)]);
    }
  }, [defaultAddress, addresses])

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);

    if (currentIndex === -1) {
      setChecked([value]);
    } else {
      setChecked([]);
    }
  };

  useEffect(() => {
    if (userData) {
      getAddressList();
    }
    // eslint-disable-next-line
  }, [userData]);

  const getAddressList = async () => {
    try {
      const response = await UserServices.getUserAdresses(userData.user_id);
      let filteredList = response.data.filter((a) => COUNTRIES[a?.country] === i18n.language)
      setAddresses(filteredList);
    } catch (error) {
      console.log(error);
    }
  }

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    handleClose();
    saveAddressInLocalStorage(checked[0]);
  }

  const handleAddNewAddress = () => {
    navigate('/perfil/direccion?init=true');
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.root}>
      <DialogTitle
        className={classes.title}
        id="address-dialog-title">
        <Typography variant="h6" marked="center" align="center" component="h2">
          {t('phrases.choose_where_you_will_live_your_experience')}
        </Typography>
      </DialogTitle>

      <List className={classes.list}>
        {open && is.not.empty(checked) && addresses.map((value, i) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItem key={value.id} role={undefined} dense button onClick={handleToggle(value)} className={i !== 0 && classes.listItem}>
              <ListItemIcon>
                <GreenCheckbox
                  edge="start"
                  // style={{  color: '#04332f'  }}
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.address}`} />
            </ListItem>
          );
        })}
        <ListItem key={'add_address'} dense button onClick={handleAddNewAddress} className={classes.listItem}>
          <ListItemIcon>
            <AddBoxOutlinedIcon style={{ marginLeft: '-2px', color: '#04332f' }}  />
          </ListItemIcon>
          <ListItemText
            style={{ color: '#04332f' }}
            primary={'Agregar una dirección'} />
        </ListItem>
      </List>
      <Box className={isMobile ? classes.buttonContainerMobile : classes.buttonContainer}>
        <Button
          variant='contained'
          color='secondary'
          style={{ marginRight: !isMobile && '12px', fontSize: '14px' }}
          onClick={handleClose}
        >
          {t('text.cancel')}
        </Button>
        <Button
          variant='outlined'
          // color='secondary'
          style={{ fontSize: '14px', marginTop: isMobile && '14px', color: '#04332f', borderColor: '#04332f'}}
          disabled={is.empty(checked)}
          onClick={handleSave}
        >
          {t('text.save_changes')}
        </Button>
      </Box>
    </Dialog>
  )
}

export default withStyles(styles)(DefaultAddressModal);

const COUNTRIES = {
  'Chile': 'es_cl',
  'Perú': 'es_pe',
  'Colombia': 'es_co',
  'Argentina': 'es_ar',
  'Mexico': 'es_mx'
}