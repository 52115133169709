import { useEffect, useMemo, useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Box, CircularProgress,
} from '@material-ui/core';
import {
  GoogleMap, Marker, OverlayView,
} from '@react-google-maps/api';

import Typography from 'src/modules/components/Typography';
import { db } from 'src/utils/firebase';
import AmeizerIcon from 'src/assets/ameizer-tracking2.png';
import { getFirebaseToken } from 'src/utils/firebase';
import { AuthContext } from 'src/contexts/AuthContext';

const containerStyle = {
  width: '100%',
  height: '400px',
  marginBottom: 24
};

const ProfessionalTracking = ({ classes, booking }) => {
  const { userData } = useContext(AuthContext);
  const [center, setCenter] = useState({ lat: -33.43774451396826, lng: -70.65101623535156 });
  const [location, setLocation] = useState(null);
  const [destinationCoordinates, setDestinationCoordinates] = useState(null);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    !isAuth && booking && handleFirebaseAuth();
  }, [isAuth]);

  const handleFirebaseAuth = async () => {
    const customToken = {
      user_type: 'ameiz',
      user_id: userData.user_id,
      professional_id: booking.professional.id,
      admin: false
    }
    console.log('customToken', customToken);
    await getFirebaseToken(customToken, 'traking');
    setIsAuth(true);
  }

  useEffect(() => {
    let onValueChange;
    if (isAuth) {
      setDestinationCoordinates(booking.address.coordinate);
      setCenter(booking.address.coordinate)
      onValueChange = db.ref(`tracking/professional_${booking.professional.id}`)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            setLocation(snapshot.val().location);
          }
        })
    }
    return () => db.ref(`tracking/professional_${booking.professional.id}`).off('value', onValueChange);
  }, [booking, isAuth]);

  return (
    <Box className={classes.root}>
      {!isAuth && <CircularProgress />}
      {isAuth && <><Typography variant="h4" marked="center" align="center" component="h2" className={classes.title} >
        {location ? 'Tu Ameizer está en camino' : 'El Ameizer todavía no ha salido'}
      </Typography>
      <GoogleMap
        id="tracking-map"
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}>
          <RouteMarkers location={location} destinationCoordinates={destinationCoordinates} />
      </GoogleMap></>}
    </Box> 
  )
}

const RouteMarkers = ({location, destinationCoordinates}) => {
  const currentLocation = useMemo(
    () => ({ lat: location?.latitude, lng: location?.longitude }),
    [location]);
  const destinationLocation = useMemo(
    () => ({ lat: destinationCoordinates?.lat, lng: destinationCoordinates?.lng }),
    [destinationCoordinates]);

  return (
    <>
      <OverlayView position={currentLocation} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} >
        <img src={AmeizerIcon} style={{ height: '32px', width: '32px', objectFit: 'scale-down' }} alt="ameizer" />
      </OverlayView>
      {destinationCoordinates && <Marker
        draggable={false}
        position={destinationCoordinates}
      />}
    </>
  )
}

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 8),
    border: '4px solid #28282a',
  },
  title: {
    borderRadius: 0,
    padding: theme.spacing(2, 2),
    fontSize: '1.5rem',
    fontWeight: 'normal'
  },
});

export default withStyles(styles)(ProfessionalTracking);