import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import {
  Container,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ReactWhatsapp from 'react-whatsapp';

import { BookingContext } from 'src/contexts/BookingContext';
import Button from 'src/modules/components/Button';
import Typography from 'src/modules/components/Typography';
import BookingDetails from 'src/modules/components/BookingDetails';
import BookingServices from 'src/services/BookingServices';

const AMZ_PHONE_NUMBER = '56930397449';

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    maxWidth: 680
  },
  button: {
    marginTop: '24px',
    minWidth: 200,
  },
  loading: {
    textAlign: 'center',
    marginTop: '24px'
  },
  whatsappBox: {
    marginTop: theme.spacing(8),
    textAlign: 'center',
    width: '100%'
  }
});

function Checkout({ classes }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { cartId, bookingId } = useParams();
  let { booking, lastBookingId, addBookingComment } = useContext(BookingContext);
  const [comment, setComment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [getToCartLoading, setGetToCartLoading] = useState(false);
  const [bookingData, setBookingData] = useState({});

  useEffect(() => {
    if (booking.bookings) {
      setIsLoading(false);
    }
    // eslint-disable-next-line 
  }, [booking]);

  useEffect(() => {
    if (bookingId) {
      getBookingData();
    }
    // eslint-disable-next-line 
  }, [bookingId]);

  const getBookingData = async () => {
    try {
      const response = await BookingServices.getBooking(bookingId);
      setBookingData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const keepBuying = async (e) => {
    e.preventDefault();
    if (comment) {
      setSaveLoading(true);
      try {
        await addBookingComment(comment, cartId, bookingId);
      } catch (error) {
        console.log(error);
      }
    }
    navigate('/categoria-de-servicio');
  }

  const getToCart = async (e) => {
    e.preventDefault();
    if (comment) {
      setGetToCartLoading(true);
      try {
        await addBookingComment(comment, cartId, bookingId);
      } catch (error) {
        console.log(error);
      }
    }
    navigate('/cart');
  }

  return (
    <section>
      {booking && <Container className={classes.container}>
        {isLoading && <Box className={classes.loading}>
          <CircularProgress color="secondary" />
        </Box>}
        {!isLoading && <>
          <BookingDetails
            booking={bookingId ? bookingData : booking.bookings.find((b) => b.id === (bookingId ? bookingId : lastBookingId))}
            single
            currency={bookingId ? bookingData.currency : booking.bookings.find((b) => b.id === (bookingId ? bookingId : lastBookingId)).currency}
            cartId={cartId}
            setComment={setComment}
            noComment
          />
          <Box className={classes.buttonContainer}>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              onClick={getToCart}
              className={classes.button}
            >
              {getToCartLoading ? <CircularProgress color='inherit' size={20} /> : t('phrases.pay_and_book')}
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              onClick={keepBuying}
              className={classes.button}
            >
              {saveLoading ? <CircularProgress color='inherit' size={20} /> : t('phrases.keep_buying')}
            </Button>
          </Box>
        </>}

        <Box className={classes.whatsappBox}>
          <ReactWhatsapp number={AMZ_PHONE_NUMBER} message="" style={{ backgroundColor: 'transparent', border: 'none' }}>
            <Button
              endIcon={<WhatsAppIcon />}
              variant='outlined'
              size='small'
              style={{ marginTop: '24px' }}>
              {t('text.contact_us')}
            </Button>
          </ReactWhatsapp>
        </Box>

      </Container>}
    </section>
  );
}

Checkout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Checkout);
