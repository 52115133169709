import { useState, useEffect, useContext } from 'react';
import { Container, Box, Avatar, IconButton, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import UserServices from 'src/services/UserServices';
import { AuthContext } from 'src/contexts/AuthContext';
import CardListComponent from 'src/modules/components/CardList';
import Typography from 'src/modules/components/Typography';
import Button from 'src/modules/components/Button';
import PaymentServices from 'src/services/PaymentServices';
import MasterCard from 'src/assets/master-card.png';
import VisaCard from 'src/assets/visa-card.png';
import AmexCard from 'src/assets/amex-card.png';
import { COUNTRIES } from 'src/utils/constants';

const CREDIT_CARDS = {
  visa: VisaCard,
  master: MasterCard,
  amex: AmexCard
}

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  cardBox: {
    borderRadius: '6px',
    backgroundColor: '#fff',
    marginBottom: '36px',
    position: 'relative',
    padding: '0px',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 22px',
    borderBottom: '1px solid #eee',
  },
  newCard: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '16px 22px',
    borderBottom: '1px solid #eee',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
   },
   '&:active': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    boxShadow: '0 1px rgba(0, 0, 0, 0.04)',
    transform: 'translateY(1px)',
  }
  },
  cardThumbnail: {
    flex: '3'
  },
  cardCircle: {
    width: '66px',
    height: '66px',
    padding: '12px',
    borderRadius: '50%',
    border: '1px solid #d9d9d9',
    display: 'flex',
    marginRight: '36px',
  },
  cardImage: {
    width: '100%',
    height: 'auto',
    margin: 'auto',
  },
  cardGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    with: '100%'
  },
  cardData: {
    minWidth: '250px'
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  brand: {
    color: '#999',
    fontSize: '14px'
  },
  deleteButton: {
    fontWeight: 450,
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    padding: 8
  },
  cardAvatar: {
    color: '#999',
    backgroundColor: 'transparent',
    width: '100%',
    height: 'auto',
    margin: 'auto',
  }
});

const CardList = ({ classes }) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  let { userData } = useContext(AuthContext);
  const [cards, setCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    Promise.all([getCards(), getPaymentMethods(), getRedirectUrl()]);
  }, []);

  const getCards = async () => {
    setIsLoading(true);
    let filter = {};
    try {
      const response = await UserServices.getCards(userData.user_id, filter);
      let updatedList = response.data.map((c) => ({ ...c, checked: false }));
      setCards(updatedList);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const getPaymentMethods = async () => {
    const country = COUNTRIES.find((c) => c.lang === i18n.language);
    let filter = {
      coordinate: {
        lat: country.center.lat,
        lng: country.center.lng
      }
    };
    try {
      const response = await PaymentServices.searchPaymentMethods(filter);
      let updatedList = response.data.map((pm) => ({ ...pm, checked: false }));
      setPaymentMethods(updatedList);
    } catch (error) {
      console.log(error);
    }
  }

  const getRedirectUrl = async () => {
    let filter = {
      user_id: userData.user_id,
      channel: 'profile'
    }
    try {
      const resp = await PaymentServices.getOneClickUrl(filter);
      setRedirectUrl(resp.data.inscription_url);
    } catch (e) {
      console.log(e);
    }
  }

  const handleAddNewCard = () => {
    if (redirectUrl) {
      const oneClickEnabled = paymentMethods.find((method) => { return (method.name === 'One Click' && method.app_enabled) });
      const dlocalEnabled = paymentMethods.find((method) => { return (method.name === 'D Local' && method.app_enabled) });
      if (oneClickEnabled) {
        window.location.href = redirectUrl;
      } else if (dlocalEnabled) {
        const token = localStorage.getItem('access_token');
        navigate(`/cart/dlocal-secure-card/${token}?profile=true`);
      }
    } else {
      getRedirectUrl();
    }
  }

  const handleDeleteCard = async (cardId) => {
    let filteredList = cards.filter((c) => c.id !== cardId);
    setCards(filteredList);
    try {
      const response = await UserServices.deleteCard(userData.user_id,cardId);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container className={classes.container} >
      {isLoading && <CircularProgress size={60} />}
      {!isLoading && <Typography variant="h4" marked="center" className={classes.title} component="h2">
          Tarjetas
      </Typography>}
      <Box className={classes.cardBox} boxShadow={2}>
        {!isLoading && cards.map((c) => <Box className={classes.card}>
          <Box className={classes.cardThumbnail}>
            <Box className={classes.cardCircle}>
             { CREDIT_CARDS[c?.brand.toLowerCase()] ? 
              <Avatar 
                  alt={c?.brand}
                  src={CREDIT_CARDS[c?.brand.toLowerCase()]}
                  className={classes.cardAvatar}
                /> : 
                <Avatar className={classes.cardAvatar} alt={c?.brand}>
                  <CreditCardIcon />
                </Avatar>
              }
            </Box>
          </Box>
          <Box className={classes.cardGroup}>
            <Box className={classes.cardData}>
              <Typography>Terminada en {c?.last_four_digit}</Typography>
              <Typography className={classes.brand}>{c?.brand}</Typography>
            </Box>
            <Box className={classes.cardAction}>
              <Button
              size='small'
              onClick={() => handleDeleteCard(c.id)}
              className={classes.deleteButton}
              >
                Eliminar
              </Button>
            </Box>
          </Box>
        </Box>)}

        {!isLoading && <Box className={classes.newCard} onClick={handleAddNewCard}>
          <Box className={classes.cardThumbnail}>
            <Box className={classes.cardCircle}>
              <Avatar className={classes.cardAvatar}>
                <CreditCardIcon />
              </Avatar>
            </Box>
          </Box>
          <Box className={classes.cardGroup}>
            <Box className={classes.cardData}>
              <Typography>
                Agregar nueva tarjeta
              </Typography>
            </Box>
            <Box style={{ width: 80 }}>
            </Box>
          </Box>
        </Box>}
        
      </Box>
    </Container>
  )
}

export default withStyles(styles)(CardList);
