import React, { useState, useEffect, useContext } from 'react';
import { BookingContext } from 'src/contexts/BookingContext';
import { useNavigate } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import is from 'is_js';
import { es } from 'date-fns/locale';
import { DatePickerCalendar } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import ReacTypography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ReactWhatsapp from 'react-whatsapp';

import Button from '../components/Button';
import Typography from '../components/Typography';
import ShoppingCartServices from 'src/services/ShoppingCartServices';
import MoneyDisplay from 'src/modules/components/MoneyDisplay';

const AMZ_PHONE_NUMBER = '56930397449';

const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  timeButton: {
    padding: 10,
    margin: 10,
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  timeTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(5),
  },
  whatsappBox: {
    marginTop: theme.spacing(8),
    textAlign: 'center',
    width: '100%'
  }
});

function DateTimeSelect({ classes }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { cartId, bookingId } = useParams();
  let { booking, addBookingDate } = useContext(BookingContext);
  const [hours, setHours] = useState([]);
  const [date, setDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [buttonAvailable, setButtonAvailable] = useState(true);
  const [time, setTime] = useState();
  const [monthAvailability, setMonthAvailability] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (booking.last_created_booking || bookingId) {
      getCalendarData();
    }
    // eslint-disable-next-line 
  }, [booking, bookingId]);

  const getCalendarData = async () => {
    try {
      await Promise.all([getAvailabilities(new Date()), getMonthAvailability(new Date())]);
    } catch (error) {
      console.log(error);
    }
  }

  const getAvailabilities = async (date) => {
    let filter = {
      booking_id: bookingId ? bookingId : booking.last_created_booking,
      date: moment(date).format(),
      professional_id: new URLSearchParams(window.location.search).get("professionalId") ? Number(new URLSearchParams(window.location.search).get("professionalId")) : null
    }
    try {
      const response = await ShoppingCartServices.getAvailabilityTime(filter.booking_id, filter);
      setHours(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getMonthAvailability = async (month) => {
    const startOfMonth = moment(month).utc().clone().startOf('month').format();
    const endOfMonth = moment(month).utc().clone().endOf('month').format();
    let filter = {
      booking_id: bookingId ? bookingId : booking.last_created_booking,
      from: startOfMonth,
      to: endOfMonth,
      professional_id: new URLSearchParams(window.location.search).get("professionalId") ? Number(new URLSearchParams(window.location.search).get("professionalId")) : null
    }
    try {
      const response = await ShoppingCartServices.getAvailabilityDate(filter.booking_id, filter);
      setMonthAvailability(response.data.dates);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSelectedTime = (hour) => {
    setButtonAvailable(false);
    setTime(hour);
  }

  const handleDateSelect = (date) => {
    setDate(date);
    getAvailabilities(moment(date).format());
  }

  const nextStep = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await addBookingDate(moment(date).format(), time, cartId, bookingId);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    navigate(`/cart`);
  }

  const modifiers = {
    highlight: date => monthAvailability.find((d) => moment(d.date).utc().format('DD-MM-YYYY') === moment(date).utc().format('DD-MM-YYYY'))
  }

  const modifiersClassNames = {
    highlight: '-highlight',
  }

  const handleChangeMonth = (props) => {
    setCurrentMonth(props);
    getMonthAvailability(props);
  }

  return (
    <section>
      <Container className={classes.container}>
        <Typography variant="h4" marked="center" align="center" component="h2">
          {t('phrases.when_do_you_want_your_service?')}
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <DatePickerCalendar
              date={date}
              month={currentMonth}
              onDateChange={handleDateSelect}
              onMonthChange={handleChangeMonth}
              modifiers={modifiers}
              modifiersClassNames={modifiersClassNames}
              locale={es} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" component="h3" className={classes.timeTitle}>
              {date ? 'Horarios disponibles para el ' + format(date, 'dd MMMM yyyy', { locale: es }) : t('phrases.select_a_day')}
            </Typography>
            <Box>
              {is.empty(hours.times) && <Typography>
                {t('phrases.no_times_available')}
              </Typography>}
            </Box>
            <Box>
              {hours.times && hours.times.map((hour, i) => <RenderTime key={"time" + i} price={hour.price_from} hour={hour.time} handleSelectedTime={handleSelectedTime} time={time} classes={classes} i18n={i18n} />)}
            </Box>
            <Box>
              <Button
                color="primary"
                size="large"
                variant="contained"
                className={classes.button}
                onClick={nextStep}
                disabled={buttonAvailable}
              >
                {isLoading ? <CircularProgress color='inherit' /> : t('text.add')}
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box className={classes.whatsappBox}>
          <Typography variant="h5" align="center" component="h2">
            {t('phrases.can_not_find_what_you_are_looking_for')}
          </Typography>
          <ReactWhatsapp number={AMZ_PHONE_NUMBER} message="" style={{ backgroundColor: 'transparent', border: 'none' }}>
            <Button
              endIcon={<WhatsAppIcon />}
              variant='outlined'
              size='small'
              style={{ marginTop: '24px' }}>
              {t('text.contact_us')}
            </Button>
          </ReactWhatsapp>
        </Box>

      </Container>
    </section>
  )
}

export default withStyles(styles)(DateTimeSelect);

const RenderTime = ({ hour, price, handleSelectedTime, time, classes, i18n }) => (
  <Button variant="contained" color={hour === time ? 'primary' : 'default'}
    className={classes.timeButton} onClick={() => handleSelectedTime(hour)}>
    <Box>
      <ReacTypography gutterBottom variant="body1">
        {hour}
      </ReacTypography>
      <ReacTypography variant="body2">
        <MoneyDisplay
          prefix={i18n.language === "es_pe" ? 'S/.' : '$'}
          value={parseInt(price)}
          displayType={'text'} />
      </ReacTypography>
    </Box>
  </Button>
)