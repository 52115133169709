import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';

import VariantDetails from './VariantDetails';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'flexStart',
    justifyContent: 'center',
  },
}));

export default function TransitionsModal({ variant, show, handleCloseModal, professionalId }) {
  const classes = useStyles();

  const handleClose = () => {
    handleCloseModal()
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      className={classes.modal} 
      onClose={handleClose} 
      aria-labelledby="simple-dialog-title" 
      open={show}
    >
      <Fade in={show}>
        <div className={classes.paper}>
          <VariantDetails variant={variant} professionalId={professionalId} />
        </div>
      </Fade>
    </Dialog>
  );
}
