import React, { useState, useContext, useEffect } from 'react';
import { CircularProgress, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Marker, GoogleMap, Autocomplete } from '@react-google-maps/api';
import is from 'is_js';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { AuthContext } from 'src/contexts/AuthContext';
import Button from 'src/modules/components/Button';
import Typography from 'src/modules/components/Typography';
import TextField from 'src/modules/components/TextField';
import UserServices from 'src/services/UserServices';
import { COUNTRIES } from 'src/utils/constants';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(2, 8),
    border: '4px solid #f9464695',
  },
  title: {
    borderRadius: 0,
    padding: theme.spacing(2, 2),
    fontSize: '1.6rem',
    fontWeight: 'normal'
  },
  mobileTitle: {
    borderRadius: 0,
    padding: theme.spacing(2, 2),
    fontSize: '1rem',
    fontWeight: 'normal'
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  address: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  buoy: {
    width: 60,
  },
  button: {
    marginTop: '24px',
    minWidth: 200,
  },
  autocomplete: {
    width: '100%'
  },
  googleMessage: {
    fontWeight: 100,
    fontSize: '0.8rem',
    marginBottom: '24px',
    marginTop: '4px',
  },
  pinMessage: {
    marginTop: '12px',
    fontWeight: 200,
    fontSize: '0.9rem',
  },
});

const containerStyle = {
  width: '100%',
  height: '400px'
};

function CreateAddressForm({ classes }) {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const [addressSelected, setAddressSelected] = useState(null);
  const [confirmedCoordinates, setConfirmedCoordinates] = useState(null);
  const [center, setCenter] = useState({ lat: -33.43774451396826, lng: -70.65101623535156 });
  const [autocomplete, setAutocomplete] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [hasParking, setHasParking] = useState(false);
  const [floor, setFloor] = useState('');

  useEffect(() => {
    let country = COUNTRIES.find((c) => c.lang === i18n.language);
    setCenter({ lat: country.center.lat, lng: country.center.lng });
  }, [i18n]);

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete)
  }

  const onPlaceChanged = () => {
    setErrors({});
    if (autocomplete !== null) {
      let newAddress = autocomplete.getPlace();
      if (newAddress.address_components) {
        if (newAddress.address_components.find((a) => a.types.find((t) => t === "street_number"))) {
          setCenter(newAddress?.geometry.location);
          setConfirmedCoordinates(null);
          setAddressSelected(newAddress);
        } else {
          setErrors({
            ...errors,
            address: 'Ingresa una numeración.'
          })
        }
      }
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  const onLoadMarker = marker => {
    console.log('marker: ', marker)
  }

  const handlePointMove = (e) => {
    setConfirmedCoordinates(e.latLng);
  }

  const handleSaveNewAddress = async () => {
    if (addressSelected) {
      setIsLoading(true);
      let coordinates;
      if (confirmedCoordinates) {
        coordinates = confirmedCoordinates;
      } else {
        coordinates = addressSelected?.geometry.location;
      }

      const { address_components, formatted_address, geometry, place_id, plus_code, types } = addressSelected;

      let googleData = {
        results: [
          {
            address_components,
            formatted_address,
            geometry,
            place_id,
            plus_code,
            types
          }
        ],
        status: "OK"
      }

      let body = {
        formatted_address: addressSelected.formatted_address,
        address: addressSelected.formatted_address,
        extra_info: floor,
        has_parking: hasParking,
        user_id: userData.user_id,
        default: true,
        google_data: googleData,
        coordinate: {
          lat: coordinates.lat(),
          lng: coordinates.lng()
        }
      }

      try {
        const response = await UserServices.createUserAddress(userData.user_id, body);
        localStorage.setItem('default_address', JSON.stringify(response.data));

        if (new URLSearchParams(window.location.search).get("init")) {
          navigate('/');
        } else {
          navigate('/perfil');
        }

      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }

    }
  }

  const handleChangeCheckbox = () => {
    setHasParking((prev) => !prev);
  }

  return (
    <Container className={classes.root} component="section" maxWidth="md">
      <Typography variant="h4" marked="center" align="center" component="h2" className={isMobile ? classes.mobileTitle : classes.title}>
        {t('phrases.adding_a_new_address')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Autocomplete
            onLoad={onLoad}
            restrictions={{ country: i18n.language.slice(3) }}
            onPlaceChanged={onPlaceChanged}
            className={classes.autocomplete}
          >
            <TextField
              id="outlined-basic"
              placeholder="Ingresa una dirección"
              error={!!errors.address}
              helperText={errors.address}
              style={{ width: '100%', marginBottom: isMobile ? '12px' : '0px' }}
              autoComplete="off"
            />
          </Autocomplete>
          <Typography className={classes.googleMessage} align="rigth">
            Selecciona una dirección sugerida por Google para poder guardar.
          </Typography>
          <Grid container item md={12} spacing={!isMobile && 2} style={{ width: '100%', marginBottom: isMobile ? '12px' : '24px' }}>
            <Grid item xs={12} md={8} style={{ width: '100%' }} >
              <TextField
                fullWidth
                require
                style={{ width: isMobile && '100%', marginBottom: isMobile && '12px' }}
                placeholder="Departamento, casa o información extra"
                value={floor}
                onChange={(e) => setFloor(e.target.value)}
              />
            </Grid>
            <Grid item md={4}>
              <FormControlLabel
                control={<Checkbox checked={hasParking} onChange={handleChangeCheckbox} name="hasParking" />}
                label="Tiene estacionamiento"
              />
            </Grid>
          </Grid>
          <GoogleMap
            id="marker-example"
            mapContainerStyle={containerStyle}
            center={center}
            zoom={addressSelected ? 16 : 12} >

            {addressSelected && <Marker
              onLoad={onLoadMarker}
              draggable={true}
              onDragEnd={(e) => { handlePointMove(e) }}
              position={center}
            />}
          </GoogleMap>
          <Typography className={classes.pinMessage} align="center">
            {t('phrases.move_the_pin')}
          </Typography>
        </Grid>
      </Grid>

      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={handleSaveNewAddress}
        disabled={is.not.empty(errors) || !addressSelected}
        className={classes.button}
      >
        {isLoading ? <CircularProgress color='inherit' /> : "Guardar"}
      </Button>
    </Container>
  );
}


export default withStyles(styles)(CreateAddressForm);