import React, { useContext, useState } from 'react';
import { AuthContext } from 'src/contexts/AuthContext';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Link, IconButton, Avatar, MenuItem, Menu, ListItemText, ListItemIcon, Box } from '@material-ui/core';
import Toolbar, { styles as toolbarStyles } from '../components/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useTranslation } from "react-i18next";
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import EventIcon from '@material-ui/icons/Event';
import { useMediaQuery } from "react-responsive";
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';

import AppBar from '../components/AppBar';
import AddressSelected from 'src/modules/components/AddressSelected';
import DefaultAddressModal from 'src/modules/components/DefaultAddressModal';
import { UserContext } from 'src/contexts/UserContext';
import LangSelector from 'src/modules/components/LangSelector';
import AppBarLogo from 'src/assets/ameiz-navbar-logo.png';
import AppBarMobile from 'src/modules/views/AppBarMobile';
import MessageNotification from 'src/modules/components/MessageNotification';

const styles = (theme) => ({
  title: {

  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'space-between',
    display: 'flex'
  },
  left: {
    flex: 1,
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
  sectionDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    width: '250px',
    textAlign: 'center',
    padding: '12px',
    marginTop: '4px'
  },
  accountIcons: {
    display: 'flex',
    alignItems: 'center',
    width: '250px',
    justifyContent: 'flex-end'
  },
  logButtons: {
    display: 'flex',
    alignItems: 'center',
    width: '250px',
    justifyContent: 'flex-end'
  }
});

function AppAppBar({ classes }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  let { logged, userData, handleLogout } = useContext(AuthContext);
  let { getSavedAddress } = useContext(UserContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const [openAddressModal, setOpenAddressModal] = useState(false);

  const handleClose = () => {
    setOpenAddressModal(false);
  };

  const handleClickOpen = () => {
    setOpenAddressModal(true);
  };

  const getToCart = (e) => {
    e.preventDefault();
    navigate('/cart');
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getToProfile = () => {
    handleMenuClose();
    navigate('/perfil');
  }

  const handleLocalLogout = () => {
    handleLogout();
    handleMenuClose();
    getSavedAddress();
    navigate('/');
  }

  const getToBookings = () => {
    handleMenuClose();
    navigate('/reservas');
  }

  const getToMessages = () => {
    navigate('/mensajes');
  }

  const menuId = 'primary-search-account-menu';
  const RenderMenu = () => (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={getToProfile}>
        <ListItemIcon>
          <AccountCircleRoundedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('navbar.my_profile')} />
      </MenuItem>
      <MenuItem onClick={() => {navigate('/medios-de-pago'); handleMenuClose();}}>
        <ListItemIcon>
          <CreditCardRoundedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={'Medios de pago'} />
      </MenuItem>
      <MenuItem onClick={getToBookings}>
        <ListItemIcon>
          <EventIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('navbar.my_bookings')} />
      </MenuItem>
      {/* <MenuItem onClick={() => {navigate('/mis-packs'); handleMenuClose();}}>
        <ListItemIcon>
          <DateRangeIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('navbar.my_packs')} />
      </MenuItem> */}
      <MenuItem onClick={() => {navigate('/mis-creditos'); handleMenuClose();}}>
        <ListItemIcon>
          <AccountBalanceWalletOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('navbar.my_credits')} />
      </MenuItem>
      <MenuItem onClick={getToMessages}>
        <ListItemIcon>
          <MessageOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('navbar.my_messages')} />
      </MenuItem>
      <MenuItem onClick={() => {navigate('/recomienda-un-amigo'); handleMenuClose();}}>
        <ListItemIcon>
          <ShareOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('navbar.refer_friend')} />
      </MenuItem>
      <MenuItem onClick={handleLocalLogout}>
        <ListItemIcon>
          <ExitToAppRoundedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('navbar.logout')} />
      </MenuItem>
    </Menu>
  );

  if (isMobile) {
    return <AppBarMobile />
  } else {
    return (
      <div>
        <AppBar position="fixed">
          <Toolbar className={classes.toolbar}>
            {logged && <AddressSelected onClick={handleClickOpen} />}
            {!localStorage.getItem('default_address') && <div style={{ width: '250px' }} />}

            <Box className={classes.titleContainer}>
              <Link
                variant="h6"
                underline="none"
                color="inherit"
                className={classes.title}
                href="/"
              >
                <img src={AppBarLogo} height='auto' width='120px' alt="Ameiz" />
              </Link>
            </Box>

            {!logged && <Box className={classes.logButtons}>
              <LangSelector />
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                className={classes.rightLink}
                href="/ingreso"
              >
                {'Ingresar'}
              </Link>
              <Link
                variant="h6"
                underline="none"
                className={clsx(classes.rightLink, classes.linkSecondary)}
                href="/registro"
              >
                {'Registro'}
              </Link>
            </Box>}

            {logged && <Box className={classes.accountIcons} >
              <LangSelector />
              <MessageNotification />
              <IconButton color="inherit" onClick={getToCart}>
                <ShoppingCartIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                aria-controls={menuId}
                onClick={handleProfileMenuOpen}
              >
                {userData?.avatar?.url ? <Avatar alt="avatar" src={userData?.avatar?.url} /> : <AccountCircle />}
              </IconButton>
            </Box>}

          </Toolbar>
        </AppBar>
        <RenderMenu />
        <div className={classes.placeholder} />
        <DefaultAddressModal onClose={handleClose} open={openAddressModal} />
      </div>
    );
  }


}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
