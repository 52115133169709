import React from 'react';
import {
  Dialog,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';

import Rating from 'src/modules/components/Rating';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    width: 345,
    padding: '24px',
    margin: '24px 24px'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    // backgroundColor: ,
  },
}));

const ReviewModal = ({ onClose, reviewSelected }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="review-dialog-title" open={reviewSelected} style={{ padding: '24px' }}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}/>
        <DialogContent >
      {reviewSelected.reviews != null && reviewSelected.reviews.map((r, i) => {
        if (r.reviewer_type === 'user') {
          return (
          <Card key={'review' + i} className={classes.cardRoot} variant="outlined">
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              className={classes.avatar}
              src={r.reviewer_type === 'professional' ? `${reviewSelected?.professional?.user?.avatar?.full_url}` : `${reviewSelected?.shoppingCart?.user?.avatar?.full_url}`} />
          }
          title={r.reviewer_type === 'professional' ?
            `${reviewSelected?.professional?.user?.name} ${reviewSelected?.professional?.user?.lastname}` :
            `${reviewSelected?.shoppingCart?.user?.name} ${reviewSelected?.shoppingCart?.user?.lastname}`}
        />
        <CardContent>
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Rating
              name="hover-feedback"
              value={r.rating}
              precision={0.5}
              readOnly
              size="large"
            />
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: '12px' }}>
              {r.comment}
            </Typography>
          </Box>
        </CardContent>
      </Card>)}})}
      </DialogContent>

    </Dialog>
  )
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(({ children, classes, onClose, ...other }) => {
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default ReviewModal;
