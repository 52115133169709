import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import is from 'is_js';
import {
	Box,
	Card,
	CardContent,
	CardMedia,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper
} from '@material-ui/core';

import Button from 'src/modules/components/Button';
import TextField from 'src/modules/components/TextField';
import MoneyDisplay from 'src/modules/components/MoneyDisplay';

const useStyles = makeStyles({
	root: {
		maxWidth: 800
	},
	multipleRoot: {
		maxWidth: 350
	},
	media: {
		height: 140,
	},
	quantityText: {
		marginRight: 10,
	},
	details: {
		width: '100%'
	},
	table: {
		minWidth: 650,
	},
	multipleTable: {
		minWidth: 250,
	},
	totalsTable: {
		minWidth: 650,
		marginTop: 24
	},
	commentTitle: {
		marginTop: 24
	},
	paymentMethodsAccordion: {
		marginTop: 24
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		textAlign: 'center'
	},
	button: {
		marginTop: '24px',
		width: '100%'
	},
	heading: {
		paddingLeft: '16px'
	}
});

export default function FinalCount({
	cart,
	cardSelected,
	paymentMethods,
	setPromocode,
	promocode,
	handleAddPromocode,
	promocodeLoading,
	promocodeError }) {
	const classes = useStyles();

	const handleChangePromocode = (value) => {
		setPromocode(value);
	}

	return (
		<Card className={classes.root}>
			<CardMedia
				className={classes.media}
				image={'https://s3.amazonaws.com/ameizproduction/site/client-form-bg.jpg'}
			// title={localBookingData?.serviceVariantArea?.name}
			/>
			<CardContent>
				<TableContainer component={Paper} className={classes.totalsTable}>
					<Table className={classes.table} aria-label="simple table">
						<TableBody>
							<TableRow >
								<TableCell component="th" scope="row">
									Medio de pago:
								</TableCell>
								<TableCell align="right">
									{cardSelected === "MercadoPago" ? "Mercado Pago" : `${cardSelected.brand} **** **** **** ${cardSelected.last_four_digit}`}
								</TableCell>
							</TableRow>
							<TableRow >
								<TableCell component="th" scope="row">
									Total servicios-.
								</TableCell>
								<TableCell align="right">
									<MoneyDisplay
										prefix={cart?.currency?.name === 'PEN' ? 'S/.' : '$'}
										value={cart?.total_service_variants_price}
										displayType={'text'} />
								</TableCell>
							</TableRow>
							<TableRow >
								<TableCell component="th" scope="row">
									Total adicionales:
								</TableCell>
								<TableCell align="right">
									<MoneyDisplay
										prefix={cart?.currency?.name === 'PEN' ? 'S/.' : '$'}
										value={cart?.total_addons_price}
										displayType={'text'} />
								</TableCell>
							</TableRow>
							<TableRow >
								<TableCell component="th" scope="row">
									Total transporte
								</TableCell>
								<TableCell align="right">
									<MoneyDisplay
										prefix={cart?.currency?.name === 'PEN' ? 'S/.' : '$'}
										value={cart?.total_transport_price}
										displayType={'text'} />
								</TableCell>
							</TableRow>
							{<TableRow>
								<TableCell component="th" scope="row">
									¿Tienes un código?
								</TableCell>
								<TableCell align="right">
									<Box>
										<TextField
											size={'small'}
											value={promocode}
											onChange={(e) => handleChangePromocode(e.target.value)}
											error={!!promocodeError}
											helperText={promocodeError} />
										<Button onClick={handleAddPromocode} size="small" style={{ paddingTop: '6px', paddingBottom: '5px' }} color='secondary' variant='outlined'>
											{promocodeLoading ? <CircularProgress /> : "Calcular"}
										</Button>
									</Box>
								</TableCell>
							</TableRow>}
							{is.not.empty(cart.promocode) && cart?.promocode?.code && <TableRow>
								<TableCell component="th" scope="row">
									Código de descuento
								</TableCell>
								<TableCell align="right">
									{cart?.promocode?.code ? cart?.promocode?.code : ''}
								</TableCell>
							</TableRow>}
							<TableRow >
								<TableCell component="th" scope="row">
									Descuento
								</TableCell>
								<TableCell align="right">
									<MoneyDisplay
										prefix={cart?.currency?.name === 'PEN' ? 'S/.' : '$'}
										value={cart?.discount_amount}
										displayType={'text'} />
								</TableCell>
							</TableRow>
							<TableRow style={{ backgroundColor: 'black' }}>
								<TableCell component="th" scope="row" style={{ color: '#fff' }}>
									Total:
								</TableCell>
								<TableCell style={{ color: '#fff' }} align="right">
									<MoneyDisplay
										prefix={cart?.currency?.name === 'PEN' ? 'S/.' : '$'}
										value={cart?.final_price}
										displayType={'text'} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">
									Créditos a utilizar:
								</TableCell>
								<TableCell align="right">
									<MoneyDisplay
										prefix={cart?.currency?.name === 'PEN' ? 'S/.' : '$'}
										value={cart?.wallet_paid_amount}
										displayType={'text'} />
								</TableCell>
							</TableRow>
							<TableRow style={{ backgroundColor: 'black' }}>
								<TableCell component="th" scope="row" style={{ color: '#fff' }}>
									Total por pagar:
								</TableCell>
								<TableCell style={{ color: '#fff' }} align="right">
									<MoneyDisplay
										prefix={cart?.currency?.name === 'PEN' ? 'S/.' : '$'}
										value={cart?.payment_method_paid_amount}
										displayType={'text'} />
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
		</Card>
	);
}
