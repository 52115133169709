import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token');

  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, function (err) {
  return Promise.reject(err);
});

const BookingServices = {
  getBooking: async (bookingId) => {
    console.log(`BookingServices.getBooking(${bookingId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/booking/${bookingId}`;
    return axios.get(url);
  },
  postReview: async (bookingId,review) => {
    console.log(`BookingServices.postReview(${bookingId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/booking/${bookingId}/review`;
    return axios.post(url,review);
  },
};

export default BookingServices;