import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import is from 'is_js';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { AuthContext } from 'src/contexts/AuthContext';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
  Box,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  IconButton,
  Button,
} from '@material-ui/core';
import { useMediaQuery } from "react-responsive";
import InsertCommentOutlinedIcon from '@material-ui/icons/InsertCommentOutlined';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

import Typography from 'src/modules/components/Typography';
import BookingDetails from 'src/modules/components/BookingDetails';
import UserServices from 'src/services/UserServices';
import ReviewModal from '../components/ReviewModal';
import ShoppingCartServices from 'src/services/ShoppingCartServices';
import { BookingContext } from 'src/contexts/BookingContext';
import ProfessionalTracking from 'src/modules/components/ProfessionalTracking';

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  tableContainer: {
    border: '1px solid #ff3366',
    borderRadius: '12px',
  },
  table: {
  },
  loading: {
    textAlign: 'center',
    marginTop: '24px'
  },
  emptyMessageContainer: {
    marginTop: theme.spacing(8),
  },
  tableHead: {
    backgroundColor: '#28282a',
  },
  tableTitle: {
    color: '#fff'
  },
  tableRow: {
    cursor: 'pointer'
  }
});

function BookingList({ classes }) {
  let { userData } = useContext(AuthContext);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [bookings, setBookings] = useState([]);
  const [bookingSelected, setBookingSelected] = useState(null);
  const [reviewSelected, setReviewSelected] = useState(null)
	const [repeatLoading, setRepeatLoading] = useState(null);
  const { setBooking, setLastBookingId } = useContext(BookingContext);
  const [trackingSelected, setTrackingSelected] = useState(null);

  useEffect(() => {
    if (userData) {
      getBookingList();
    }
    // eslint-disable-next-line
  }, [userData]);

  const getBookingList = async () => {
    let filter = {
      user_id: userData.user_id,
      status: [
        'searching',
        'accepted',
        'done',
        'ameizer_paid',
        'refunded',
        'cancelled_by_user',
        'cancelled',
        'on_my_way',
        'arrived'
      ],
    }
    try {
      const response = await UserServices.bookingSearch(userData.user_id, filter);
      setBookings(response.data.filter((b) => b.date));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleOpenService = (bookingId) => {
    setBookingSelected(bookingId);
  }

  const handleCloseModal = () => {
    setBookingSelected(null);
  }

  const handleReview = (e, bookingId) => {
    e.stopPropagation();
    navigate(`/review/${bookingId}`);
  }

  const handleOpenReviewModal = (e, bookingId) => {
    e.stopPropagation();
    setReviewSelected(bookings.find((b) => b.id === bookingId));
  }

  const handleCloseReviewModal = () => {
    setReviewSelected(null);
  }

	const handleRepeatBooking = async (e, booking) => {
    e.stopPropagation();
		setRepeatLoading(booking.id);
		let newBooking = {
			address_id: booking.address.id,
			service_variant_area_id: booking.serviceVariantArea.id,
			quantity: booking.quantity,
			ameizers_quantity: 1,
			channel: 'web'
		}
		try {
			const response = await ShoppingCartServices.createBooking(newBooking);
			setBooking(response.data);
      setLastBookingId(response.data.last_created_booking);
			navigate('/fecha');
		} catch (error) {
			console.log(error);
		} finally {
			setRepeatLoading(null);
		}
	}

  if (isMobile) {
    return (
      <section>
        <Container className={classes.container}>
          <Typography className={classes.title} variant="h4" marked="center" align="center" component="h2">
            {t('navbar.my_bookings')}
          </Typography>

          {isLoading && <Box className={classes.loading}>
            <CircularProgress color="secondary" />
          </Box>}

          {is.empty(bookings) && !isLoading && <Box className={classes.emptyMessageContainer}>
            <Typography
              variant="h6"
              align="center"
              component="h2">
              {t('phrases.no_services_scheduled')}
            </Typography>
          </Box>}

          {is.not.empty(bookings) && <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell align="center" className={classes.tableTitle}>Servicios</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookings.map((row) => (
                  <TableRow key={row.id} hover className={classes.tableRow} onClick={() => handleOpenService(row.id)}>
                    <TableCell component="th" scope="row">
                      <Box style={{ display: 'flex', flexDirection: 'column' }}>

                        <Typography
                          variant="h6"
                          align="center"
                          marked="center"
                          style={{ marginBottom: '8px' }}
                          component="h2">
                          {row.serviceVariantArea.name}
                        </Typography>

                        <Typography
                          variant="subtitle3"
                          align="center"
                          component="span">
                          {moment(row.date).utc().format('DD/MM/YYYY HH:mm')}
                        </Typography>

                        <Typography
                          variant="subtitle3"
                          align="center"
                          component="span">
                          AMZ{row.shoppingCart.id}
                        </Typography>

                        <Typography
                          variant="subtitle3"
                          align="center"
                          component="span">
                          {t(`booking_status.${BOOKING_STATUS[row.status]}`)}
                        </Typography>

                        <Typography
                          variant="subtitle3"
                          align="center"
                          component="span">
                          {row.address?.address}
                        </Typography>

                        <Typography
                          variant="subtitle3"
                          align="center"
                          component="span">
                          {row?.professional?.user?.name ?
                          <>
                            {`${row?.professional?.user?.name} ${row?.professional?.user?.lastname}` }
                            <IconButton
                              disabled={!row?.professional?.id}
                              onClick={(e) => { e.stopPropagation(); navigate(`/mensajes?ameizerId=${row?.professional?.id}`); }}
                            >
                              <InsertCommentOutlinedIcon />
                            </IconButton>
                          </>
                          : 'Optimizado por Ameiz'}
                        </Typography>

                        {row.status === 'done' && !(row.reviews.find((r) => r.reviewer_type === 'user')) && <Button
                          variant='contained'
                          color='secondary'
                          size="small"
                          onClick={(e) => handleReview(e, row.id)}
                          style={{ marginTop: '8px' }}
                        >
                          {t('text.rate')}
                        </Button>}

                        {row.reviews.find((r) => r.reviewer_type === 'user') && <Button
                          variant='contained'
                          color='secondary'
                          size="small"
                          style={{ marginTop: '8px' }}
                          onClick={(e) => handleOpenReviewModal(e, row.id)}
                        >
                          {t('text.rating')}
                        </Button>}

                        <Button
                          onClick={(e) => handleRepeatBooking(e, row)}
                          style={{ fontSize: '0.6rem', minWidth: '130px', padding: '12px 0px' }}
                          variant="contained" 
                          color="primary" 
                        >
                          {repeatLoading === row.id ? <CircularProgress color='inherit' size={20} /> : 'Repetir reserva'}
                        </Button>

                      </Box>

                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}

          <BookingModal open={bookingSelected} handleClose={handleCloseModal} bookings={bookings} bookingSelected={bookingSelected} BookingList />
          {reviewSelected && <ReviewModal reviewSelected={reviewSelected} onClose={handleCloseReviewModal} />}
        </Container>
      </section>
    );
  } else {
    return (
      <section>
        <Container className={classes.container}>
          <Typography className={classes.title} variant="h4" marked="center" align="center" component="h2">
            {t('navbar.my_bookings')}
          </Typography>

          {isLoading && <Box className={classes.loading}>
            <CircularProgress color="secondary" />
          </Box>}

          {is.empty(bookings) && !isLoading && <Box className={classes.emptyMessageContainer}>
            <Typography
              variant="h6"
              align="center"
              component="h2">
              {t('phrases.no_services_scheduled')}
            </Typography>
          </Box>}

          {is.not.empty(bookings) && <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell align="center" className={classes.tableTitle}>Servicio</TableCell>
                  <TableCell align="right" className={classes.tableTitle}>Fecha</TableCell>
                  <TableCell align="right" className={classes.tableTitle}>Código reserva</TableCell>
                  <TableCell align="right" className={classes.tableTitle}>Estado</TableCell>
                  <TableCell align="right" className={classes.tableTitle}>Dirección</TableCell>
                  <TableCell align="right" className={classes.tableTitle}>Ameizer</TableCell>
                  <TableCell align="center" className={classes.tableTitle}>Contacto</TableCell>
                  <TableCell align="center" className={classes.tableTitle}>Seguimiento</TableCell>
                  <TableCell align="right" className={classes.tableTitle}>Calificación</TableCell>
                  <TableCell align="right" className={classes.tableTitle}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookings.map((row) => (
                  <TableRow key={row.id} hover className={classes.tableRow} onClick={() => handleOpenService(row.id)}>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="subtitle1"
                        align="center"
                        component="h2">
                        {row.serviceVariantArea.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">{moment(row.date).utc().format('DD/MM/YYYY HH:mm')}</TableCell>
                    <TableCell align="right">AMZ{row.shoppingCart.id}</TableCell>
                    <TableCell align="right">{t(`booking_status.${BOOKING_STATUS[row.status]}`)}</TableCell>
                    <TableCell align="right">{row.address?.address}</TableCell>
                    <TableCell align="right">{row?.professional?.user?.name ? `${row?.professional?.user?.name} ${row?.professional?.user?.lastname}` : 'Optimizado por Ameiz'}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        disabled={!row?.professional?.id}
                        onClick={(e) => { e.stopPropagation(); navigate(`/mensajes?ameizerId=${row?.professional?.id}`); }}
                      >
                        <InsertCommentOutlinedIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        disabled={!(row.status === 'on_my_way' )}
                        onClick={(e) => { e.stopPropagation(); setTrackingSelected(row); }}
                      >
                        <DirectionsRunIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      {row.status === 'done' && !(row.reviews.find((r) => r.reviewer_type === 'user')) && <Button
                        variant='contained'
                        color='secondary'
                        size="small"
                        onClick={(e) => handleReview(e, row.id)}
                      >
                        {t('text.rate')}
                      </Button>}
                      {row.reviews.find((r) => r.reviewer_type === 'user') && <Button
                        variant='contained'
                        color='secondary'
                        size="small"
                        onClick={(e) => handleOpenReviewModal(e, row.id)}
                      >
                        {t('text.rating')}
                      </Button>}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={(e) => handleRepeatBooking(e, row)}
                        style={{ fontSize: '0.6rem', minWidth: '130px', padding: '12px 0px' }}
                        variant="contained" 
                        color="primary" 
                      >
                        {repeatLoading === row.id ? <CircularProgress color='inherit' size={20} /> : 'Repetir reserva'}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}

          <BookingModal open={bookingSelected} handleClose={handleCloseModal} bookings={bookings} bookingSelected={bookingSelected} BookingList/>
          {reviewSelected && <ReviewModal reviewSelected={reviewSelected} onClose={handleCloseReviewModal} />}
          {trackingSelected && <TrackingModal handleClose={() => setTrackingSelected(null)} open={trackingSelected} />}
        </Container>
      </section>
    );
  }
}

const BookingModal = ({ handleClose, open, bookings, bookingSelected, BookingList }) => {
  return (
    <Dialog scroll='body' onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <BookingDetails booking={bookings.find((b) => b.id === bookingSelected)} profile multiple BookingList />
    </Dialog>
  );
}

const TrackingModal = ({ handleClose, open }) => {
  return (
    <Dialog onClose={handleClose} aria-labelledby="professional-tracking-dialog" open={open}>
      <ProfessionalTracking booking={open} />
    </Dialog>
  );
}

const BOOKING_STATUS = {
  'created': 'created',
  'searching': 'searching',
  'accepted': 'accepted',
  'done': 'done',
  'cancelled': 'cancelled',
  'ameizer_paid': 'done',
  'refunded': 'refunded',
  'cancelled_by_user': 'cancelled',
  'on_my_way': 'on_my_way',
  'arrived': 'arrived'
}

export default withStyles(styles)(BookingList);
