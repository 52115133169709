import axios from 'axios';

const ReviewServices = {
  getBookingInfo: (bookingId) => {
    console.log(`ReviewServices.getBookingInfo(${bookingId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/review/${bookingId}`;
    return axios.get(url);
  },
  createReview: (bookingId, review) => {
    console.log(`ReviewServices.createReview(${bookingId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/review/${bookingId}/review`;
    return axios.post(url, review);
  },
};

export default ReviewServices;
