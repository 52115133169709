import React, { useEffect, useMemo, useState } from "react";
import Glide, { mount } from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";
import { Box, Container, Typography, Link, IconButton, useMediaQuery } from "@material-ui/core";
import BannersServices from "src/services/BannersServices";
import { withStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { COUNTRIES } from "src/utils/constants";
import { useTranslation } from "react-i18next";
import AppBarLogo from 'src/assets/ameiz-logo.png';

const styles = (theme) => ({
  bannerText: {
    color: theme.palette.common.white,
  },
  bannerButtonText: {
    color: theme.palette.common.white,
    border: '3px #fff solid',
    padding: '5px 20px',
    display: 'inline-block'
  },
  backgroundCard: {
    height: '280px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  backgroundCardText: {
    backgroundImage: `url(${AppBarLogo})`,
    width: '100%',
    height: '100%',
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
  },
});

function AddressMapSlider(props) {
  const { classes } = props;
  const { i18n } = useTranslation();
  const [banners, setBanners] = useState([])
  const mainGlide = useMemo(() => new Glide(".main__glide", {
    type: 'carousel',
    perView: 3,
    focusAt: 'center',
    autoplay: 3000,
    breakpoints: {
      600: {
        perView: 1,
      },
      800: {
        perView: 2,
      },
      1200: {
        perView: 3
      }
    }
  }), []);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const glideContainerStyle = {
    padding: isSmallScreen ? "0px" : "0px 16px",
  }

  useEffect(() => {
    if (banners.length === 0) {
      return;
    }
    mainGlide.mount()
    // eslint-disable-next-line 
  }, [banners]);

  useEffect(() => {
    getBanners();
    // eslint-disable-next-line 
  }, [])

  function getBanners() {
    const country = COUNTRIES.find((c) => c.lang === i18n.language);
    return BannersServices.getBanners({
      status: ['active'],
      page_size: 10,
      coordinate: {
        lat: country.center.lat,
        lng: country.center.lng
      }
    })
      .then(response => setBanners(response.data))
      .catch(error => console.log(error));
  }



  return (
    <Container className="main__glide" style={{ width: '100%', ...glideContainerStyle }}>
      <Box className="glide__track" data-glide-el="track" marginTop={3} marginBottom={3}>
        <Box className="glide__slides" >
          {banners.sort((a, b) => a.order - b.order).map((banner) => {
            return (<Box className="glide__slide" key={banner.id} style={{ margin: 0 }}>
              {banner.url &&
                <Link
                  color="inherit"
                  underline="none"
                  rel="noopener"
                  href={banner.url}
                  target="_blank"
                >
                  {banner.section === 'home_images' && <ImageBanner banner={banner} classes={classes} />}
                  {banner.section === "home_text" && <TextBanner banner={banner} classes={classes} />}
                </Link>}
              {!banner.url && banner.section === 'home_images' && <ImageBanner banner={banner} classes={classes} />}
              {!banner.url && banner.section === "home_text" && <TextBanner banner={banner} classes={classes} />}
            </Box>);
          })}
        </Box>
      </Box>
      {banners.length > 0 &&
        <Box className="glide__arrows" data-glide-el="controls" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconButton className="glide__arrow glide__arrow--left" data-glide-dir="<">
            <ChevronLeft />
          </IconButton>
          <Box className="glide__bullets" data-glide-el="controls[nav]" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {banners.map((_, index) => (
              <FiberManualRecordIcon key={index} className="glide__bullet" data-glide-dir={`=${index}`} />
            ))}
          </Box>
          <IconButton className="glide__arrow glide__arrow--right" data-glide-dir=">">
            <ChevronRight />
          </IconButton>
        </Box>}
    </Container>
  );
}

function ImageBanner({ banner, classes }) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const cardWidth = {
    width: isSmallScreen ? "100%" : "385px",
  }
  return (<Box className={classes.backgroundCard} style={{...cardWidth, backgroundImage: `url(${banner.image.url})` }}>
    <Box padding={3} style={{ backgroundColor: "rgba(0,0,0,0.3)", width: '100%', height: '100%' }}>
      <Typography className={classes.bannerText} variant="button">{banner.description}</Typography>
      <Typography className={classes.bannerText} variant="h4" style={{ marginBottom: '10px', fontSize: '24px' }}>{banner.title}</Typography>
    </Box>
  </Box>);
}

function TextBanner({ banner, classes }) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const cardWidth = {
    width: isSmallScreen ? "100%" : "385px",
  }
  return (<Box className={classes.backgroundCard} style={{...cardWidth, backgroundColor: `#28282a` }}>
    <Box padding={3} className={classes.backgroundCardText}>
      <Typography className={classes.bannerText} variant="button">{banner.description}</Typography>
      <Typography className={classes.bannerText} variant="h4" style={{ marginBottom: '10px', fontSize: '24px' }}>{banner.title}</Typography>
      {banner.url &&
        <Typography className={classes.bannerButtonText} component="h3" variant="h6">{'Ver'}</Typography>}
    </Box>
  </Box>);
}

export default withStyles(styles)(AddressMapSlider);
