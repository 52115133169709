import React, { useState, useRef, useContext, useEffect } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import is from 'is_js';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Container,
  CircularProgress,
  Grid,
  MenuItem,
  IconButton
} from '@material-ui/core';
import { Form, FormSpy } from 'react-final-form';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import { useTranslation } from "react-i18next";

import Typography from 'src/modules/components/Typography';
import AppForm from 'src/modules/views/AppForm';
// eslint-disable-next-line
import { email, required } from 'src/modules/form/validation';
import FormButton from 'src/modules/form/FormButton';
import FormFeedback from 'src/modules/form/FormFeedback';
import blankProfile from 'src/assets/blank-profile.png';
import TextField from 'src/modules/components/TextField';
import UserServices from 'src/services/UserServices';
import { useNavigate } from 'react-router';

const COUNTRIES = [
  { name: 'AR', code: '+54', i18n: 'es_ar', },
  { name: 'CH', code: '+56', i18n: 'es_cl', },
  { name: 'CO', code: '+57', i18n: 'es_co', },
  { name: 'MX', code: '+52', i18n: 'es_mx', },
  { name: 'PE', code: '+51', i18n: 'es_pe', },
  { name: 'Otro', code: '+', i18n: 'es_cl', }
]

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    marginTop: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  avatarContainer: {
    backgroundSize: "cover",
    background: 'no-repeat center/80% ',
    width: '220px',
    height: '220px',
    borderRadius: '100%',
    border: '5px solid #ddd',
    margin: '0px auto 24px auto'
  },
  cameraButton: {
    marginTop: '-25px'
  }
}));

function UserProfileEdit() {
  const navigate = useNavigate();
  let { profile } = useContext(UserContext);
  const { t } = useTranslation();
  const classes = useStyles();
  // eslint-disable-next-line
  const [sent, setSent] = useState(false);
  const [name, setName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [identification, setIdentification] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [countrySelected, setCountrySelected] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [base64Image, setBase64image] = useState(null);
  const [isNewAvatar, setIsNewAvatar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let countryRef = useRef(null);

  useEffect(() => {
    if (is.not.empty(profile)) {
      setName(profile?.name);
      setLastName(profile?.lastname);
      setEmail(profile?.email);
      setPhoneNumber(profile?.phone);
      setIdentification(profile?.identification_number);
      setAvatar(profile?.avatar?.url);
      setCountrySelected(COUNTRIES.find((c) => c.code === profile?.phone.slice(0, 3)));
    }
  }, [profile]);

  const validate = (values) => {
    let newValues = {
      ...values,
      email,
      name,
      lastName,
      phoneNumber,
      country: countrySelected
    }
    const errors = required(['name', 'lastName', 'email', 'country', 'phoneNumber'], newValues);
    return errors;
  };

  const handleChangeCountry = (country) => {
    setCountrySelected(country);
    setPhoneNumber(country.code);
    countryRef.current.value = country.code;
  }

  const onSubmit = async () => {
    setIsLoading(true);
    let body = {
      id: profile.id,
      name,
      lastName,
      identification_number: identification,
      phone: phoneNumber,
      email
    }

    let newAvatar = {
      base64: base64Image,
      user_id: profile.id,
      image_type: 'image/png'
    }

    try {
      await UserServices.updateUser(profile.id, body);
      if (isNewAvatar) {
        await UserServices.postAvatar(profile.id, newAvatar);
      }
      navigate('/perfil');
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const handleUploadClick = event => {
    var file = event.target.files[0];
    setAvatar(URL.createObjectURL(file));
    setIsNewAvatar(true);
    getBase64(event.target.files[0]).then(file64 => {
      setBase64image(file64);
    });
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h4" marked="center" align="center" component="h2">
        {t('phrases.edit_your_data')}
      </Typography>
      <AppForm>
        <Form onSubmit={onSubmit} subscription={{ submitting: true }} validate={validate}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.avatarContainer}
                    style={{ backgroundImage: avatar ? `url(${avatar})` : `url(${blankProfile})` }} >
                    <input
                      accept="image/jpeg,image/png"
                      className={classes.input}
                      id="web_image"
                      type="file"
                      name="web_image"
                      onChange={handleUploadClick}
                    />
                    <label htmlFor="web_image" >
                      <IconButton style={{ position: 'relative' }} component="span" className={classes.cameraButton}>
                        <AddAPhotoOutlinedIcon />
                      </IconButton>
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Nombre"
                    autoComplete="name"
                    name="name"
                    fullWidth
                    required
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Apellido"
                    autoComplete="lname"
                    name="lastName"
                    fullWidth
                    required
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                </Grid>
                <Grid container item xs={12} md={6} spacing={0}>
                  <Grid item xs={4} sm={4}>
                    <TextField
                      label="País"
                      name='country'
                      required
                      fullWidth
                      value={countrySelected}
                      onChange={(e) => handleChangeCountry(e.target.value)}
                      select>
                      {COUNTRIES.map((c, i) => <MenuItem key={"country_" + c.name} value={c}>
                        {c.name}
                      </MenuItem>)}
                    </TextField>
                  </Grid>
                  <Grid item xs={8} sm={8}>
                    <TextField
                      label="Teléfono"
                      autoComplete="phone"
                      name="phoneNumber"
                      fullWidth
                      required
                      inputRef={countryRef}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label={t('text.identification')}
                    autoComplete="identification"
                    name="identification"
                    fullWidth
                    required
                    onChange={(e) => setIdentification(e.target.value)}
                    value={identification}
                  >
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Email"
                    autoComplete="email"
                    name="email"
                    fullWidth
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </Grid>
              </Grid>
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback className={classes.feedback} error>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                className={classes.button}
                disabled={isLoading}
                color="primary"
                fullWidth
              >
                {isLoading ? <CircularProgress color='inherit' /> : 'Confirmar'}
              </FormButton>
            </form>
          )}
        </Form>
      </AppForm>
    </Container>
  );
}


export default UserProfileEdit;
