import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import DlocalSaveCard from 'src/modules/views/DlocalSaveCard';

function CartCheckout() {
  return (
    <React.Fragment>
      <AppAppBar />
      <DlocalSaveCard/>
      <AppFooter />
    </React.Fragment>
  );
}

export default CartCheckout;
