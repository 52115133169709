import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
	IconButton,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	Tooltip,
	Dialog,
	DialogTitle,
	DialogContent,
	Divider,
	CircularProgress,
	Button,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

// import Button from 'src/modules/components/Button';
import TextField from 'src/modules/components/TextField';
import MoneyDisplay from 'src/modules/components/MoneyDisplay';
import { BookingContext } from 'src/contexts/BookingContext';
import AmeizerList from 'src/modules/views/AmeizerList';
import ShoppingCartServices from 'src/services/ShoppingCartServices';

const useStyles = makeStyles({
	root: {
		maxWidth: 800
	},
	multipleRoot: {
		maxWidth: 350
	},
	media: {
		height: 140,
	},
	quantityText: {
		marginRight: 10,
	},
	details: {
		width: '100%'
	},
	table: {
		minWidth: 650,
	},
	multipleTable: {
		minWidth: 250,
	},
	totalsTable: {
		minWidth: 650,
		marginTop: 24
	},
	commentTitle: {
		marginTop: 24,
		marginBottom: 8,
		fontSize: '0.9rem'
	},
	paymentMethodsAccordion: {
		marginTop: 24
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		textAlign: 'center'
	},
	deleteButton: {
		marginTop: '24px',
		width: '100%'
	},
});

const CustomDeleteButton = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
		border: '3px solid #f94646!important',
    '&:hover': {
      backgroundColor: '#f9464650',
			color: '#fff',
    },
  },
}))(Button);

export default function VariantDetails({
	booking, single, multiple, setComment, handleDeleteBooking, profile, currency, noComment, cartId, BookingList,
}) {
	const navigate = useNavigate();
	const classes = useStyles();
	const [localBookingData, setLocalBookingData] = useState({});
	const { t } = useTranslation();
	const [inputDisabled, setInputDisabled] = useState(true);
  let { addBookingComment, setBooking } = useContext(BookingContext);
	const [ameizerModal, setAmeizerModal] = useState(false);
	const [favouriteAmeizerLoading, setFavouriteAmeizerLoading] = useState(false);
	const [repeatLoading, setRepeatLoading] = useState(false);

	useEffect(() => {
		if (booking) {
			setLocalBookingData(booking);
		}
	}, [booking]);

	const handleChangeComment = (comment) => {
		setLocalBookingData((currentValue) => ({
			...currentValue,
			comment: comment
		}))
	}

	const backToCalendar = (e) => {
		e.preventDefault();
		navigate(`/fecha/${cartId}/${localBookingData.id}`);
	}

	const backToCheckout = (e) => {
		e.preventDefault();
		navigate(`/checkout/${cartId}/${localBookingData.id}`);
	}

	const handleSaveComment = async () => {
		setInputDisabled((currentValue) => !currentValue);
		if (localBookingData.comment) {
      try {
        await addBookingComment(localBookingData.comment, cartId, localBookingData.id);
      } catch (error) {
        console.log(error);
      }
		}
	}

	const handleCloseAmeizerModal = () => {
		setAmeizerModal(false);
	}

	const handleSelectAmeizer = async (ameizerId) => {
		setFavouriteAmeizerLoading(true);
		let bookingUpdated = {
			booking_id: localBookingData.id,
			favourite_ameizers_ids: [ameizerId],
		}
		try {
			const response = await ShoppingCartServices.updateBooking(localBookingData.id, bookingUpdated);
			setLocalBookingData(response.data.bookings.find((b) => b.id === bookingUpdated.booking_id));
		} catch (error) {
			console.log(error);
		} finally {
			setFavouriteAmeizerLoading(false);
			handleCloseAmeizerModal();
		}
	}

	const handleRepeatBooking = async () => {
		setRepeatLoading(true);
		let newBooking = {
			address_id: localBookingData.address.id,
			service_variant_area_id: localBookingData.serviceVariantArea.id,
			quantity: localBookingData.quantity,
			ameizers_quantity: 1,
			channel: 'web'
		}
		try {
			const response = await ShoppingCartServices.createBooking(newBooking);
			setBooking(response.data);
			navigate('/fecha');
		} catch (error) {
			console.log(error);
		} finally {
			setRepeatLoading(false);
		}
	}

	return (
		<>
		<Card className={multiple ? classes.multipleRoot : classes.root}>
			<CardMedia
				className={classes.media}
				image={localBookingData?.serviceVariantArea?.image.url}
				title={localBookingData?.serviceVariantArea?.name}
			/>
			<CardContent>
				<TableContainer component={Paper}>
					<Table className={multiple ? classes.multipleTable : classes.table} aria-label="simple table">
						<TableBody>
							<TableRow >
								<TableCell component="th" scope="row">
									{t('text.service')}
								</TableCell>
								<TableCell align="right">
									{
										localBookingData?.serviceVariantArea?.name.length > 22 ?
											<Tooltip title={localBookingData?.serviceVariantArea?.name}><span>{localBookingData?.serviceVariantArea?.name.slice(0, 20) + '...'}</span></Tooltip> :
											localBookingData?.serviceVariantArea?.name
									}
								</TableCell>
							</TableRow>
							<TableRow >
								<TableCell component="th" scope="row">
									{t('text.service_price')}
								</TableCell>
								<TableCell align="right">
									<MoneyDisplay
										prefix={currency?.name === 'PEN' ? 'S/.' : '+ $'}
										value={localBookingData?.service_variant_total_price}
										displayType={'text'} />
								</TableCell>
							</TableRow>
							<TableRow >
								<TableCell component="th" scope="row">
									{t('text.quantity')}
								</TableCell>
								<TableCell align="right">x {localBookingData?.quantity}</TableCell>
							</TableRow>
							<TableRow >
								<TableCell component="th" scope="row">
									{t('text.transport')}
								</TableCell>
								<TableCell align="right">
									<MoneyDisplay
										prefix={currency?.name === 'PEN' ? 'S/.' : '+ $'}
										value={localBookingData?.transport_price}
										displayType={'text'} />
								</TableCell>
							</TableRow>
							{localBookingData.bookingAddOns && localBookingData.bookingAddOns.map((addon, i) => <TableRow key={`addon_${i}`} >
								<TableCell component="th" scope="row">
									Adicional
								</TableCell>
								<TableCell align="right">
								{`${currency?.name === 'PEN' ? 'S/.' : '+ $'}${addon.total_price} (${addon.quantity} x ${addon.addOnArea.name})`}
								</TableCell>
							</TableRow>)}
							<TableRow>
								<TableCell component="th" scope="row">
									{t('text.address')}
								</TableCell>
								<TableCell align="right">
									{
										localBookingData?.address?.address.length > 22 ?
											<Tooltip title={localBookingData?.address?.address}><span>{localBookingData?.address?.address.slice(0, 20) + '...'}</span></Tooltip> :
											localBookingData?.address?.address
									}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">
									{t('text.date_time')}
								</TableCell>
								<TableCell align="right" >
									{moment(localBookingData?.date).utc().format('DD-MM-YYYY HH:mm')}
									{!profile && <Tooltip title="Editar fecha" arrow>
										<IconButton onClick={backToCalendar}
											color='secondary'
											style={{ padding: '0px', marginLeft: '5px' }}>
											<EditOutlinedIcon fontSize="small" />
										</IconButton>
									</Tooltip>}
								</TableCell>
							</TableRow>
							<TableRow >
								<TableCell component="th" scope="row">
									Ameizer
								</TableCell>
								<TableCell align="right">
									{
									 localBookingData.requests &&	localBookingData.requests.find((r) => r.favourite) ? `${localBookingData.requests.find((r) => r.favourite).professional.user.name} ${localBookingData.requests.find((r) => r.favourite).professional.user.lastname}` : BookingList ? 'Asignado' : 'Optimizado por Ameiz'
									}
									{!BookingList && <Tooltip title="Elegir Ameizer" arrow>
										<IconButton onClick={() => setAmeizerModal(true)}
											color='secondary'
											style={{ padding: '0px', marginLeft: '5px' }}>
											<EditOutlinedIcon fontSize="small" />
										</IconButton>
									</Tooltip>}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>

				{!BookingList && <><Typography variant="subtitle1" marked="center" align="center" component="h2" className={classes.commentTitle}>
					{t('phrases.leave_a_comment')}
				</Typography>
				<TextField 
					size={'large'} 
					value={localBookingData?.comment} 
					multiline
					disabled={inputDisabled}
					fullWidth
					onChange={(e) => handleChangeComment(e.target.value)} 
				/>
				<Button
					fullWidth
					variant={inputDisabled ? 'outlined' : 'contained'}
					color='primary'
					size='small'
					style={{marginTop: '4px'}}
					onClick={() => inputDisabled ? setInputDisabled((currentValue) => !currentValue) : handleSaveComment() }
				>
					{inputDisabled ? 'editar' : 'guardar'}
				</Button></>}

				{BookingList && (
					<Button
						onClick={handleRepeatBooking}
						variant='contained'
						size='small'
						fullWidth
						color='primary'
						style={{ marginTop: '8px' }}
					>
					 {repeatLoading ? <CircularProgress size={40} color='inherit' /> : 'Repetir reserva'}
					</Button>
				)}

				{single && <TableContainer component={Paper} className={classes.totalsTable}>
					<Table className={classes.table} aria-label="simple table">
						<TableBody>
							<TableRow >
								<TableCell component="th" scope="row">
									{t('text.total_services')}:
								</TableCell>
								<TableCell align="right">
									<MoneyDisplay
										prefix={currency?.name === 'PEN' ? 'S/.' : '$'}
										value={localBookingData?.service_variant_total_price}
										displayType={'text'} />
								</TableCell>
							</TableRow>
							<TableRow >
								<TableCell component="th" scope="row">
									{t('text.additional_total')}:
								</TableCell>
								<TableCell align="right">
									<MoneyDisplay
										prefix={currency?.name === 'PEN' ? 'S/.' : '$'}
										value={localBookingData?.total_addons_price}
										displayType={'text'} />
								</TableCell>
							</TableRow>
							<TableRow >
								<TableCell component="th" scope="row">
									{t('text.total_transportation')}:
								</TableCell>
								<TableCell align="right">
									<MoneyDisplay
										prefix={currency?.name === 'PEN' ? 'S/.' : '$'}
										value={localBookingData?.transport_price}
										displayType={'text'} />
								</TableCell>
							</TableRow>
							<TableRow style={{ backgroundColor: 'black' }}>
								<TableCell component="th" scope="row" style={{ color: '#fff' }}>
									Total:
								</TableCell>
								<TableCell style={{ color: '#fff' }} align="right">
									<MoneyDisplay
										prefix={currency?.name === 'PEN' ? 'S/.' : '$'}
										value={localBookingData?.final_price}
										displayType={'text'} />
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>}

			</CardContent>

			{!profile && !single && <CardActions style={{ marginTop: '0px', paddingTop: '0px' }}>
				<CustomDeleteButton
					variant="outlined"
					color="secondary"
					onClick={() => handleDeleteBooking(localBookingData.id)}
					className={classes.deleteButton}
					startIcon={<DeleteForeverRoundedIcon />}
				>
					{t('text.delete')}
				</CustomDeleteButton>
			</CardActions>}

		</Card>
		<Dialog
			fullWidth={true}
			onClose={handleCloseAmeizerModal} 
			aria-labelledby="ameizers-dialog" 
			open={ameizerModal}
		>
			<DialogTitle id="ameizers-dialog">Seleccionar Ameizer</DialogTitle>
			<Divider/>
			<DialogContent>
				<AmeizerList
					favouriteAmeizerLoading={favouriteAmeizerLoading}
					booking={{ id: localBookingData.id, date: localBookingData.date }}
					handleSelectAmeizer={handleSelectAmeizer}
				/>
			</DialogContent>
		</Dialog>
		</>
	);
}
