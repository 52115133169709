import React from 'react';
import { useNavigate } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent, CardMedia, IconButton } from '@material-ui/core';
import is from 'is_js';
import EditIcon from '@material-ui/icons/Edit';

import Typography from 'src/modules/components/Typography';
import blankProfile from 'src/assets/blank-profile.png';

const useStyles = makeStyles({
	root: {
		maxWidth: 800,
		marginTop: '24px'
	},
	media: {
		height: 140,
		width: 140,
		borderRadius: '100%',
		margin: 'auto'
	},
	expand: {
		// marginLeft: 'auto',
	},
	editButtonContainer: {
		textAlign: 'right',
		width: '100%'
	},
	name: {
		marginBottom: '24px'
	}
});

export default function UserDetails({ userDetails }) {
	const navigate = useNavigate();
	const classes = useStyles();

	const handleGetToEditProfile = () => {
		navigate('/perfil/edit');
	}

	return (
		<>
			{
				is.not.empty(userDetails) && <Card className={classes.root}>
					<Box className={classes.editButtonContainer}>
						<IconButton
							className={classes.expand}
							onClick={handleGetToEditProfile}
							aria-label="Edit"
						>
							<EditIcon />
						</IconButton>
					</Box>
					<CardMedia
						className={classes.media}
						image={userDetails?.avatar?.url ? userDetails?.avatar?.url : blankProfile}
						title="Profile Image"
					/>
					<CardContent>
						<Typography variant="h6" marked="center" align="center" component="h2" className={classes.name}>
							{userDetails?.name} {userDetails?.lastname}
						</Typography>
						<Typography variant="subtitle1" align="center" component="h2">
							{userDetails?.phone}
						</Typography>
						<Typography variant="subtitle1" align="center" component="h2">
							{userDetails?.email}
						</Typography>
					</CardContent>
				</Card>
			}
		</>
	);
}
