import { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  CircularProgress,
  Typography,
  Link,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  ListItemSecondaryAction,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { useNavigate } from 'react-router';
import Rating from '@material-ui/lab/Rating';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import { UserContext } from 'src/contexts/UserContext';
import AmeizerServices from 'src/services/AmeizerServices';
import { BookingContext } from 'src/contexts/BookingContext';
import ShoppingCartServices from 'src/services/ShoppingCartServices';
import AmeizServices from 'src/services/AmeizServices';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(4),
    width: '90%',
    textAlign: 'center',
    margin: '0 auto'
  },
  bookingRoot: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
    margin: '0 auto'
  },
  loading: {
    textAlign: 'center',
    marginTop: '24px',
    width: '100%'
  },
  rootList: {
    width: '100%',
    margin: '0 auto',
    // position: 'relative',
    // overflow: 'auto',
    // maxHeight: 600,
  }
});

const AmeizerList = ({ classes, booking, handleSelectAmeizer, favouriteAmeizerLoading }) => {
  const navigate = useNavigate();
  const { defaultAddress } = useContext(UserContext);
  const { addressSelected } = useContext(BookingContext);
  const [allAmeizers, setAllAmeizers] = useState([]);
  const [ameizers, setAmeizers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    if (booking) {
      getFilteredAmeizerList();
    } else if (defaultAddress || addressSelected) {
      getAmeizerList();
    }
  }, [defaultAddress, addressSelected, booking]);

  const getFilteredAmeizerList = async () => {
    setIsLoading(true);
    const filter = {
      booking_id: booking.id,
      date: booking.date
    }
    try {
      const response = await ShoppingCartServices.getProfessionals(booking.id, filter);
      setAmeizers(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAmeizerList = async () => {
    setIsLoading(true);
    let filter = {
      coordinate: {
        lat: defaultAddress ? defaultAddress.coordinate.lat : addressSelected.geometry.location.lat(),
        lng: defaultAddress ? defaultAddress.coordinate.lng : addressSelected.geometry.location.lng()
      }
    }
    try {
      const response = await AmeizerServices.getProfessionals(filter);
      setAmeizers(response.data);
      setAllAmeizers(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchAmeizers = async (query) => {
    if (!query) {
      setAmeizers(allAmeizers);
    }
    if (query.length > 2) {
      setSearchLoading(true);
      let filter = {
        coordinate: {
          lat: addressSelected.coordinate.lat,
          lng: addressSelected.coordinate.lng
        },
        text: query
      }
      try {
        const response = await AmeizServices.search(filter);
        let professionals = response.data.professionals;
        setAmeizers(professionals); 
      } catch (error) {
        console.log(error);
      } finally {
        setSearchLoading(false);
      }
    }
  }

  return (
    <Box className={booking ? classes.bookingRoot : classes.root}>

      {!booking && <TextField
        id="outlined-basic"
        fullWidth
        style={{ backgroundColor: '#e3b9b333' }}
        onChange={(event) => handleSearchAmeizers(event.target.value)}
        InputProps={{
          endAdornment: <InputAdornment position="start"><SearchRoundedIcon /></InputAdornment>,
        }}
        label="Encuentra tu próximo ameizer"
        variant="outlined" />}

      <List className={classes.rootList}>
        {isLoading && <Box className={classes.loading}>
            <CircularProgress color="secondary" />
          </Box>}
        {!isLoading && ameizers && <Divider variant="inset" component="li" />}
        {!isLoading && ameizers && ameizers.map((ameizer) => <Box key={ameizer.id}>
          <ListItem 
            alignItems="flex-start" 
            button={!booking}
            onClick={() => {!booking && navigate(`/ameizer/${ameizer.id}/perfil`)}}
          >
          <ListItemAvatar>
            <Avatar alt={ameizer.user.name} src={ameizer.user.avatar ? ameizer.user.avatar.full_url : null} />
          </ListItemAvatar>
          <ListItemText
            primary={`${ameizer.user.name} ${ameizer.user.lastname}`}
            secondary={
              booking ?
              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Rating name="rating" value={Number(ameizer.rating)} readOnly style={{ marginRight: '8px '}} />
                    <Typography>({ameizer.reviews_count} reviews)</Typography>
                  </Box>
                  <Button
                    variant='outlined'
                    startIcon={<PersonAddIcon />}
                    display={favouriteAmeizerLoading}
                    onClick={() => handleSelectAmeizer(ameizer.id)}
                  >
                    {favouriteAmeizerLoading ? <CircularProgress/> : 'Elegir' }
                  </Button>
                </Box>
                <Button
                  component={Link}
                  href={`/ameizer/${ameizer.id}/perfil`}
                  target="_blank"
                  rel="noopener"
                  underline="none"
                  size="small"
                >
                  Ver perfil
                </Button>
              </Box> :
              "Parte del equipo de Ameizers"}
          />
          {!booking && <ListItemSecondaryAction>
            <Button
              style={{ fontWeight: 600 }}
              edge="end" 
              aria-label="delete" 
              onClick={() => {!booking && navigate(`/ameizer/${ameizer.id}/perfil`)}}
            >
              Ver perfil
            </Button>
          </ListItemSecondaryAction>}
        </ListItem>
        <Divider variant="inset" component="li" /></Box>)}
      </List>
    </Box>
  );
}

export default withStyles(styles)(AmeizerList);
