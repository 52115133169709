// eslint-disable-next-line 
export default {
    "home": {
        "main_title": "Bienestar y belleza",
        "subtitle1": "Vive una experiencia que querrás repetir",
        "booking_button": "Reservar ahora",
        "subtitle2": "Inviertí en vos"
    },
    "navbar": {
        "logout": "Cerrar sesión",
        "my_profile": "Mi perfil",
        "my_bookings": "Mis reservas",
        "my_messages": "Mensajes",
        "my_packs": "Mis packs",
        "refer_friend": "Recomienda y gana",
        "my_credits": "Mis creditos"
    },
    "footer": {

    },
    "text": {
        "identification": "Rut",
        "categories": "Categorias",
        "sign_in": "Iniciar sesión",
        "password": "Contraseña",
        "contact_us": "Escríbenos",
        "service": "Servicio",
        "service_price": "Precio servicio",
        "quantity": "Cantidad",
        "transport": "Transporte",
        "address": "Dirección",
        "date_time": "Fecha y Hora",
        "total_services": "Total servicios",
        "additional_total": "Total adicionales",
        "total_transportation": "Total transporte",
        "delete": "Borrar",
        "floor_and_house": "Departamento, casa o información extra",
        "from": "Desde",
        "extras": "Adicionales",
        "add": 'Agregar',
        "registration": "Registro",
        "first_name": 'Nombre',
        "last_name": "Apellido",
        "country": 'País',
        "code": "Código",
        "phone": "Teléfono",
        "confirm": "Confirmar",
        "cart": "Carrito",
        "go_back": "Volver",
        "pay": "Pagar",
        "cancel": "Cancelar",
        "save_changes": "Guardar cambios",
        "accept": "Aceptar",
        "my_address": "Mis direcciones",
        "add_address": "Agregar dirección",
        "terms_and_conditions": 'Términos y condiciones',
        "rate": "Calificar",
        "rating": "Calificación",
        "thanks": "Muchas gracias",
        "my_profile": "Mi perfil",
        "comment": "Comentario",
        "messages": "Mensajes",
        "share": "Compartir",
        "copied": "Copiado"
    },
    "phrases": {
        "address_search_title": "¿Dónde vivirás tu experiencia ameiz?",
        "select_service": "Selecciona un servicio",
        "no_coverage_for_the_selected_address": "Sin cobertura para la dirección seleccionada.",
        "contact_us_message": "¿Quieres saber cúando llegaremos? Contáctanos",
        "forgotten_password_message": "¿Olvidaste tu contraseña?",
        "forgotten_password_instructions": "Te enviaremos un mensaje a tu correo con las instrucciones. Por favor espera hasta 5 minutos y revisa la carpeta de correos no deseados (spam).",
        "not_a_member_message": "¿No eres miembro aún?",
        "Sign_up_here_message": "Regístrese aquí",
        "in_progress_message": "En proceso",
        "what_will_be_your_experience_today": "¿Cuál será tu experiencia hoy?",
        "can_not_find_what_you_are_looking_for": "¿No encuentras lo que buscas?",
        "when_do_you_want_your_service?": "¿Cuándo deseas tu servicio?",
        "the_purchase_has_been_successfully_completed": "La compra se ha realizado exitosamente",
        "we_are_preparing_the_best_wellness_experience": "Estamos preparando la mejor experiencia en bienestar",
        "leave_a_comment": "¿Quieres dejar un comentario para tu Ameizer?",
        "no_times_available": "No hay horarios disponibles para la fecha seleccionada.",
        "select_address": "Selecciona una dirección",
        "enter_a_new_address": "Ingresa una dirección nueva",
        "move_the_pin": "Puedes mover el pin para ajustar la dirección (mantén presionado el pin para moverlo)",
        "how_many_people": "¿Cuántas personas?",
        "select_day_and_time": 'Seleccionar Fecha/Hora',
        "select_a_day": 'Selecciona una fecha',
        'reset_mail_sended': "Correo enviado",
        "repeat_your_password": "Repite tu contraseña",
        "already_have_an_account?": "¿Ya tienes una cuenta?",
        "pay_and_book": "Pagar y reservar",
        "keep_buying": "Seguir comprando",
        "no_services_selected": "No hay servicios seleccionados",
        "select_payment_method": "Seleccionar medio de pago",
        "pay_error": "Ha habido un error, por favor inténtalo más tarde.",
        "select_a_card": "Selecciona un tarjeta",
        "choose_where_you_will_live_your_experience": "Elige donde vivirás tu experiencia",
        "change_country_message": "Al cambiar de país, eliminarás los servicios que tienes en tu carrito. ¿Deseas continuar?",
        "no_services_scheduled": "No hay servicios agendados",
        "rate_your_experience_with": "Evalúa tu experiencia con",
        "anything_you_would_like_to_comment_on?": "¿Tienes algo que te gustaría comentar? Este mensaje será visible para el equipo Ameiz y con él podremos mejorar la Experiencia Ameiz",
        "evaluation_saved": "Hemos registrado la evaluación que has realizado.",
        "service_already_qualified": "El servicio ya ha sido calificado.",
        "adding_a_new_address": "Estás agregando una dirección",
        "save_card": "Guardar tarjeta",
        "secure_transaction": "Transacción segura",
        "edit_your_data": "Edita tus datos",
        "referral_code": "¿Tienes un código de referido?",
        "refer_and_win_subtitle": "Para tu próxima experiencia Ameiz compartiendo el código:",
        "refer_and_win": "Recomienda y gana",
        "how_it_works": "¿Cómo funciona?",
        "referral_description": "Comparte el código con tus amigos, y diles que lo agreguen al finalizar su compra, una vez vivan su experiencia Ameiz, cargaremos automáticamente en tus créditos el premio para que disfrutes tu próxima experiencia Ameiz.",
    },
    "languages": {
        "es": "Español",
        "en": "Inglés",
    },
    "countries": {
        "CO": "Colombia",
        "CL": "Chile",
        "PE": "Perú",
        "AR": "Argentina",
        "MX": "México",
        "US": "Estados Unidos",
    },
    "booking_status": {
        'created': 'Creado',
        'searching': 'Buscando',
        'accepted': 'Aceptado',
        'done': 'Realizado',
        'cancelled': 'Cancelado',
        'on_my_way': 'En camino',
        'arrived': 'En destino',
        'refunded': 'Reembolsado'
    },
    "transaction_concepts": {
        "ameizer_payment": "Pago por servicio realizado",
        "purchase": "Compra de servicio",
        "referred": "Abono por referido",
        "refund": "Devolución",
        "compensation": "Compensación",
        "ameizer_withdraw": "Retiro de dinero"
    },
}