import { useState, useEffect, useContext } from 'react';
import { IconButton, Badge } from '@material-ui/core';
import InsertCommentOutlinedIcon from '@material-ui/icons/InsertCommentOutlined';
import { useNavigate } from 'react-router';

import { db } from 'src/utils/firebase';
import { AuthContext } from 'src/contexts/AuthContext';

const MessageNotification = ({ mobile }) => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const [notifications, setNotifications] = useState(0);

  useEffect(() => {
    if (userData) {
      let onValueChange;
      onValueChange = db.ref(`unread/user_${userData.user_id}`)
      .on('value', snapshot => {
        let messages = snapshot.val();
        if (messages) {
          let notifications = Object.values(messages).filter((unread) => !unread.isRead);
          setNotifications(notifications);
        }
      });

      return () => {
        db.ref(`unread/user_${userData.user_id}`)
          .off('value', onValueChange);
      }

    }
  }, [userData]);

  return (
    <>
      {!mobile && <IconButton
        aria-label="notifications"
        color="inherit"
        onClick={() => navigate('/mensajes')}
      >
        <Badge
          badgeContent={notifications.length} 
          color="secondary">
          <InsertCommentOutlinedIcon />
        </Badge>
      </IconButton>}
      {mobile && <Badge
          badgeContent={notifications.length} 
          color="secondary">
          <InsertCommentOutlinedIcon />
        </Badge>}
    </>
  )
}

export default MessageNotification
