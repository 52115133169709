import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts/AuthContext';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { Container, CircularProgress, Grid } from '@material-ui/core';

import UserDetails from 'src/modules/components/UserDetails';
import UserAddresses from 'src/modules/components/UserAddresses';
import UserServices from 'src/services/UserServices';
import { UserContext } from 'src/contexts/UserContext';
import Typography from 'src/modules/components/Typography';

const styles = (theme) => ({
	container: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(15),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	progress: {
		marginTop: theme.spacing(8),
	},
});

function UserProfile(props) {
	const { classes } = props;
	let { userData } = useContext(AuthContext);
	let { setProfile } = useContext(UserContext);
	const { t } = useTranslation();
	const [addresses, setAddresses] = useState([]);
	const [userDetails, setUserDetails] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (userData) {
			getProfileInfo();
		}
		// eslint-disable-next-line
	}, [userData]);

	const getProfileInfo = async () => {
		setIsLoading(true);
		try {
			await Promise.all([getUserAddresses(), getUserData()]);
		} catch (error) {
			console.log(error);
		}
		setIsLoading(false);
	}

	const getUserAddresses = async () => {
		try {
			const response = await UserServices.getUserAdresses(userData.user_id);
			setAddresses(response.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getUserData = async () => {
		try {
			const response = await UserServices.getUser(userData.user_id);
			setUserDetails(response.data);
			setProfile(response.data);
		} catch (error) {
			console.log(error);
		}
	}

	const handleDeleteAddress = async (addressId) => {
		try {
			await UserServices.deleteUserAddress(userData.user_id, addressId);
		} catch (error) {
			console.log(error);
		}
	}


	return (
		<Container className={classes.container}>
			<Typography variant="h4" marked="center" align="center" component="h2">
				{t('text.my_profile')}
			</Typography>
			{isLoading && <CircularProgress color='secondary' className={classes.progress} />}
			{!isLoading && <>
				<Grid container spacing={2}>
					<Grid item md={4} xs={12}>
						<UserDetails userDetails={userDetails} />
					</Grid>
					<Grid item md={8} xs={12}>
						<UserAddresses addresses={addresses} handleDeleteAddress={handleDeleteAddress} />
					</Grid>
				</Grid>
			</>}
		</Container>
	);
}


export default withStyles(styles)(UserProfile);
