import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AmeizerServices from 'src/services/AmeizerServices';
import { Container, Box, Avatar, IconButton, CircularProgress, Button } from '@material-ui/core';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Typography from 'src/modules/components/Typography';
import Divider from '@material-ui/core/Divider';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router';
import InsertCommentOutlinedIcon from '@material-ui/icons/InsertCommentOutlined';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

import AppTab from 'src/modules/components/AppTab';
import ProfessionalServices from 'src/modules/components/ProfessionalServices';
import ProfessionalPortfolio from 'src/modules/components/ProfessionalPortfolio';
import ProfessionalReviews from 'src/modules/components/ProfessionalReviews';
import { COUNTRIES } from 'src/utils/constants';
import { UserContext } from 'src/contexts/UserContext';
import { BookingContext } from 'src/contexts/BookingContext';
import { RouterContext } from 'src/contexts/RouterContext';

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardRoot: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: '50%',
    textAlign: 'center',
    margin: '0 auto'
  },
  media: {
		height: 140,
		width: 140,
		borderRadius: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4)
		// margin: 'auto'
	},
  profile: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0px',
    width: '100%'
  },
  profileHeader: {
    display: 'flex',
    padding: '0px 0px 24px 24px',
    width: '100%',
    justifyContent: 'center'
  },
  avatarBox: {
    flex: '3'
  },
  avatar: {
    color: '#999',
    backgroundColor: 'transparent',
    width: theme.spacing(20),
    height: theme.spacing(20),
    margin: 'auto',
  },
  profileDataBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    with: '100%',
    flex: 3,
  },
  profileData: {
    // minWidth: '250px',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'left',
    // paddingLeft: '12%'
  },
  rates: {
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'center',
    marginTop: theme.spacing(4),
  }
});

const AmeizerDetails = ({ classes }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { defaultAddress } = useContext(UserContext);
  const { addressSelected } = useContext(BookingContext);
  const { setPreviousPath } = useContext(RouterContext);
  const { ameizerId } = useParams();
  const { i18n } = useTranslation();
  const [ameizer, setAmeizer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    setPreviousPath('/ameizer');
  }, [])

  useEffect(() => {
    if (defaultAddress || addressSelected) {
      getAmeizerData();
    }
  }, [defaultAddress, addressSelected]);

  const getAmeizerData = async () => {
    let country = COUNTRIES.find((c) => c.lang === i18n.language);
    setIsLoading(true);
    let filter = {
      professional_id: ameizerId,
      coordinate: {
        lat: defaultAddress ? defaultAddress.coordinate.lat : addressSelected.geometry.location.lat(),
        lng: defaultAddress ? defaultAddress.coordinate.lng : addressSelected.geometry.location.lng()
      }
    }

    try {
      const response = await AmeizerServices.getProfessional(ameizerId, filter);
      console.log('getAmeizerData', response.data);
      setAmeizer(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleChangeIndex = (index) => {
    setTabValue(index);
  };
  
  return (
    <Container className={classes.container}>
      {isLoading && <CircularProgress size={60} />}
      {!isLoading && <Typography variant="h4" marked="center" className={classes.title} component="h2">
          Ameizer
      </Typography>}

      {ameizer && !isLoading && <Box className={classes.profile} >
          <Box className={classes.profileHeader}>
            <Box className={classes.avatarBox}>
              <Avatar 
                alt={ameizer.user.name}
                src={ameizer.user.avatar.full_url}
                className={classes.avatar}
              /> 
            </Box>
            <Box className={classes.profileDataBox}>
              <Box className={classes.profileData}>
                <Typography variant="h6" style={{ fontSize: '1.8rem', color: '#262626', fontWeight: '500' }}>{`${ameizer.user.name} ${ameizer.user.lastname}`}</Typography>
              </Box>
              <Box className={classes.rates}>
                <Box style={{ paddingRight: '24px'}}>
                  <Typography variant="h3" style={{ color: '#888888', fontSize: '1rem', lineHeight: '2' }}>
                    {ameizer.reviews_count}
                  </Typography>
                  <Typography variant="h3" style={{ color: '#888888', fontSize: '1rem' }}>
                    Reviews
                  </Typography>
                </Box>
                <Box style={{ paddingLeft: '24px', paddingRight: '24px' }}>
                  <Typography variant="h3" style={{ color: '#888888', fontSize: '1rem', lineHeight: '2' }}>
                    {ameizer.rating}
                  </Typography>
                  <Typography variant="h3" style={{ color: '#888888', fontSize: '1rem' }}>
                    Evaluación
                  </Typography>
                </Box>
                <Box style={{ paddingLeft: '24px'}}>
                  <IconButton onClick={() => navigate(`/mensajes?ameizerId=${ameizerId}`)}>
                    <InsertCommentOutlinedIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider/>

          <AppTab value={tabValue} setValue={setTabValue} tabs={['Servicios', 'Portafolio', 'Reviews']} />

          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={tabValue}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={tabValue} index={0} dir={theme.direction}>
              <ProfessionalServices services={ameizer.services} ameizerId={ameizerId} />
            </TabPanel>
            <TabPanel value={tabValue} index={1} dir={theme.direction}>
              <ProfessionalPortfolio ameizerId={ameizerId} />
            </TabPanel>
            <TabPanel value={tabValue} index={2} dir={theme.direction}>
              <ProfessionalReviews ameizerId={ameizerId} />
            </TabPanel>
          </SwipeableViews>

			</Box>}

      {!isLoading && <Button
      variant='contained'
      color='primary'
      startIcon={<ArrowBackIosRoundedIcon />}
      onClick={() => navigate('/')}
      >
        Volver
      </Button>}
    </Container>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default withStyles(styles)(AmeizerDetails);
