import { useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const GroupedButtons = ({ handleCounter }) => {
  const [counter, setCounter] = useState(1);

  const handleIncrement = () => {
    setCounter((currentValue) => currentValue + 1);
    handleCounter('increment');
  };

  const handleDecrement = () => {
    setCounter((currentValue) => currentValue - 1);
    handleCounter('decrement');
  };

  return (
    <ButtonGroup size="small" aria-label="small outlined button group">
      <Button onClick={handleDecrement} disabled={counter === 1} >-</Button>
      <Button disabled style={{color: '#343434'}}>{counter}</Button>
      <Button onClick={handleIncrement}>+</Button>
    </ButtonGroup>
  )

}

export default GroupedButtons;