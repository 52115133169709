import React from 'react';
import Services from 'src/modules/views/Services';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';

function Index() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Services />
      <AppFooter />
    </React.Fragment>
  );
}

export default Index;