import { useRoutes } from 'react-router-dom';
import routes from './routes';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import { BookingProvider } from 'src/contexts/BookingContext';
import { RouterProvider } from 'src/contexts/RouterContext';
import { UserProvider } from 'src/contexts/UserContext';
import { LoadScript } from '@react-google-maps/api';
import { useLogged } from 'src/hooks/useLogged';
import { useCurrentLang } from 'src/hooks/useCurrentLang';

const langs = ['en_us', 'es_ar', 'es_cl', 'es_co', 'es_mx', 'es_pe'];

const App = () => {
  const routing = useRoutes(routes);
  useCurrentLang();
  useLogged();

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider>
        <UserProvider>
          <BookingProvider>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={["places"]} >
              <CssBaseline />
              {routing}
            </LoadScript>
          </BookingProvider>
        </UserProvider>
      </RouterProvider>
    </ThemeProvider>
  );
}

export default App;