import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import UserProfile from 'src/modules/views/UserProfile';

function Account() {
  return (
    <React.Fragment>
      <AppAppBar />
      <UserProfile />
      <AppFooter />
    </React.Fragment>
  );
}

export default Account;
