import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Menu,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import is from "is_js";
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from 'react-router';

import { UserContext } from 'src/contexts/UserContext';
import { AuthContext } from 'src/contexts/AuthContext';
import UserServices from "src/services/UserServices";
import ShoppingCartServices from 'src/services/ShoppingCartServices';
import Button from 'src/modules/components/Button';

const LANGS = [
  {
    name: 'es',
    country: 'CL'
  },
  {
    name: 'es',
    country: 'AR'
  },
  {
    name: 'es',
    country: 'PE'
  },
  {
    name: 'es',
    country: 'MX'
  },
  {
    name: 'es',
    country: 'CO'
  },
  // {
  //   name: 'en',
  //   country: 'US'
  // }
]

const LangSelector = ({ mobile }) => {
  const navigate = useNavigate();
  let {
    setDefaultAddress
  } = useContext(UserContext);
  let {
    userData,
    logged
  } = useContext(AuthContext);
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLang, setCurrentLang] = useState(null);
  const [init, setInit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [newLang, setNewLang] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const toggleLang = async (newLangName) => {
    if (newLangName) {
      setNewLang(newLangName);
      if (logged) {
        try {
          const response = await UserServices.getCart(userData.user_id);
          if (is.not.empty(response.data.bookings)) {
            setBookings(response.data.bookings);
            setOpenModal(true);
          } else {
            handleChangeLocation(newLangName);
            navigate('/');
            window.location.reload(false);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        handleChangeLocation(newLangName);
        navigate('/');
        window.location.reload(false);
      }
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const handleAccept = async () => {
    setDeleteLoading(true);
    for (let i in bookings) {
      try {
        await ShoppingCartServices.deleteBooking(bookings[i].id);
      } catch (error) {
        console.log(error);
      }
    }
    setDeleteLoading(false);
    setOpenModal(false);
    handleChangeLocation(newLang);
    navigate('/');
    window.location.reload(false);
  }

  const handleChangeLocation = async (newLangName) => {
    setDefaultAddress(null);
    localStorage.removeItem('default_address');
    setAnchorEl(null);
    setCurrentLang(newLangName);
    localStorage.setItem('lang', `${newLangName.name}_${newLangName.country.toLowerCase()}`);
    await i18n.changeLanguage(`${newLangName.name}_${newLangName.country.toLowerCase()}`); // change app display lang
  }

  useEffect(() => {
    if (localStorage.getItem('lang') && !init) {
      setInit(true);
      const defaultLang = localStorage.getItem('lang').slice(3);
      setCurrentLang(LANGS.find(l => l.country.toLowerCase() === defaultLang));
    }
    // eslint-disable-next-line
  }, [localStorage.getItem('lang'), init]);

  return (
    <Box>
      <Button
        color='inherit'
        onClick={handleOpenMenu}>
        {currentLang && !mobile && <ReactCountryFlag svg countryCode={currentLang?.country} style={{ width: 24, height: 24, }} />}
        {mobile && currentLang && <Box style={{ display: 'flex' }} >
          <div>
            <div>{t(`languages.${currentLang.name}`)}</div>
            <div style={{ fontSize: 10, color: 'gray', marginTop: -5 }}>{t(`countries.${currentLang.country}`)}</div>
          </div>
        </Box>}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {LANGS?.map(l => <MenuItem onClick={() => toggleLang(l)} style={{ display: 'flex' }} key={'c_' + l.country}>
          <ReactCountryFlag svg countryCode={l.country} style={{ marginRight: 8, width: 24, height: 24 }} />
          <div>
            <div>{t(`languages.${l.name}`)}</div>
            <div style={{ fontSize: 10, color: 'gray', marginTop: -5 }}>{t(`countries.${l.country}`)}</div>
          </div>
        </MenuItem>
        )}
      </Menu>
      <FullCartModal t={t} onClose={handleCloseModal} open={openModal} handleAccept={handleAccept} deleteLoading={deleteLoading} />
    </Box>
  )
};

export default LangSelector;


function FullCartModal({ onClose, open, handleAccept, deleteLoading, t }) {

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <Box style={{ border: '1px solid #ff336695', padding: '12px' }}>
        <DialogTitle id="simple-dialog-title" style={{ padding: '24px 24px', }}>

          <Typography variant="h4" component="span"
            style={{
              borderRadius: 0,
              padding: '24px 0px',
              fontSize: '1.2rem',
              fontWeight: 'normal'
            }}
          >
            {t('phrases.change_country_message')}
          </Typography>
        </DialogTitle>

        <DialogActions>
          <Button
            size='small'
            variant='outlined'
            onClick={handleClose}
            color="secondary">
            {t('text.cancel')}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            size='small'
            onClick={handleAccept}
            autoFocus>
            {deleteLoading ? <CircularProgress color='inherit' size={20} /> : t('text.accept')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

