import { createContext, useState, useEffect } from "react";

let RouterContext = createContext();
let { Provider } = RouterContext;

function RouterProvider({ children }) {
const [previousPath, setPreviousPath] = useState('');

  return (
    <Provider
      value={{
        previousPath,
        setPreviousPath,
      }}
    >
      {children}
    </Provider>
  );
}

export { RouterProvider, RouterContext };