import axios from "axios";

const Services = {
  getCategories: async (location) => {
    console.log(`Services.getCategories(${JSON.stringify(location)})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/category/search`;
    return axios.post(url, location);
  },
  getServicesByCategoryId: async (location, categoryId) => {
    console.log(`Services.getServicesByCategoryId(${categoryId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/category/${categoryId}/search`;
    return axios.post(url, location);
  }
};

export default Services;