import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import Messages from 'src/modules/views/Messages';

function Chat() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Messages />
      <AppFooter />
    </React.Fragment>
  );
}

export default Chat;
