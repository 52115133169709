import { useContext } from 'react';

import { UserContext } from 'src/contexts/UserContext';
import SelectAddress from 'src/scenes/SelectAddress';
import SelectCategory from 'src/scenes/SelectCategory';

const DefaultAddressChecker = () => {
  const { defaultAddress } = useContext(UserContext);

  if (localStorage.getItem('default_address') || defaultAddress) {
    return <SelectCategory />
  } else {
    return <SelectAddress />
  }

}

export default DefaultAddressChecker;
