import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import {
  Box,
  Grid,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  makeStyles,
  Container,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";

import TextField from 'src/modules/components/TextField';
import FormButton from 'src/modules/form/FormButton';
import Typography from 'src/modules/components/Typography';

import PaymentServices from 'src/services/PaymentServices';

var dlocalInstance, dlocalFields;

// GENERATE DEVICE ID FOR DLOCAL TRANSACTION
const generateDeviceId = () => {
  var nav = window.navigator;
  var screen = window.screen;
  var deviceId = nav.mimeTypes.length;
  Object.values(navigator.mimeTypes).forEach(p => deviceId += p.type);
  deviceId += nav.userAgent.replace(/\D+/g, '');//Only use digits
  deviceId += nav.plugins.length;
  Object.values(navigator.plugins).forEach(p => deviceId += p.filename);
  deviceId += screen.height || '';
  deviceId += screen.width || '';
  deviceId += screen.pixelDepth || '';
  return checksum(deviceId);
}

const checksum = (s) => {
  var hash = 0, strlen = s.length, i, c;
  if (strlen === 0) {
    return hash;
  }
  for (i = 0; i < strlen; i++) {
    c = s.charCodeAt(i);
    hash = ((hash << 5) - hash) + c;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

function DlocalSaveCard() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  let { auth_token } = useParams();

  useEffect(() => {
    loadDLocalScripts()
    // eslint-disable-next-line 
  }, []);

  const handlePay = async (card_token) => {
    setIsLoading(true);
    let device_id = `${generateDeviceId()}`;
    let ip = '';
    try {
      ip = await PaymentServices.getPublicIP();
      await PaymentServices.dLocalSaveCard(auth_token, card_token, ip, device_id);
      handleSaveCardSuccess()
    } catch (e) {
      console.log(e);
      handleSaveCardSuccess()
    } finally {
      setIsLoading(false)
    }
  }

  const handleSaveCardSuccess = () => {
    if (new URLSearchParams(window.location.search).get("profile")) {
      navigate('/medios-de-pago', { replace: true });
    } else {
      navigate('/process-payment/save-card-success', { replace: true });
    }
  }

  function loadDLocalScripts() {
    let dlocalScript = document.createElement("script");
    dlocalScript.setAttribute("src", process.env.REACT_APP_DLOCAL_JS_IMPORT);
    dlocalScript.async = true;
    dlocalScript.defer = true;
    dlocalScript.onload = () => {
      dlocalInstance = window.dlocal(process.env.REACT_APP_DLOCAL_PUBLIC_KEY);
      dlocalFields = dlocalInstance.fields({
        locale: "es",
        country: "AR",
      });
      var style = {
        base: {
          fontSize: "15px",
          fontFamily: "'Inter UI medium', sans-serif",
          lineHeight: '28px',
          fontSmoothing: 'antialiased',
          fontWeight: '500',
          color: "#666",
          '::placeholder': {
            color: "#aab7c4"
          },
          iconColor: "#adbfd3"
        },
      };
      var card = dlocalFields.create("card", { style: style });
      card.addEventListener("change", function (event) {
        var displayError = document.getElementById("card-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });
      card.mount(document.getElementById("card-field"));
      var form = document.getElementById("payment-form");
      form.addEventListener("submit", function (event) {
        event.preventDefault();
        setIsLoading(true)
        var cardHolderName = document.getElementById("card-holder").value;
        if (!cardHolderName) {
          var displayError = document.getElementById("card-errors");
          displayError.textContent = 'Debe ingresar el nombre del titular de la tarjeta.';
          setIsLoading(false)
          return;
        }
        dlocalInstance
          .createToken(card, {
            name: cardHolderName,
          })
          .then(function (result) {
            handlePay(result.token)
          })
          .catch((result) => {
            if (result.error) {
              var errorField = document.getElementById("card-errors");
              errorField.textContent = result.error.message;
              setIsLoading(false);
            }
          });
      });
    };
    document.head.appendChild(dlocalScript);
  }

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Box border={1} className={classes.cardBoxContainer} borderRadius={2}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h4" className={classes.title} color="textPrimary" gutterBottom>
              {t('phrases.save_card')}
            </Typography>
            <form method="post" id="payment-form" className={classes.cardInput}>
              <Box border={1} borderColor="grey.500" className={classes.cardNumberBoxContainer}>
                <div>
                  <label htmlFor="card-field"></label>
                  <div id="card-field"></div>
                  <div id="card-errors" role="alert"></div>
                </div>
              </Box>
              <div className={classes.cardHolderName}>
                <TextField className={classes.cardHolderNameInput} id="card-holder" label="Titular de la tarjeta" />
              </div>
              <CardActions className={classes.saveCardButton}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="h5" className={classes.subTitle} color="textPrimary" gutterBottom>
                      {t('phrases.secure_transaction')}
                    </Typography>
                    <img width="100" src="/static/images/secure-connection.png" alt='secure-conection' />
                  </Grid>
                  <Grid item xs={6}>
                    {isLoading ? <CircularProgress /> :
                      <FormButton type="submit" variant="contained" color="primary">
                        {t('phrases.save_card')}
                      </FormButton>
                    }
                  </Grid>
                </Grid>
              </CardActions>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )
} export default DlocalSaveCard;

const useStyles = makeStyles({
  root: {
    marginTop: 40,
    marginBottom: 160
  },
  cardNumberBoxContainer: {
    padding: 10,
  },
  saveCardButton: {
    marginTop: 20,
  },
  secureTransaction: {
    flexDirection: 'row',
  },
  title: {
    marginBottom: 20,
  },
  subTitle: {
    minHeight: 30,
  },
  cardInput: {
    marginTop: 25,
  },
  cardHolderName: {
    marginTop: 20,
  },
  cardHolderNameInput: {
    width: '100%'
  }
});
