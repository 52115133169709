import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import CreateAddresForm from 'src/modules/views/CreateAddresForm';

function CreateAddress() {
  return (
    <React.Fragment>
      <AppAppBar />
      <CreateAddresForm />
      <AppFooter />
    </React.Fragment>
  );
}

export default CreateAddress;
