import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, Box, Avatar } from '@material-ui/core';
import ReactCountryFlag from "react-country-flag";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useNavigate } from 'react-router';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import EventIcon from '@material-ui/icons/Event';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import { useTranslation } from "react-i18next";
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';

import AppBarLogo from 'src/assets/appbar-logo.png';
import { AuthContext } from 'src/contexts/AuthContext';
import { UserContext } from 'src/contexts/UserContext';
import LangSelector from 'src/modules/components/LangSelector';
import AddressSelected from 'src/modules/components/AddressSelected';
import DefaultAddressModal from 'src/modules/components/DefaultAddressModal';
import MessageNotification from 'src/modules/components/MessageNotification';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  titleContainer: {
    textAlign: 'center',
    margin: '0 auto',
  },
}));

export default function PersistentDrawerLeft() {
  const { t } = useTranslation();
  let { logged, handleLogout, userData } = useContext(AuthContext);
  let { getSavedAddress, defaultAddress } = useContext(UserContext);
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);

  const handleClose = () => {
    setOpenAddressModal(false);
  };

  const handleClickOpen = () => {
    setOpenAddressModal(true);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLocalLogout = () => {
    handleLogout();
    getSavedAddress();
    navigate('/');
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            style={{ position: 'absolute' }}
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Box className={classes.titleContainer}>
            <Link
              variant="h6"
              underline="none"
              color="inherit"
              className={classes.title}
              href="/"
            >
              <img src={AppBarLogo} height='80px' width='80px' alt="Ameiz" />
            </Link>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />

        {!logged && <List>
          <ListItem button onClick={() => navigate('/ingreso')}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary={'Ingresar'} />
          </ListItem>
          <ListItem button onClick={() => navigate('/registro')} >
            <ListItemIcon><EditOutlinedIcon /></ListItemIcon>
            <ListItemText primary={'Registro'} />
          </ListItem>
          <Divider />
          <ListItem button >
            <ListItemIcon>
              {localStorage.getItem('lang') && <ReactCountryFlag svg countryCode={localStorage.getItem('lang').slice(3)} style={{ marginRight: 8, width: 24, height: 24 }} />}
            </ListItemIcon>
            <LangSelector mobile />
          </ListItem>
          <Divider />
        </List>}

        {logged && <List>
          <ListItem>
            <Box style={{ width: '100%' }}>
              {userData?.avatar?.url ? <Avatar alt="avatar" src={userData?.avatar?.url} style={{ margin: '0 auto' }} /> : <AccountCircle />}
            </Box>
          </ListItem>
          <Divider />
          {defaultAddress && <ListItem button onClick={() => { handleClickOpen(); handleDrawerClose(); }}>
            <ListItemIcon><LocationOnOutlinedIcon /></ListItemIcon>
            <AddressSelected mobile />
          </ListItem>}
          <ListItem button onClick={() => navigate('/cart')}>
            <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
            <ListItemText primary={'Carrito'} />
          </ListItem>
          <ListItem button onClick={() => navigate('/perfil')} >
            <ListItemIcon>
              <AccountCircleRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={'Mi perfil'} />
          </ListItem>
          <ListItem button onClick={() => navigate('/medios-de-pago')} >
            <ListItemIcon>
              <CreditCardRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={'Medios de pago'} />
          </ListItem>
          <ListItem button onClick={() => navigate('/reservas')}>
            <ListItemIcon><EventIcon /></ListItemIcon>
            <ListItemText primary={'Mis reservas'} />
          </ListItem>
          {/* <ListItem button onClick={() => {navigate('/mis-packs');}}>
            <ListItemIcon><DateRangeIcon /></ListItemIcon>
            <ListItemText primary={t('navbar.my_packs')} />
          </ListItem> */}
          <ListItem button onClick={() => {navigate('/mis-creditos');}}>
            <ListItemIcon><AccountBalanceWalletOutlinedIcon /></ListItemIcon>
            <ListItemText primary={t('navbar.my_credits')} />
          </ListItem>
          <ListItem button onClick={() => navigate('/mensajes')}>
            <ListItemIcon><MessageNotification mobile /></ListItemIcon>
            <ListItemText primary={'Mensajes'} />
          </ListItem>
          <ListItem button onClick={() => navigate('/recomienda-un-amigo')}>
            <ListItemIcon><ShareOutlinedIcon /></ListItemIcon>
            <ListItemText primary={'Recomienda y gana'} />
          </ListItem>
          <ListItem button onClick={handleLocalLogout} >
            <ListItemIcon><ExitToAppRoundedIcon /></ListItemIcon>
            <ListItemText primary={'Cerrar sesión'} />
          </ListItem>
          <Divider />
          <ListItem button >
            <ListItemIcon>
              {localStorage.getItem('lang') && <ReactCountryFlag svg countryCode={localStorage.getItem('lang').slice(3)} style={{ marginLeft: '4px', width: 20, height: 20 }} />}
            </ListItemIcon>
            <LangSelector mobile />
          </ListItem>
          <Divider />
        </List>}

      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
      <DefaultAddressModal onClose={handleClose} open={openAddressModal} />
    </div>
  );
}