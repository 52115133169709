import React from 'react';

import AddressMap from 'src/modules/views/AddressMap';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import AddressMapSlider from 'src/modules/components/AddressMapSlider';

function SelectAddress() {

  return (
    <React.Fragment>
      <AppAppBar />
      <AddressMap />
      <AddressMapSlider />
      <AppFooter />
    </React.Fragment>
  );
}

export default SelectAddress;