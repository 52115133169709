import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress
} from '@material-ui/core';

import { BookingContext } from 'src/contexts/BookingContext';
import BigButton from './Button';
import VariantAddon from './VariantAddon';
import QuantitySelector from '../form/QuantitySelector';
import AuthServices from 'src/services/AuthServices';
import MoneyDisplay from 'src/modules/components/MoneyDisplay';

const useStyles = makeStyles({
  root: {
    maxWidth: 800,
  },
  media: {
    height: 140,
  },
  button: {
    marginTop: 10,
  },
  quantityText: {
    marginRight: 10,
    color: '#343434'
  }
});

export default function VariantDetails({ variant, professionalId }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  let { booking, setBooking, createBooking } = useContext(BookingContext);
  const [addonsSelected, setAddonsSelected] = useState({});
  const [peopleCount, setPeopleCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setBooking((currentValues) => ({
      ...currentValues,
      addonsSelected
    }));
    // eslint-disable-next-line
  }, [addonsSelected]);

  useEffect(() => {
    setBooking((currentValues) => ({
      ...currentValues,
      peopleCount
    }));
    // eslint-disable-next-line
  }, [peopleCount]);

  useEffect(() => {
    setBooking((currentValues) => ({
      ...currentValues,
      service_variant_area_id: variant.service_variant_area_id,
    }));
    // eslint-disable-next-line
  }, []);

  const handleGetToCalendar = async () => {
    setIsLoading(true);
    const isLoggedResponse = AuthServices.isLogged();
    if (isLoggedResponse) {
      let userId = JSON.parse(isLoggedResponse.userStorageData).user_id;
      await createBooking(userId);
      setIsLoading(false);
      if (professionalId) {
        navigate(`/fecha?professionalId=${professionalId}`);
      } else {
        navigate('/fecha');
      }
    } else {
      navigate('/ingreso?reserva=true');
    }
  }

  const handleSetAddons = (addonId, value) => {
    let oldQuantity = booking.addonsSelected[addonId]?.quantity;

    setAddonsSelected((currentValues) => ({
      ...currentValues,
      [addonId]: {
        active: value,
        quantity: value ? oldQuantity ? oldQuantity : 1 : 0
      }
    }))
  }

  const handleAddPeople = (type) => {
    if (type === 'increment') {
      setPeopleCount((currentValue) => currentValue + 1)
    }
    if (type === 'decrement') {
      setPeopleCount((currentValue) => currentValue - 1)
    }
  }

  const handleAddonQuantity = (quantity, addonId) => {
    let oldQuantity = booking.addonsSelected[addonId].quantity;
    if (quantity === 'increment') {
      setBooking((currentValues) => ({
        ...currentValues,
        addonsSelected: {
          ...currentValues.addonsSelected,
          [addonId]: {
            ...currentValues.addonsSelected[addonId],
            quantity: oldQuantity + 1
          }
        }
      }));
    }
    if (quantity === 'decrement') {
      setBooking((currentValues) => ({
        ...currentValues,
        addonsSelected: {
          ...currentValues.addonsSelected,
          [addonId]: {
            ...currentValues.addonsSelected[addonId],
            quantity: oldQuantity - 1
          }
        }
      }));
    }
  }

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={variant.image.url}
        title={variant.name}
      />
      <CardContent>
        <Grid container className={classes.root} spacing={5}>
          <Grid item md={variant.addOns.length > 0 ? 6 : 12}>
            <Typography gutterBottom variant="h5" component="h2">
              {variant.name}
            </Typography>
            <Typography gutterBottom>
              {t('text.from')}&nbsp;
            <MoneyDisplay
                prefix={i18n.language === "es_pe" ? 'S/.' : '$'}
                value={variant.price}
                displayType={'text'} />
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ whiteSpace: 'break-spaces' }}>
              {variant.description}
            </Typography>
            <Box display="flex" justifyContent="center" flexDirection="row" alignItems="center" m={1}>
              <Typography variant="body3" color="textSecondary" className={classes.quantityText}>
                {variant.quantity_message ? variant.quantity_message : t('phrases.how_many_people')}
              </Typography>
              <QuantitySelector handleCounter={handleAddPeople} />
            </Box>
            {!variant.pack && <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <BigButton
                color="primary"
                variant="contained"
                size="large"
                className={classes.button}
                onClick={handleGetToCalendar}
              >
                {isLoading ? <CircularProgress color='inherit' /> : t('phrases.select_day_and_time')}
              </BigButton>
            </Box>}
            {variant.pack && <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <BigButton
                color="primary"
                variant="contained"
                size="large"
                className={classes.button}
                onClick={handleGetToCalendar}
              >
                {variant.pack ? 'Comprar' : 'Seleccionar fechas'}
              </BigButton>
            </Box>}
          </Grid>
          {variant.addOns.length > 0 &&
            <Grid item md={6}>
              <Typography gutterBottom variant="h5" component="h2">
                {t('text.extras')}
              </Typography>
              {variant.addOns.map((addon, i) => <Box key={`addon_${i}`}><VariantAddon handleSetAddons={handleSetAddons} addon={addon} handleAddonQuantity={handleAddonQuantity}/></Box>)}
            </Grid>}
        </Grid>
      </CardContent>
    </Card>
  );
}
