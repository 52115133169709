import React from 'react';
import Calendar from '../modules/views/Calendar';
import AppFooter from '../modules/views/AppFooter';
import AppAppBar from '../modules/views/AppAppBar';

function Index() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Calendar />
      <AppFooter />
    </React.Fragment>
  );
}

export default Index;
