import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Button, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '50%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Servicios', 'Selección Medio de Pago', 'Pagar'];
}

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#979797',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#f94646',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#000000',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon({ active, completed } ) {
  const classes = useQontoStepIconStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

export default function CartSteps({ activeStep, setActiveStep, skipped, setSkipped }) {
  const classes = useStyles();
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel StepIconComponent={QontoStepIcon} {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}