import { useState, useEffect } from 'react';
import AmeizerServices from 'src/services/AmeizerServices';
import { withStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Divider } from '@material-ui/core';
import is from 'is_js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import 'moment/locale/es';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Rating from '@material-ui/lab/Rating';

import Typography from 'src/modules/components/Typography';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  portfolio: {
    marginTop: theme.spacing(4),
  },
  title: {
    fontSize: '1.5rem'
  },
  emptyReviews: {
    marginTop: theme.spacing(4),
  },
  reviewList: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%'
  },
  reviewCard: {
    width: '350px',
    margin: '24px'
    // minWdth: '1350px'
  }
})

const ProfessionalReviews = ({ classes, ameizerId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  moment.locale('es');

  useEffect(() => {
    getProfessionalPortfolio();
  }, []);

  const getProfessionalPortfolio = async () => {
    setIsLoading(true);
    try {
      const response = await AmeizerServices.getReviews(ameizerId);
      console.log('getReviews', response.data);
      setReviews(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box className={classes.root}>
      {isLoading && <CircularProgress size={60} />}
      {!isLoading && is.empty(reviews) && <Box className={classes.emptyReviews}>
        <Typography variant="h5" className={classes.title} component="h2">
          El Ameizer aún no tiene reviews.
        </Typography>
      </Box>}
      {!isLoading && <Box className={classes.reviewList}>{reviews.map((r,i) => <Card key={`review_${i}`} className={classes.reviewCard}>
        <CardContent>
          <ListItemText primary={r.service_variant_area} />
        </CardContent>
        <Divider/>
        <CardActions>
          <Rating name="read-only" value={Number(r.average_rating)} readOnly />
        </CardActions>
      </Card>)}</Box>}
    </Box>
  )
}

export default withStyles(styles)(ProfessionalReviews);

