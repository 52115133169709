import firebase from 'firebase/app';
import UserServices from 'src/services/UserServices';
import "firebase/auth";
import "firebase/analytics";
import 'firebase/database';

// import { isTokenExpired } from "./isTokenExpired";

const firebaseConfig = {
  apiKey: "AIzaSyAmXqh1HsYfaQP4SO5jsWhpIzq4vkSsm9k",
  authDomain: "ameiz-a7551.firebaseapp.com",
  databaseURL: "https://ameiz-a7551.firebaseio.com",
  projectId: "ameiz-a7551",
  storageBucket: "ameiz-a7551.appspot.com",
  messagingSenderId: "973429353945",
  appId: "1:973429353945:web:4f39211ada3df5df9fbdc8",
  measurementId: "G-62ED8NBYHJ"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth;
export const analytics = firebase.analytics();
export const db = firebase.database();

export const getFirebaseToken = async (customToken, type = 'chat') => {
  let firebaseAuthToken = localStorage.getItem('firebaseToken');
  console.log('firebaseAuthToken', firebaseAuthToken);
  // if (true) {
  if (type === 'traking' || !firebaseAuthToken) {
    try {
        console.log('requesting new firebase token');
        let resp = await UserServices.getFirebaseToken(customToken.user_id, customToken);
        localStorage.setItem('firebaseToken', resp.data);
        console.log('token', resp.data);
        await firebaseAuth(resp.data);
    } catch (error) {
        console.log(error);
    }
  } else {
    firebaseAuth(firebaseAuthToken)
  };
}

export const firebaseAuth = async (token) => {
  if (!auth().currentUser) {
      try {
          await auth().signInWithCustomToken(token);
      } catch (error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log('firebase auth error: ', errorCode, errorMessage)
      }
  }
}
