import { useState, useEffect, useContext } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core/styles';
import Typography from 'src/modules/components/Typography';
import VariantCard from 'src/modules/components/VariantCard';
import is from 'is_js';
import { Button } from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import Fade from '@material-ui/core/Fade';

import VariantModal from 'src/modules/components/VariantModal';
import { BookingContext } from 'src/contexts/BookingContext';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%'
  },
  imageWrapper: {
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '40vh',
    margin: '0px 0px 5px 5px',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
      height: 100,
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $imageBackdrop': {
      opacity: 0.15,
    },
    '&:hover $imageMarked': {
      opacity: 0,
    },
    '&:hover $imageTitle': {
      border: '4px solid currentColor',
    },
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    flexDirection: 'column',
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
    marginBottom: theme.spacing(2),
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  backButton: {
    color: 'grey',
  }
});

const ProfessionalServices = ({ classes, services, ameizerId }) => {
  const [variants, setVariants] = useState(null);
  const [variantSelected, setVariantSelected] = useState(null);
  const { setFavouriteProfessional } = useContext(BookingContext);

  const handleSelectCategory = (serviceId) => {
    setVariants(services.find((s) => s.id === serviceId));
  }

  const getBack = () => {
    setVariants(null);
  }

  const handleCloseModal = () => {
    setVariantSelected(null);
  }

  const handleOpenModal = (variant) => {
    setVariantSelected(variant);
    setFavouriteProfessional(ameizerId);
  }

  return (
    <>
      {variants && <Button
          className={classes.backButton}
          onClick={getBack}
          startIcon={<ArrowBackIosRoundedIcon />}
        >  Volver
        </Button>}

      <div className={classes.root}>

        {!variants && services.map((services, i) => (<Fade in={services} key={"services_" + i}><ButtonBase
          onClick={() => { handleSelectCategory(services.id) }}
          className={classes.imageWrapper}
          style={{
            width: '45%',
          }}
        >
          <div
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${services.image.full_url})`,
            }}
          />
          <div className={classes.imageBackdrop} />
          <div className={classes.imageButton}>
            <Typography
              component="h3"
              variant="h6"
              color="inherit"
              className={classes.imageTitle}>
              {services.name}
              <div className={classes.imageMarked} />
            </Typography>
          </div>
        </ButtonBase></Fade>
        ))}

        {variants && variants.variants.map((variant, i) => (<Fade in={variants} key={"variant" + i}><ButtonBase
          onClick={() => handleOpenModal(variant)}
          className={classes.imageWrapper}
          style={{
            width: '45%',
          }}
        >
          <div
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${variant.image.full_url})`,
            }}
          />
          <div className={classes.imageBackdrop} />
          <div className={classes.imageButton}>
            <Typography
              component="h3"
              variant="h6"
              color="inherit"
              className={classes.imageTitle}>
              {variant.name}
              <div className={classes.imageMarked} />
            </Typography>
          </div>
        </ButtonBase></Fade>
        ))}

      </div>

      {variantSelected && <VariantModal variant={variantSelected} show={variantSelected} handleCloseModal={handleCloseModal} professionalId={ameizerId} />}
    </>
  )
}

export default withStyles(styles)(ProfessionalServices);
