import React from 'react';
import NumberFormat from "react-number-format";

const MoneyDisplay = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator={true}
      decimalScale={0}
      fixedDecimalScale={false}
      onValueChange={(values) => {
        // onChange({
        //   target: {
        //     name: props.name,
        //     value: values.value,
        //   },
        // });
      }}

    />
  )
}

export default MoneyDisplay
