import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import PackList from 'src/modules/views/PackList';

function Bookings() {
  return (
    <React.Fragment>
      <AppAppBar />
      <PackList />
      <AppFooter />
    </React.Fragment>
  );
}

export default Bookings;
