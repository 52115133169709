import { useState, useEffect } from 'react';
import AmeizerServices from 'src/services/AmeizerServices';
import { withStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Divider } from '@material-ui/core';
import is from 'is_js';

import ImageList from 'src/modules/components/ImageList';
import Typography from 'src/modules/components/Typography';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  portfolio: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'flex-start'
  },
  title: {
    fontSize: '1.5rem',
  }
})

const ProfessionalPortfolio = ({ classes, ameizerId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    getProfessionalPortfolio();
  }, []);

  const getProfessionalPortfolio = async () => {
    setIsLoading(true);
    try {
      const response = await AmeizerServices.getPortfolio(ameizerId);
      console.log('getPortfolio', response.data.categories);
      setPortfolio(response.data.categories);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box className={classes.root}>
      {isLoading && <CircularProgress size={60} />}
      {!isLoading && is.empty(portfolio) && <Box className={classes.portfolio}>
        <Typography variant="h5" className={classes.title} component="h2">
          El portafolio no tiene fotos cargadas.
        </Typography>
      </Box>}
      {!isLoading && portfolio.map((p) => <Box key={p.category.id} className={classes.portfolio}>
        <Typography className={classes.title}>
          {p.category.name} 
        </Typography>
        <Divider style={{marginBottom: 24}} />
        <ImageList itemData={p.images} />
      </Box>)}
    </Box>
  )
}

export default withStyles(styles)(ProfessionalPortfolio);
