import { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
} from '@material-ui/core';
import is from 'is_js';
import moment from 'moment';

import { db } from 'src/utils/firebase';
import { AuthContext } from 'src/contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderTop: '1px solid #CBCBCB',
    borderBottom: '1px solid #CBCBCB',
    'overflow-y': 'auto',
  },
  inline: {
    display: 'inline',
  },
}));

const ContactList = ({ handleOpenConversationList, professionalSelected, newChat }) => {
  const classes = useStyles();
  const { userData } = useContext(AuthContext);
  const [contactList, setContactList] = useState([]);

  useEffect(() => {
    if (userData) {
      let onValueChange;
      onValueChange = db.ref(`unread/user_${userData.user_id}`)
      .on('value', snapshot => {
        let messages = snapshot.val();
        if (messages) {
          let messageList = [];
          for(let i in messages) {
            messageList.push({...messages[i], professionalId: i });
          }
          setContactList(messageList);
        }
      });

      return () => {
        db.ref(`unread/user_${userData.user_id}`)
          .off('value', onValueChange);
      }

    }
  }, [userData]);

  return (
    <List className={classes.root} boxShadow={0}>
      {contactList.map((c, i) => <Box key={`contact_${i}`} >
        <ListItem 
          onClick={() => handleOpenConversationList(c)}
          alignItems="flex-start" 
          button
          selected={professionalSelected?.user_id === c?.user_id}
        >
        <ListItemAvatar>
          <Avatar alt={c.firstName} src={c.avatar} />
        </ListItemAvatar>
        <ListItemText
          primary={`${c.firstName} ${c.lastName}`}
          secondary={c.image ? 'Foto 📷' : c?.message}
        />
      </ListItem>
      <Divider variant="inset" component="li" /></Box>)}
      
    </List>
  )
}

export default ContactList;
