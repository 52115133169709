// --- Post bootstrap -----
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { AuthContext } from 'src/contexts/AuthContext';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import Link from '@material-ui/core/Link';
import { Field, Form, FormSpy } from 'react-final-form';
import Typography from '../modules/components/Typography';
import AppFooter from '../modules/views/AppFooter';
import AppAppBar from '../modules/views/AppAppBar';
import AppForm from '../modules/views/AppForm';
import { email, required } from '../modules/form/validation';
import RFTextField from '../modules/form/RFTextField';
import FormButton from '../modules/form/FormButton';
import FormFeedback from '../modules/form/FormFeedback';
import Alert from '@material-ui/lab/Alert';
import {
  MenuItem,
  CircularProgress,
  Box,
  IconButton,
} from '@material-ui/core';
import TextField from 'src/modules/components/TextField';
import UserServices from 'src/services/UserServices';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import ReactCountryFlag from "react-country-flag";
import { useLocation } from "react-router-dom";

import blankProfile from 'src/assets/blank-profile.png';
import PhoneDisplay from 'src/modules/components/PhoneDisplay';
import { BookingContext } from 'src/contexts/BookingContext';
import { getFirebaseToken }  from '../utils/firebase';

const COUNTRIES = [
  { name: 'Chile', code: '+56', flag: 'CL', },
  { name: 'Argentina', code: '+54', flag: 'AR', },
  { name: 'Colombia', code: '+57', flag: 'CO', },
  { name: 'México', code: '+52', flag: 'MX', },
  { name: 'Perú', code: '+51', flag: 'PE', },
  { name: 'Otro', code: '+', flag: null, }
]

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  avatarContainer: {
    backgroundSize: "cover",
    width: '120px',
    height: '120px',
    borderRadius: '8px',
    border: '5px solid #ddd',
    margin: '0px auto 24px auto'
  },
  MuiTextField: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(3),
    },
  }
}));

function SignUp() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  let { createBooking } = useContext(BookingContext);
  let { setLogged, setUserData } = useContext(AuthContext);
  const [sent, setSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [countrySelected, setCountrySelected] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [base64Image, setBase64image] = useState(null);
  const [phonePrefix, setPhonePrefix] = useState(null);
  const [country, setCountry] = useState(null);
  const [apiError, setApiError] = useState(null);
  const search = useLocation().search;
  const paramBooking = new URLSearchParams(search).get('reserva');

  const validate = (values) => {
    let newValues = {
      ...values,
      phoneNumber,
      country: countrySelected
    }
    const errors = required(['firstName', 'lastName', 'email', 'password', 'country', 'phoneNumber', 'second_password'], newValues);

    if (!errors.email) {
      const emailError = email(values.email, values);
      if (emailError) {
        errors.email = email(values.email, values);
      }
    }
    if (newValues.password && newValues.second_password) {
      if (newValues.password !== newValues.second_password) {
        errors.password = 'Las contraseñas no coinciden'
      }
    }
    return errors;
  };

  const handleChangeCountry = (country) => {
    setCountrySelected(country);
    setPhonePrefix(country.code);
    setCountry(country.flag);
  }

  const onSubmit = async (values) => {
    setApiError(null);

    let body = {
      name: values.firstName,
      lastname: values.lastName,
      phone: `${phonePrefix}${phoneNumber}`,
      email: values.email,
      minutes_offset: 0,
      password: values.password,
      referred_by: values.referral_code,
      country: countrySelected.name,
    }

    try {
      // create user method
      const response = await UserServices.createUser(body);
      const { access_token, refresh_token, ...userProperties } = response.data.login;
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);

      let avatarResponse;

      // add avatar method
      if (base64Image) {
        let newAvatar = {
          base64: base64Image,
          user_id: response.data.user.id,
          image_type: 'image/png'
        }
        avatarResponse = await UserServices.postAvatar(response.data.user.id, newAvatar);
      }

      // adding avatar to user data
      let newUserData = {
        ...userProperties,
        avatar: {
          url: avatarResponse && avatarResponse.data.url
        }
      }

      localStorage.setItem('user_data', JSON.stringify(newUserData));

      setLogged(true);
      setUserData(newUserData);
      setSent(true);
      const customToken = {
        user_type: 'ameiz',
        user_id: response.data.user_id,
        professional_id: null,
        admin: false
      }
      await getFirebaseToken(customToken);
      if (paramBooking) {
        await createBooking(response.data.user.id, "signUn", 'session');
      } else {
        navigate('/');
      }

    } catch (error) {
      console.log(error);
      error.response.data.message && setApiError(error.response.data.message);
      console.log("error", error.response.data.message)
    }

  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const handleUploadClick = event => {
    var file = event.target.files[0];
    setAvatar(URL.createObjectURL(file));
    getBase64(event.target.files[0]).then(file64 => {
      setBase64image(file64);
    })
  };

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            {t('text.registration')}
          </Typography>
        </React.Fragment>
        <Form onSubmit={onSubmit} subscription={{ submitting: true }} validate={validate}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.avatarContainer}
                    style={{ backgroundImage: avatar ? `url(${avatar})` : `url(${blankProfile})` }} >
                    <input
                      accept="image/jpeg,image/png"
                      className={classes.input}
                      id="web_image"
                      type="file"
                      name="web_image"
                      onChange={handleUploadClick}
                    />
                    <label htmlFor="web_image" >
                      <IconButton style={{ position: 'relative' }} component="span">
                        <AddAPhotoOutlinedIcon />
                      </IconButton>
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    autoFocus
                    component={RFTextField}
                    autoComplete="fname"
                    fullWidth
                    label={t('text.first_name')}
                    name="firstName"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={RFTextField}
                    autoComplete="lname"
                    fullWidth
                    label={t('text.last_name')}
                    name="lastName"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box style={{ display: 'flex' }}>
                    <TextField
                      label={t('text.country')}
                      name='country'
                      style={{ paddingRight: '4px' }}
                      fullWidth
                      value={countrySelected}
                      onChange={(e) => handleChangeCountry(e.target.value)}
                      select>
                      {COUNTRIES.map((c, i) => <MenuItem key={"country_" + c.name} value={c}>
                        {c.name}
                      </MenuItem>)}
                    </TextField>
                    <TextField
                      label={t('text.code')}
                      style={{ paddingLeft: '4px' }}
                      fullWidth
                      value={phonePrefix}
                      onChange={(e) => setPhonePrefix(e.target.value)}
                      InputProps={{
                        readOnly: country,
                        startAdornment: <ReactCountryFlag svg countryCode={country} style={{ margin: '4px' }} />,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('text.phone')}
                    autoComplete="phone"
                    name="phoneNumber"
                    id='phoneNumber'
                    fullWidth
                    required
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                    InputProps={{
                      inputComponent: PhoneDisplay,
                    }}
                  >
                  </TextField>
                </Grid>
              </Grid>
              <Field
                autoComplete="email"
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
              />
              <Field
                fullWidth
                component={RFTextField}
                disabled={submitting || sent}
                required
                name='password'
                autoComplete="current-password"
                label={t('text.password')}
                type="password"
                margin="normal"
              />
              <Field
                fullWidth
                component={RFTextField}
                disabled={submitting || sent}
                required
                name="second_password"
                autoComplete="current-password"
                label={t('phrases.repeat_your_password')}
                type="password"
                margin="normal"
              />
              <Field
                fullWidth
                component={RFTextField}
                name="referral_code"
                label={t('phrases.referral_code')}
                margin="normal"
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback className={classes.feedback} error>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                className={classes.button}
                disabled={submitting || sent}
                color="secondary"
                fullWidth
              >
                {submitting || sent ? <CircularProgress color="secondary" /> : t('text.confirm')}
              </FormButton>
              {apiError && <Alert severity="error">{apiError}</Alert>}
            </form>
          )}
        </Form>
        <Typography variant="body2" align="center">
          <Link href="/ingreso" underline="always">
            {t('phrases.already_have_an_account?')}
          </Link>
        </Typography>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default SignUp;
