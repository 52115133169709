// eslint-disable-next-line 
export default {
    "home": {

    },
    "navbar": {

    },
    "footer": {

    },
}