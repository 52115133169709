import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token');

  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, function (err) {
  return Promise.reject(err);
});

const PaymentServices = {
  searchPaymentMethods: async (filter) => {
    console.log(`PaymentServices.searchPaymentMethods(${JSON.stringify(filter)})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/payment/payment-method/search`;
    return axios.post(url, filter);
  },
  getCard: async (userCardId) => {
    console.log(`PaymentServices.getCard(${userCardId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/payment/card/${userCardId}`;
    return axios.get(url);
  },
  addCard: async (cardData) => {
    console.log(`PaymentServices.addCard(${cardData})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/payment/card`;
    return axios.post(url, cardData);
  },
  getOneClickUrl: async (filter) => {
    console.log(`PaymentServices.getOneClickUrl(${JSON.stringify(filter)})`);
    const url = `${process.env.REACT_APP_PAYMENTS_API_URL}/webpay/inscription`;
    return axios.post(url, filter);
  },
  pay: async (body) => {
    console.log(`PaymentServices.pay()`);
    console.log(body)
    const url = `${process.env.REACT_APP_PAYMENTS_API_URL}/payment`;
    return axios.post(url, body);
  },
  payTip: async (body) => {
    console.log(`PaymentServices.payTip()`);
    console.log(body)
    const url = `${process.env.REACT_APP_PAYMENTS_API_URL}/payment/tip`;
    return axios.post(url, body);
  },
  confirmOneClickInscription: async (user_id, token) => {
    console.log(`PaymentServices.confirmOneClickPay()`);
    const url = `${process.env.REACT_APP_PAYMENTS_API_URL}/webpay/inscription/confirmation`;
    return axios.post(url, { user_id: user_id, token: token });
  },
  getPublicIP: async () => {
    console.log(`PaymentServices.getPublicIP()`);
    const url = `https://api.country.is/`;
    return axios.get(url);
  },
  dLocalSaveCard: async (auth_token, card_token, ip, device_id) => {
    console.log(`PaymentServices.dLocalSaveCard()`);
    const url = `${process.env.REACT_APP_PAYMENTS_API_URL}/dlocal/secure-card`;
    return axios.post(url, {
      token: card_token,
      save_card: true,
      ip: ip,
      device_id: device_id,
    });
  }
};

export default PaymentServices;