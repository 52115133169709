import { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

import ContactList from 'src/modules/components/ContactList';
import Conversation from 'src/modules/components/Conversation';
import AmeizerServices from 'src/services/AmeizerServices';
import { UserContext } from 'src/contexts/UserContext';
import { COUNTRIES } from 'src/utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  gridContainer: {
    minHeight: '600px',
  }
}));

const Messages = () => {
  const { defaultAddress } = useContext(UserContext);
  const { i18n, t } = useTranslation();
  const classes = useStyles();
  const [conversationSelected, setConversationSelected] = useState(null);
  const [professionalSelected, setprofessionalSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const search = useLocation().search;
  const ameizerId = new URLSearchParams(search).get('ameizerId');

  useEffect(() => {
    if (ameizerId) {
      getProfessionalData();
    }
  }, [ameizerId]);

  const getProfessionalData = async () => {
    const country = COUNTRIES.find((c) => c.lang === i18n.language);

    setIsLoading(true);
    const filter = {
      professional_id: parseInt(ameizerId),
      coordinate: {
        lat: defaultAddress ? defaultAddress.coordinate.lat : country.center.lat,
        lng: defaultAddress ? defaultAddress.coordinate.lng : country.center.lng
      }
    }
    try {
      const response = await AmeizerServices.getProfessional(filter.professional_id, filter);
      setprofessionalSelected({
        user_id: parseInt(ameizerId),
        firstName: response.data.user.name,
        lastName: response.data.user.lastname,
        avatar: response.data.user?.avatar?.url
      });
      setConversationSelected(`professional_${ameizerId}`);
    } catch (error) {
      console.log(error.response.data.message || error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleOpenConversationList = (professional) => {
    setConversationSelected(professional?.professionalId);
    setprofessionalSelected(professional);
  }

  return (
    <Container className={classes.root} >
      {isLoading && <CircularProgress />}
      {!isLoading && <Grid container spacing={1} className={classes.gridContainer}>
        <Grid item sm={4} md={5} alignContent="center" style={{ width: '100%', borderRight: '1px solid #28282a' }} >
          <ContactList
            handleOpenConversationList={handleOpenConversationList}
            professionalSelected={professionalSelected}
            newChat={ameizerId}
          />
        </Grid>
        <Grid item sm={8} md={7} alignContent="center"  style={{ width: '100%' }} >
          <Conversation
            conversationSelected={conversationSelected}
            professionalSelected={professionalSelected}
            newChat={ameizerId}
          />
        </Grid>
      </Grid>}
    </Container>
  );
}

export default Messages
