import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";
import { Field, Form, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { useLocation } from "react-router-dom";

import Typography from '../modules/components/Typography';
import AppFooter from '../modules/views/AppFooter';
import AppAppBar from '../modules/views/AppAppBar';
import AppForm from '../modules/views/AppForm';
import { email, required } from '../modules/form/validation';
import RFTextField from '../modules/form/RFTextField';
import FormButton from '../modules/form/FormButton';
import FormFeedback from '../modules/form/FormFeedback';
import AuthServices from 'src/services/AuthServices';
import { AuthContext } from 'src/contexts/AuthContext';
import { BookingContext } from 'src/contexts/BookingContext';
import { getFirebaseToken }  from '../utils/firebase';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

function SignIn() {
  const classes = useStyles();
  const navigate = useNavigate();
  let { setLogged, setUserData } = useContext(AuthContext);
  let { createBooking } = useContext(BookingContext);
  const { t } = useTranslation();
  const search = useLocation().search;
  const paramBooking = new URLSearchParams(search).get('reserva');
  // eslint-disable-next-line
  const [sent, setSent] = useState(false);
  const [passwordError, setPasswordError] = useState(null);

  const validate = (values) => {
    const errors = required(['email', 'password'], values);

    if (!errors.email) {
      const emailError = email(values.email, values);
      if (emailError) {
        errors.email = email(values.email, values);
      }
    }

    return errors;
  };

  const onSubmit = async (values) => {
    setPasswordError(null);
    let body = {
      username: values.email,
      password: values.password,
      minutes_offset: 0
    }
    try {
      const response = await AuthServices.getLogged(body);
      const { access_token, refresh_token, ...userDataProperties } = response.data;
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
      localStorage.setItem('user_data', JSON.stringify(userDataProperties));
      setLogged(true);
      setUserData(response.data);
      const customToken = {
        user_type: 'ameiz',
        user_id: response.data.user_id,
        professional_id: null,
        admin: false
      }
      await getFirebaseToken(customToken);
      if (paramBooking) {
        await createBooking(response.data.user_id, "signIn", 'session');
      } else {
        navigate('/');
      }
    } catch (error) {
      console.log(error);
      setPasswordError('La contraseña es incorrecta');
    }
  }

  const getToSignUp = (e) => {
    e.preventDefault();
    navigate("/registro?reserva=true");
  }

  return (
    <>
      <AppAppBar />
      <AppForm>
        <>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            {t('text.sign_in')}
          </Typography>
          <Typography variant="body2" align="center">
            {t('phrases.not_a_member_message')}
            <Link onClick={getToSignUp} align="center" underline="always" style={{ marginLeft: '4px' }}>
              {t('phrases.Sign_up_here_message')}
            </Link>
          </Typography>
        </>
        <Form onSubmit={onSubmit} subscription={{ submitting: true }} validate={validate}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <Field
                autoComplete="email"
                autoFocus
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
                size="large"
              />
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                disabled={submitting || sent}
                required
                name="password"
                error={!!passwordError}
                helperText={passwordError}
                submitError={passwordError}
                touched={true}
                autoComplete="current-password"
                label={t('text.password')}
                type="password"
                margin="normal"
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback className={classes.feedback} error>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                className={classes.button}
                disabled={submitting || sent}
                size="large"
                color="secondary"
                fullWidth
              >
                {submitting || sent ? t('phrases.in_progress_message') + "..." : t('text.sign_in')}
              </FormButton>
            </form>
          )}
        </Form>
        <Typography align="center">
          <Link underline="always" href="/recuperar-clave">
            {t('phrases.forgotten_password_message')}
          </Link>
        </Typography>
      </AppForm>
      <AppFooter />
    </>
  );
}

export default SignIn;