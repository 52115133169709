import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Container,
  Box,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import ReactWhatsapp from 'react-whatsapp';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import is from 'is_js';

import { AuthContext } from 'src/contexts/AuthContext';
import CommonServices from 'src/services/CommonServices';
import { COUNTRIES } from 'src/utils/constants';

const CONFIG_VALUE_TYPES = [
  "referer-text",
  "referer-amount",
  "referred-amount",
  "sharing-text"
]

const ReferFriendCode = () => {
  const { userData } = useContext(AuthContext);
  const [copied, setCopied] = useState(false);
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [configValues, setConfigValues] = useState([]);

  useEffect(() => {
    console.log('configValues', configValues);
  }, [configValues]);

  useEffect(() => {
    getConfigValueData();
  }, []);

  const getConfigValueData = async () => {
    // setIsLoading(true);
    let currentCountry = COUNTRIES.find((c) => c.lang === i18n.language);
 
    try {
      let filter = {
        cateogry: 'refer-a-friend',
        coordinate: {
          lat: currentCountry.center.lat,
          lng: currentCountry.center.lng
        }
      }
      const response = await CommonServices.searchConfigValue(filter);
      setConfigValues(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const getSharingText = () => {
    if (is.not.empty(configValues)) {
      let sharingText = configValues.find((v) => v.type === "sharing-text").value;
      let finalText = `${sharingText} - Código de referido: ${userData?.affiliation_code}`
      return finalText;
    }
    return '';
  }

  return (
    <Container className={classes.container}>
      {isLoading && <CircularProgress />}
      {!isLoading && <Card className={classes.root}>
          <CardMedia
            className={classes.media}
            image={'https://s3.amazonaws.com/ameizproduction/site/client-form-bg.jpg'}
            title="Recomienda y Gana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" className={classes.mainTitle}>
              {t('phrases.refer_and_win')} {configValues.find((v) => v.type === 'referer-amount').area ? configValues.find((v) => v.type === 'referer-amount').area.currencies[0].symbol : '$'} {configValues.find((v) => v.type === 'referer-amount')?.value}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {t('phrases.refer_and_win_subtitle')}
            </Typography>
            <Box className={classes.btnContainer}>
              <CopyToClipboard text={getSharingText()} onCopy={() => { setCopied(true); setTimeout(() => setCopied(false), 1000); }}>
                <Tooltip title={t(`text.share`)} placement="top" arrow>
                  <Box style={{ display: 'flex', flexDirection: 'column',position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
                    <Button color="primary" variant='outlined' className={classes.btn} style={{width: '98%'}}>
                      {userData?.affiliation_code}
                    </Button>
                    {copied && <span style={{ fontSize: '12px', color: 'grey', marginBottom: '12px' }}>{t(`text.copied`)}</span>}
                  </Box>
                </Tooltip>
              </CopyToClipboard>
              <ReactWhatsapp number='' message={getSharingText()} style={{ backgroundColor: 'transparent', border: 'none', width: '100%' }}>
                <Button fullWidth color="primary" variant='contained' startIcon={<ShareOutlinedIcon />} >
                {t('text.share')}
                </Button>
              </ReactWhatsapp>
            </Box>
            <Box className={classes.how_it_works_container}>
              <Typography gutterBottom variant="h5" component="h2" className={classes.how_it_works}>
                {t('phrases.how_it_works')}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {configValues.find((v) => v.type === 'referer-text')?.value}
              </Typography>
            </Box>
          </CardContent>
      </Card>}
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    maxWidth: 645,
  },
  media: {
    height: 140,
  },
  mainTitle: {
    fontWeight: 'bold',
    fontSize: '1.5rem'
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12
  },
  btn: {
    marginBottom: 12
  },
  how_it_works_container: {
    marginTop: 24
  },
  how_it_works: {
    fontWeight: 'bold'
  }
}));

export default ReferFriendCode;
