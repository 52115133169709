import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token');

  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, function (err) {
  return Promise.reject(err);
});

const ShoppingCartServices = {
  createBooking: async (booking) => {
    console.log(`ShoppingCartServices.createBooking(${JSON.stringify(booking)})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/shopping_cart/booking`;
    return axios.post(url, booking);
  },
  updateBooking: async (bookingId, bookingUpdated) => {
    console.log(`ShoppingCartServices.updateBooking(${bookingId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/shopping_cart/booking/${bookingId}`;
    return axios.patch(url, bookingUpdated);
  },
  deleteBooking: async (bookingId) => {
    console.log(`ShoppingCartServices.deleteBooking(${bookingId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/shopping_cart/booking/${bookingId}`;
    return axios.delete(url);
  },
  getAvailabilityDate: async (bookingId, filter) => {
    console.log(`ShoppingCartServices.getAvailabilityDate(${bookingId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/shopping_cart/booking/${bookingId}/availability/date/search`;
    return axios.post(url, filter);
  },
  getAvailabilityTime: async (bookingId, filter) => {
    console.log(`ShoppingCartServices.getAvailabilityTime(${bookingId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/shopping_cart/booking/${bookingId}/availability/time/search`;
    return axios.post(url,filter);
  },
  addPromocode: async (code) => {
    console.log(`ShoppingCartServices.addPromocode(${code})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/shopping_cart/promocode/${code}`;
    return axios.post(url);
  },
  getProfessionals: async (booking_id, filter) => {
    console.log(`CartServices.getAvailableDates()`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/shopping_cart/booking/${booking_id}/availability/professional/search`;
    return axios.post(url, filter);
  },
};

export default ShoppingCartServices;