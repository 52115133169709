import React from 'react';
import AppFooter from 'src/modules/views/AppFooter';
import AppAppBar from 'src/modules/views/AppAppBar';
import PaymentResultMessage from 'src/modules/views/PaymentResultMessage';

function PaymentResult() {
  return (
    <React.Fragment>
      <AppAppBar />
      <PaymentResultMessage />
      <AppFooter />
    </React.Fragment>
  );
}

export default PaymentResult;
