import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { Box, Tooltip, ClickAwayListener } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    display: 'flex',
    flexWrap: 'wrap',
    // transform: 'translateZ(0)',
    width: '100%',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '50px',
  },
  tooltip: {

  },
  imageItem: {
    borderRadius: '1000px',
    margin: '24px',
    // height: '100%'
    width: '180px',
    height: '180px',
  }
}));

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'black',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function ImageListComponent({ itemData }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      {itemData.map((item) => (
          <Box key={item.id} className={classes.imageItem}>
            <img src={item.full_url} alt={item.id} className={classes.image}/>
          </Box>
        ))}

      {/* <ImageList className={classes.imageList} gap={24} cols={4}  >
        {itemData.map((item) => (
          <ImageListItem key={item.id} className={classes.imageItem}>
            <img src={item.full_url} alt={item.id} className={classes.image}/>
          </ImageListItem>
        ))}
      </ImageList> */}
    </div>
  );
}
