import ForgotPassword from './scenes/ForgotPassword';
import SignIn from './scenes/SignIn';
import SignUp from './scenes/SignUp';
import SelectAddress from './scenes/SelectAddress';
import SelectCategory from './scenes/SelectCategory';
import SelectService from './scenes/SelectService';
import SelectDate from './scenes/SelectDate';
import BookingCheckout from 'src/scenes/BookingCheckout';
import CartCheckout from 'src/scenes/CartCheckout';
import DlocalSecureCard from 'src/scenes/CardSave';
import Account from 'src/scenes/Account';
import Profile from 'src/scenes/Profile';
import CreateAddress from 'src/scenes/CreateAddress';
import Bookings from 'src/scenes/Bookings';
import Review from 'src/scenes/Review';
import DefaultAddressChecker from 'src/scenes/DefaultAddressChecker';
import PaymentResult from 'src/scenes/PaymentResult';
import PaymentMethods from 'src/scenes/PaymentMethods';
import AmeizerProfile from 'src/scenes/AmeizerProfile';
import Chat from 'src/scenes/Chat';
import Packs from 'src/scenes/Packs';
import Wallet from 'src/scenes/Wallet';
import ReferFriend from 'src/scenes/ReferFriend';
import ClientReview from 'src/scenes/ClientReview';

const routes = [
  {
    path: '/',
    children: [
      { path: '/', element: <DefaultAddressChecker /> },
      { path: '/ingreso', element: <SignIn /> },
      { path: '/recuperar-clave', element: <ForgotPassword /> },
      { path: '/registro', element: <SignUp /> },
      { path: '/direccion', element: <SelectAddress /> },
      { path: '/categoria-de-servicio', element: <SelectCategory /> },
      { path: '/categoria/:categoryId/servicios', element: <SelectService /> },
      { path: '/fecha', element: <SelectDate /> },
      { path: '/fecha/:cartId/:bookingId', element: <SelectDate /> },
      { path: '/checkout', element: <BookingCheckout /> },
      { path: '/checkout/:cartId/:bookingId', element: <BookingCheckout /> },
      { path: '/cart', element: <CartCheckout /> },
      { path: '/cart/dlocal-secure-card/:auth_token', element: <DlocalSecureCard /> },
      { path: '/cart/:paymentResult', element: <CartCheckout /> },
      { path: '/cart/:service/save-card-success', element: <CartCheckout /> },
      { path: '/process-payment/:result', element: <PaymentResult /> },
      { path: '/perfil', element: <Account /> },
      { path: '/perfil/edit', element: <Profile /> },
      { path: '/perfil/direccion', element: <CreateAddress /> },
      { path: '/reservas', element: <Bookings /> },
      { path: '/review/:bookingId', element: <Review /> },
      { path: '/medios-de-pago', element: <PaymentMethods /> },
      { path: '/ameizer/:ameizerId/perfil', element: <AmeizerProfile /> },
      { path: '/mensajes', element: <Chat /> },
      { path: '/mis-packs', element: <Packs /> },
      { path: '/mis-creditos', element: <Wallet /> },
      { path: '/recomienda-un-amigo', element: <ReferFriend /> },
      { path: '/review/:bookingId/:token', element: <ClientReview /> },
    ]
  },
];

export default routes;
