import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token');

  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, function (err) {
  return Promise.reject(err);
});

const AmeizServices = {
  search: async (filter) => {
    console.log(`AmeizServices.search()`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/ameiz/search`;
    return axios.post(url, filter);
  },
};

export default AmeizServices;
